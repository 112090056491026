import throttle from 'lodash/fp/throttle';

import { inIframe } from './deviceUtils';
import versionJson from '../version.json';

const uikitVersion = versionJson.version;

const isProdEnv = () => process.env.NODE_ENV === 'production';
const isTestEnv = () => process.env.NODE_ENV === 'test';

const isLocalhost = () => window?.location?.hostname === 'localhost';

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const calcViewportUnits = () => {
    const vh = window.innerHeight * 0.01;
    return `--vh: ${vh}px`;
};

const logStyled = log => console.log('%c%s', 'color: #697a8b;', log);

const printPoweredByRIO = () => {
    logStyled('- - - - - - - - - - - - - - - - - - - - - - -');

    !isLocalhost() &&
        logStyled(
            `  ____  ___ ___    _   _ ___ _  _____ _____
 |  _ \\|_ _/ _ \\  | | | |_ _| |/ /_ _|_   _|
 | |_) || | | | | | | | || || ' / | |  | |
 |  _ < | | |_| | | |_| || || . \\ | |  | |
 |_| \\_\\___\\___/   \\___/|___|_|\\_\\___| |_|
`
        );

    const message = [` Version ${uikitVersion} | https://uikit.developers.rio.cloud`];
    isProdEnv() && message.push('\n\n You are a Developer? RIO is hiring! | https://rio.cloud/en/career');

    logStyled(message.join(''));
    logStyled('- - - - - - - - - - - - - - - - - - - - - -');
};

const checkForReleaseVersion = async () => {
    if (!isProdEnv()) {
        return;
    }

    const remoteVersionsListUrl = 'https://uikit.developers.rio.cloud/versionsList.json';

    try {
        const response = await fetch(remoteVersionsListUrl, { method: 'get', mode: 'no-cors' });
        // console.log(response);
        const data = await response.json();

        if (data.latest_release !== uikitVersion) {
            console.log(
                `🆕 UIKIT version ${data.latest_release} is the latest released version! Your are using ${uikitVersion}`
            );
        }
    } catch (error) {
        // console.warn(error);
        console.warn(`Faild to fetch versionsList from ${remoteVersionsListUrl}`);
    }
};

const checkMatchingUkitVersions = () => {
    Array.from(document.querySelectorAll('link[rel="stylesheet"]')).forEach(cssLink => {
        const { href } = cssLink;

        if (href.includes('uikit.developers.rio.cloud')) {
            const [, uikitVersionCSS] = new URL(href).pathname.split('/');

            if (!href.includes(uikitVersion)) {
                console.warn(
                    [
                        'You are using different UIKIT JS and CSS versions.\n',
                        `Your current JS version is ${uikitVersion} and `,
                        `your current CSS version is ${uikitVersionCSS}.\n`,
                        'If this is not on purpose, please fix it. Otherwise, this may lead to UI bugs.',
                    ].join('')
                );
            }
        } else if (href.includes('cdn.rio.cloud/libs/rio-uikit')) {
            console.error('You are still using an old "cdn.rio.cloud" css import. This will lead to mayor UI bugs!');
        }
    });
};

export const initDocumentBootstrapping = document => {
    if (!document || !document.documentElement) {
        return;
    }

    if (!isTestEnv()) {
        console.group('UIKIT');
        console.log('Init document bootstrapping');
    }

    const html = document.documentElement;
    const setViewportUnits = () => html.setAttribute('style', calcViewportUnits());

    window.addEventListener('orientationchange', throttle(1000, setViewportUnits));
    window.addEventListener('resize', throttle(1000, setViewportUnits));

    setViewportUnits();

    if (!window.CSS || !window.CSS.supports || !window.CSS.supports('gap: 1px')) {
        html.classList.add('css-no-gap');
    }

    if (html && inIframe()) {
        html.classList.add('window-iframe');
    }

    if (!isTestEnv()) {
        printPoweredByRIO();
        checkForReleaseVersion();
        checkMatchingUkitVersions();

        console.groupEnd('UIKIT');
    }
};

export const autoInitDocumentBootstrapping = (function (document) {
    initDocumentBootstrapping(document);
})(document);
