import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import NumberInput, { defaultProps, convertNonIntegerToDefault } from '../numberInput/NumberInput';

const INITIAL_TICK = 700;
const TICK_TIME = 50;

const NumberControl = props => {
    const {
        min = defaultProps.min,
        max = defaultProps.max,
        step = defaultProps.step,
        value = defaultProps.value,
        onValueChanged = () => {},
        disabled,
        bsSize,
        className,
        unit,
        inputAddon,
        ...remainingProps
    } = props;

    const timeout = useRef();

    const [isHoldingDownInc, setIsHoldingDownInc] = useState(false);
    const [isHoldingDownDec, setIsHoldingDownDec] = useState(false);
    const [internalValue, setInternalValue] = useState(value);

    const initialTimeout = callback => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            callback();
        }, INITIAL_TICK);
    };

    const recursiveTimeout = callback => {
        timeout.current = setTimeout(() => {
            callback();
        }, TICK_TIME);
    };

    useEffect(() => {
        // Call increment function for a loop when button is holding down
        if (isHoldingDownInc && !disabled) {
            initialTimeout(incrementRecusively);
        }

        // Call decrement function for a loop when button is holding down
        if (isHoldingDownDec && !disabled) {
            initialTimeout(decrementRecursively);
        }
    }, [isHoldingDownInc, isHoldingDownDec]);

    const incrementInternalValue = () => {
        setInternalValue(val => {
            const currentValue = convertNonIntegerToDefault(val);
            const newValue = currentValue + step;
            return newValue <= max ? newValue : val;
        });
    };

    const decrementInternalValue = () => {
        setInternalValue(val => {
            const currentValue = convertNonIntegerToDefault(val);
            const newValue = currentValue - step;
            return newValue >= min ? newValue : val;
        });
    };

    const incrementRecusively = () => {
        incrementInternalValue();
        recursiveTimeout(incrementRecusively);
    };

    const decrementRecursively = () => {
        decrementInternalValue();
        recursiveTimeout(decrementRecursively);
    };

    const handleMouseDownOnIncrement = () => {
        if (disabled) {
            return;
        }
        setIsHoldingDownInc(true);

        // increment for first click
        incrementInternalValue();
    };

    const handleMouseDownOnDecrement = () => {
        if (disabled) {
            return;
        }
        setIsHoldingDownDec(true);

        // decrement for first click
        decrementInternalValue();
    };

    const handleStopHolding = () => {
        clearTimeout(timeout.current);
        setIsHoldingDownInc(false);
        setIsHoldingDownDec(false);
    };

    const handleUpdatedNumberInputValue = value => {
        // Set the internal value when the value of the actual input has changed,
        // for instance the user has typed in a number manually and to
        // use this number as base to increase or decrease from.
        // Ignore empty value in case the user has removed it
        if (value && (!isHoldingDownDec || !isHoldingDownInc)) {
            setInternalValue(Number(value));
        }
        onValueChanged(value);
    };

    const classes = classNames('NumberControl', 'form-group', className);

    const inputGroupClassNames = classNames(
        'input-group',
        bsSize === 'sm' && 'input-group-sm',
        bsSize === 'lg' && 'input-group-lg'
    );

    return (
        <div {...remainingProps} className={classes}>
            <div className={inputGroupClassNames}>
                {inputAddon && (
                    <span className={'input-group-addon'}>
                        <span className={inputAddon} />
                    </span>
                )}
                <NumberInput
                    min={min}
                    max={max}
                    value={internalValue}
                    step={step}
                    disabled={disabled}
                    onValueChanged={handleUpdatedNumberInputValue}
                />
                <div className={'input-group-addon'}>
                    {unit && <div className={'margin-right-5'}>{unit}</div>}
                    <div
                        onMouseDown={handleMouseDownOnDecrement}
                        onMouseUp={handleStopHolding}
                        role={'button'}
                        aria-label={'decrement-button'}
                        className={'decrementButton text-color-gray hover-text-color-dark'}
                    >
                        <div className={'rioglyph rioglyph-minus scale-90'} />
                    </div>
                    <div
                        onMouseDown={handleMouseDownOnIncrement}
                        onMouseUp={handleStopHolding}
                        role={'button'}
                        aria-label={'increment-button'}
                        className={'incrementButton text-color-gray hover-text-color-dark margin-left-5'}
                    >
                        <div className={'rioglyph rioglyph-plus scale-90'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

NumberControl.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    onValueChanged: PropTypes.func,
    bsSize: PropTypes.string,
    className: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputAddon: PropTypes.string,
};

export default NumberControl;
