import React from 'react';
import {
    RadialBarChart as RechartsRadialBarChart,
    RadialBar as RechartsRadialBar,
    Legend as RechartsLegend,
    PolarAngleAxis,
    Cell,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import isFunction from 'lodash/fp/isFunction';

import Legend from './Legend';
import { getColor, getFromDefaultColors, CURSOR_BACKGROUND_COLOR } from './chartHelper';

const RadialBarChart = props => {
    const {
        width,
        height,
        data,
        dataKey,
        startAngle,
        endAngle,
        innerRadius,
        outerRadius,
        clockWise,
        legend,
        textOptions,
        radialBarOptions,
        showBarLabel,
        cornerRadius,
        background,
        range,
        tooltip,
        ...remainingProps
    } = props;

    const {
        text,
        size = '500%',
        weight = 'normal',
        color = 'gray-darkest',
        ...remainingTextOptions
    } = textOptions || {};

    const textStyle = {
        fontSize: size,
        fontWeight: weight,
        fill: color && getColor(color),
    };

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <RechartsRadialBarChart
                width={width}
                height={height}
                data={data}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                {...remainingProps}
            >
                <RechartsRadialBar
                    label={showBarLabel && { fill: '#666', position: 'insideStart' }}
                    background={background}
                    clockWise={clockWise}
                    dataKey={dataKey}
                    cornerRadius={cornerRadius}
                    {...radialBarOptions}
                >
                    {data.map((entry, index) => {
                        const cellColor = getColor(entry.color) || getFromDefaultColors(index);
                        return (
                            <Cell key={`cell-${index}`} fill={isFunction(color) ? color(entry, index) : cellColor} />
                        );
                    })}
                </RechartsRadialBar>

                {range && <PolarAngleAxis type='number' domain={range} angleAxisId={0} tick={false} />}

                {textOptions && (
                    <text
                        x={width / 2}
                        y={height / 2}
                        textAnchor='middle'
                        dominantBaseline='middle'
                        className='radialbarchart-text'
                        style={textStyle}
                        {...remainingTextOptions}
                    >
                        {text}
                    </text>
                )}

                {legend && (
                    <RechartsLegend
                        formatter={(value, _) => <span className='text-color-darker'>{value}</span>}
                        {...legend.props}
                    />
                )}

                {tooltip && <Tooltip cursor={{ fill: CURSOR_BACKGROUND_COLOR }} />}
            </RechartsRadialBarChart>
        </ResponsiveContainer>
    );
};

RadialBarChart.defaultProps = {
    width: 400,
    height: 200,
    data: [],
    dataKey: 'value',
    startAngle: 180,
    endAngle: 0,
    innerRadius: '30%',
    outerRadius: '100%',
    clockWise: true,
    legend: <Legend />,
    // textOptions,
    // radialBarOptions,
    showBarLabel: false,
    cornerRadius: 30,
    background: true,
    // range,
    tooltip: false,
};

export default RadialBarChart;
