import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import classNames from 'classnames';

const isValidDate = date => typeof date === 'object';

const DatePicker = props => {
    const { dropup, alignRight, onChange, className, mandatory, dateValidation, ...remainingProp } = props;

    const [hasError, setHasError] = useState(false);

    const validateDate = dateToValidate => {
        return dateValidation ? dateValidation(dateToValidate) : isValidDate(dateToValidate);
    };

    const handleChange = date => {
        const isValid = mandatory ? validateDate(date) : true;
        setHasError(!isValid);
        onChange(date, isValid);
    };

    const classes = classNames(
        'DatePicker',
        'form-group',
        hasError && 'has-error',
        dropup && 'dropup',
        alignRight && 'align-right',
        className && className
    );

    return <Datetime {...remainingProp} onChange={handleChange} className={classes} />;
};

DatePicker.defaultProps = {
    closeOnSelect: true,
    dropup: false,
    defaultValue: moment(),
    locale: 'en-GB',
    onChange: () => {},
    clearableInput: false,
    minWidth: 0,
    mandatory: true,
};

DatePicker.propTypes = {
    className: PropTypes.string,
    closeOnSelect: PropTypes.bool,
    clearableInput: PropTypes.bool,
    defaultValue: PropTypes.object,
    locale: PropTypes.string,
    onChange: PropTypes.func,
    dropup: PropTypes.bool,
    minWidth: PropTypes.number,
    alignRight: PropTypes.bool,
    mandatory: PropTypes.bool,
    dateValidation: PropTypes.func,
};

export default DatePicker;
