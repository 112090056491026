import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/Popover';

export const ActionBarOverlay = React.forwardRef((props, ref) => {
    const { children, show, preRender, id, title, onClick, width, style, className, ...rest } = props;

    const adjustedStyles = { ...style, width };
    const adjustedClassName = preRender && !show ? classNames(className, 'position-offscreen') : className;

    const popoverTitleClasses = classNames('ActionBarItemPopover popover-title text-color-dark', className);
    const popoverContentClasses = classNames('ActionBarItemPopover popover-content', className);

    return (
        <Popover ref={ref} id={id} onClick={onClick} {...rest} style={adjustedStyles} className={adjustedClassName}>
            {title && <Popover.Title className={popoverTitleClasses}>{title}</Popover.Title>}
            <Popover.Content className={popoverContentClasses}>{children}</Popover.Content>
        </Popover>
    );
});

ActionBarOverlay.defaultProps = {
    placement: 'bottom',
    width: 250,
};

ActionBarOverlay.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    preRender: PropTypes.bool,
    show: PropTypes.bool,
    width: PropTypes.oneOf([100, 150, 200, 250, 300, 350, 400, 450, 500]),
    onClick: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
};

export default ActionBarOverlay;
