import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import baseDialogPropTypes from './baseDialogPropTypes';

/*
 * Save dialog component to save, discard or cancel an action
 */
const SaveDialog = props => {
    const {
        content,
        onClickDiscard,
        discardButtonText,
        onClickCancel,
        cancelButtonText,
        onClickConfirm,
        confirmButtonText,
    } = props;

    const footer = (
        <div className='display-flex justify-content-end btn-toolbar'>
            <button type='button' className='DiscardButton btn btn-default' onClick={onClickDiscard}>
                {discardButtonText}
            </button>
            <button type='button' className='CancelButton btn btn-default' onClick={onClickCancel}>
                {cancelButtonText}
            </button>
            <button type='button' className='ConfirmationButton btn btn-primary' onClick={onClickConfirm}>
                {confirmButtonText}
            </button>
        </div>
    );

    return (
        <Dialog {...props} body={content} footer={footer} onHide={onClickCancel} className='save-dialog' disableEsc />
    );
};

SaveDialog.defaultProps = {
    show: false,
    showCloseButton: true,
    onClickCancel: () => {},
    onClickConfirm: () => {},
    onClickDiscard: () => {},
};

SaveDialog.propTypes = {
    ...baseDialogPropTypes,
    content: PropTypes.node,
    onClickCancel: PropTypes.func.isRequired,
    onClickDiscard: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    discardButtonText: PropTypes.node.isRequired,
    cancelButtonText: PropTypes.node.isRequired,
    confirmButtonText: PropTypes.node.isRequired,
};

export default SaveDialog;
