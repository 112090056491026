import React from 'react';
import PropTypes from 'prop-types';

import Tooltip, { TooltipPlacementType } from './Tooltip';
import OverlayTrigger, { triggerType } from '../overlay/OverlayTrigger';

const SimpleTooltip = props => {
    const { id, children, placement, trigger, content, ...remainingProps } = props;

    return (
        <OverlayTrigger
            placement={placement}
            trigger={trigger}
            overlay={<Tooltip {...remainingProps}>{content}</Tooltip>}
        >
            {children}
        </OverlayTrigger>
    );
};

SimpleTooltip.defaultProps = {
    placement: Tooltip.AUTO,
};

SimpleTooltip.propTypes = {
    placement: TooltipPlacementType,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    trigger: triggerType,
    targetRef: PropTypes.func,
    children: PropTypes.node.isRequired,
};

export default SimpleTooltip;
