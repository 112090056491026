import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ActionBarItemListItem = props => {
    const { hasLink, icon, onClick, className, children, ...remainingProps } = props;

    const classes = classNames('ActionBarItemListItem', className);
    const iconClasses = classNames('ActionBarItemListItemIcon', 'rioglyph', icon);
    const btnClasses = classNames('ActionBarItemListItemButton');

    return (
        <li {...remainingProps} className={classes}>
            {icon && <span className={iconClasses} />}
            {hasLink && !onClick && <span>{children}</span>}
            {onClick && (
                <div className={btnClasses} onClick={onClick}>
                    {children}
                </div>
            )}
            {!hasLink && !onClick && children}
        </li>
    );
};

ActionBarItemListItem.displayName = 'ActionBarItemListItem';

ActionBarItemListItem.propTypes = {
    hasLink: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export default ActionBarItemListItem;
