// eslint-disable-next-line max-len
// Inspired from https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-click-outside/use-click-outside.ts

import { useEffect, useRef } from 'react';

export default function useClickOutside(callback, eventTypes = ['mousedown', 'touchstart']) {
    const targetRef = useRef();

    useEffect(() => {
        const listener = event => {
            if (targetRef.current && !targetRef.current.contains(event.target)) {
                callback();
            }
        };

        eventTypes.forEach(eventType => document.addEventListener(eventType, listener));

        return () => {
            eventTypes.forEach(eventType => document.removeEventListener(eventType, listener));
        };
    }, [targetRef, callback]);

    return targetRef;
}
