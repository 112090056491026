const Area = () => null;

Area.defaultProps = {
    dataKey: 'value',
    dataUnit: '',
    strokeColor: 'color-coldplay-fountain',
    gradientId: 'areaGradient',
    legendType: 'square',
    type: 'monotone',
    isAnimationActive: true,
};

export default Area;
