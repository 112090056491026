import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const RadioButtonIcon = props => {
    const { icon, iconSize, iconLabelPosition, text } = props;

    const iconStyles = {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        fontSize: `${iconSize}px`,
        lineHeight: `${iconSize}px`,
    };

    return (
        <span className={`radio-icon label-${iconLabelPosition}`}>
            <span className={`rioglyph ${icon}`} style={iconStyles} />
            <span className={'radio-label'}>{text}</span>
        </span>
    );
};

const RadioButton = props => {
    const {
        checked,
        children,
        className,
        custom,
        defaultChecked,
        disabled,
        icon,
        iconLabelPosition,
        iconSize,
        inline,
        inputRef,
        label,
        name,
        onChange,
        onClick,
        right,
        tabIndex,
        value,
        ...remainingProps
    } = props;

    const labelRef = useRef();

    const handleToggleKeyDown = event => {
        switch (event.keyCode) {
            case 32:
                // toggle on space
                toggle(event);
                break;
            case 13:
                // open on enter
                toggle(event);
                break;
            default:
                break;
        }
    };

    const toggle = event => {
        event.preventDefault();

        if (disabled) {
            return;
        }

        // Controlled case - uses "onChange()" instead of "onClick()" callback
        if (checked !== null && checked !== undefined) {
            onChange(event);
            return;
        }

        // Uncontrolled case - set the input checked or unchecked
        labelRef.current.firstChild.checked = !labelRef.current.firstChild.checked;

        onClick(event);
    };

    const text = label || children;

    const labelClassnames = classname('radio', inline && 'radio-inline', className);
    const inputClassnames = classname(right && 'icon-right', className);

    const renderCustomIcon = !!icon;
    const renderCustomContent = custom && children;
    const renderDefault = !icon && !custom;

    return (
        <label
            {...remainingProps}
            className={labelClassnames}
            tabIndex={tabIndex}
            onKeyDown={handleToggleKeyDown}
            ref={labelRef}
        >
            <input
                type={'radio'}
                defaultChecked={defaultChecked}
                checked={checked}
                disabled={disabled}
                className={inputClassnames}
                ref={inputRef}
                onClick={onClick}
                onChange={onChange}
                name={name}
                value={value}
            />
            {renderCustomIcon && (
                <RadioButtonIcon icon={icon} iconSize={iconSize} iconLabelPosition={iconLabelPosition} text={text} />
            )}
            {renderDefault && (
                <span className={'radio-text'}>
                    <span>{text}</span>
                </span>
            )}
            {renderCustomContent && children}
        </label>
    );
};

RadioButton.ICON_LABEL_VERTICAL = 'vertical';
RadioButton.ICON_LABEL_HORIZONTAL = 'horizontal';

RadioButton.defaultProps = {
    inline: false,
    disabled: false,
    right: false,
    custom: false,
    tabIndex: 0,
    icon: '',
    iconSize: 16,
    iconLabelPosition: RadioButton.ICON_LABEL_VERTICAL,
    onClick: () => {},
};

RadioButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconLabelPosition: PropTypes.oneOf([RadioButton.ICON_LABEL_VERTICAL, RadioButton.ICON_LABEL_HORIZONTAL]),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    defaultChecked: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    inline: PropTypes.bool,
    right: PropTypes.bool,
    custom: PropTypes.bool,
    inputRef: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default RadioButton;
