import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ActionBarItemIcon = ({ className, children, ...remainingProps }) => {
    const classes = classNames('ActionBarItemIcon', className);
    return (
        <div {...remainingProps} className={classes}>
            {children}
        </div>
    );
};

ActionBarItemIcon.displayName = 'ActionBarItemIcon';

ActionBarItemIcon.propTypes = {
    className: PropTypes.string,
};

export default ActionBarItemIcon;
