import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ActionBarItemListSeparator = ({ className, ...remainingProps }) => {
    const classes = classNames('ActionBarItemListSeparator', className);
    return (
        <hr {...remainingProps} className={classes} />
    );
};

ActionBarItemListSeparator.displayName = 'ActionBarItemListSeparator';

ActionBarItemListSeparator.propTypes = {
    className: PropTypes.string,
};

export default ActionBarItemListSeparator;
