import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useAfterMount from '../../hooks/useAfterMount';

export const defaultProps = {
    min: 0,
    max: Number.MAX_VALUE,
    value: 0,
    step: 1,
};

const getStepFromProps = (propStep, defaultStep, max, min) => {
    return Number.isInteger(propStep) && propStep < Math.abs(max - min) ? propStep : defaultStep;
};

const getValueFromProps = (val, min, max, fallback) => clampNumber(Number(val), min, max) || fallback;

const clampNumber = (value, min, max) => {
    if (value < min) {
        return min;
    } else if (value > max) {
        return max;
    }
    return value;
};

const isFormatted = value => /^(-)?([1-9]\d*)?$/.test(value) || value === '0';

export const convertNonIntegerToDefault = (value, min) => {
    let currentValue = value;
    if (!Number.isInteger(currentValue)) {
        currentValue = min;
    }
    return currentValue;
};

const NumberInput = React.forwardRef((props, ref) => {
    const {
        unit,
        inputAddon,
        className,
        bsSize,
        disabled,
        onValueChanged = () => {},
        min: propMin,
        max: propMax,
        value: propValue,
        step: propStep,
        type: propType,
        ...remainingProps
    } = props;

    // Breaking change - default is now "number"
    const type = propType === 'text' ? 'text' : 'number';

    const min = Number.isInteger(propMin) ? propMin : defaultProps.min;
    const max = Number.isInteger(propMax) ? propMax : defaultProps.max;

    const value = getValueFromProps(propValue, min, max, defaultProps.value);
    const step = getStepFromProps(propStep, defaultProps.step, max, min);

    // Define local state and define initial values
    const [state, setState] = useState({
        value,
        enteredValue: value,
        isValid: true,
    });

    // Update internal value whenever the value prop from outside changes
    useAfterMount(() => {
        setState({
            value: getValueFromProps(propValue, min, max, defaultProps.value),
            enteredValue: value,
            isValid: true,
        });
    }, [propValue, clampNumber]);

    const applyValue = newValue => {
        if (isFormatted(newValue)) {
            if (newValue === '-' || newValue === '') {
                setState({
                    value: newValue,
                    enteredValue: newValue,
                    isValid: true,
                });
            } else {
                const enteredValue = Number(newValue);
                const isValid = enteredValue >= min && enteredValue <= max;
                const newValidValue = clampNumber(enteredValue, min, max);

                setState({
                    value: newValidValue,
                    enteredValue,
                    isValid,
                });

                // Only call back the caller for valid values
                isValid && onValueChanged(newValidValue);
            }
        }
    };

    const handleOnChange = event => applyValue(event.target.value);

    const handleBlur = event => {
        // console.log({enteredValue: state.enteredValue, event: Number(event.target.value)});

        // When the vales is removed, keep the input empty but trigger the onValueChanged callback
        // since the user has finished his input
        const lastEnteredValue = state.enteredValue;
        if (lastEnteredValue === '') {
            onValueChanged(lastEnteredValue);
            return;
        }

        // Otherwise, validate the input and calmp it if the entered value exeeds the limitations
        const convertedEnteredValue = convertNonIntegerToDefault(Number(lastEnteredValue), min);
        const validNumber = clampNumber(convertedEnteredValue, min, max);
        applyValue(validNumber);
    };

    const inputGroupClassNames = classNames(
        'input-group',
        bsSize === 'sm' && 'input-group-sm',
        bsSize === 'lg' && 'input-group-lg'
    );

    const inputClassNames = classNames(
        'form-control',
        'no-controls',
        bsSize === 'sm' && 'input-sm',
        bsSize === 'lg' && 'input-lg',
        className
    );

    const input = (
        <input
            {...remainingProps}
            type={type}
            step={step}
            min={min}
            max={max}
            value={state.isValid ? state.value : state.enteredValue}
            className={inputClassNames}
            disabled={disabled}
            onBlur={handleBlur}
            onChange={handleOnChange}
            ref={ref}
            aria-label={'number-input'}
        />
    );

    return unit || inputAddon ? (
        <div className={inputGroupClassNames}>
            {inputAddon && (
                <span className={'input-group-addon'}>
                    <span className={inputAddon} />
                </span>
            )}
            {input}
            {unit && <span className={'input-group-addon'}>{unit}</span>}
        </div>
    ) : (
        input
    );
});

NumberInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    step: PropTypes.number,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onValueChanged: PropTypes.func,
    bsSize: PropTypes.oneOf(['sm', 'lg', 'small', 'large']),
    className: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputAddon: PropTypes.string,
};

export default NumberInput;
