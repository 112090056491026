import has from 'lodash/fp/has';

export const BASE_URL = 'https://uikit.developers.rio.cloud/';

// Logs prop errors.
export const logPropError = (
    errorProps = [],
    props,
    componentName,
    removeVersion,
    additionalText
) => {
    errorProps.forEach((errorProp) => {
        // Check whether the deprected prop is used by checking the component props
        if (has(errorProp.prop, props)) {

            /* eslint-disable no-console */
            console.error([
                'RIO UIKIT: ',
                (errorProp.propCaption) ? errorProp.propCaption : 'Prop ',
                `"${errorProp.prop}"`,
                (componentName) ? ` of "${componentName}"` : '',
                ` has been removed`,
                (removeVersion) ? ` in v${removeVersion}` : '',
                (errorProp.replacement) ? `. Please use "${errorProp.replacement}" instead` : '',
                (errorProp.url) ? ` as documented in ${BASE_URL}${errorProp.url}. ` : '.',
                (additionalText) ? ` ${additionalText}` : '',
            ].join(''));
        }
    });
};

export default logPropError;
