import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import onClickOutside from 'react-onclickoutside';
import classname from 'classnames';
import isEqual from 'lodash/fp/isEqual';

import { BaseDropdownMenu } from './BaseDropdownMenu';

export class Multiselect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            selectedItems: props.value || [],
            isFilterActive: false,
            filterValue: '',
            filteredOptions: props.options,
            itemDOMValues: [],
            keyboardUsed: false,
        };

        // necessary for using the uikit as submodule
        this.onToggle = this.onToggle.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.onOptionChange = this.onOptionChange.bind(this);
        this.updateDOMValues = this.updateDOMValues.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.onOpenMenu = this.onOpenMenu.bind(this);
        this.handleToggleKeyDown = this.handleToggleKeyDown.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.updateSelectedItems(this.props.options, this.props.value);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateSelectedItems(nextProps.options, nextProps.value);

        if (!isEqual(nextProps.options, this.props.options)) {
            this.setState({
                filteredOptions: nextProps.options,
                requestItemDOMValues: true,
            });
        }
    }

    updateSelectedItems(options, value) {
        if (value) {
            this.setState(() => ({
                selectedItems: value,
            }));
        } else if (options) {
            this.setState(() => ({
                selectedItems: options
                    .filter(item => {
                        return item.selected ? item.id : false;
                    })
                    .map(option => option.id),
            }));
        }
    }

    updateDOMValues(itemDOMValues) {
        this.setState(() => ({
            itemDOMValues,
            requestItemDOMValues: false,
        }));
    }

    render() {
        const { className, hasError } = this.props;
        const dropup = this.isAutoDropActive() ? this.state.dropup : this.props.dropup;

        const classes = classname(
            'select',
            'multiselect',
            dropup && 'dropup',
            'dropdown',
            hasError && 'has-error',
            this.state.isOpen && 'open',
            className && className
        );

        return (
            <div className={classes} ref={multiselectWrapper => (this.multiselectWrapper = multiselectWrapper)}>
                {this.renderToggle()}
                {this.renderDropdownMenu()}
            </div>
        );
    }

    buildSelectionRepresentation(options) {
        const { showSelectedItemIcon, showUnselectedItemIcons } = this.props;

        if (!options || !options.length) {
            return '';
        }

        const selectionTags = options
            .filter(option => (showUnselectedItemIcons ? !option.header : this.isItemSelected(option)))
            .map((option, index) => this.renderSelectionItem(option, index));

        const optionListClassNames = classname(
            !showSelectedItemIcon && !showUnselectedItemIcons && 'selected-option-list'
        );

        return <span className={optionListClassNames}>{selectionTags}</span>;
    }

    renderCounterMessage(selectedItems, counterMessage, renderCounterMessage) {
        if (renderCounterMessage) {
            return renderCounterMessage(selectedItems.length);
        } else if (counterMessage) {
            const message = selectedItems.length === 1 ? counterMessage.one : counterMessage.many;
            return (
                <span>
                    <span className='option-counter margin-right-1'>{selectedItems.length}</span>
                    <span className='counter-message margin-left-1'>{message}</span>
                </span>
            );
        }
    }

    isItemSelected(item) {
        return this.state.selectedItems.indexOf(item.id) !== -1;
    }

    renderSelectionItem(item, index) {
        if (this.props.showSelectedItemIcon) {
            const inactiveClassNames = classname('margin-right-5', !this.isItemSelected(item) && 'inactiveIcon');

            return (
                <span key={item.id} className={inactiveClassNames}>
                    {item.icon}
                </span>
            );
        }

        return (
            <span key={index} className='selected-option'>
                <span className='selected-label'>
                    {item.icon && <span className={'margin-right-5'}>{item.icon}</span>}
                    {item.label}
                </span>
                <span
                    className='removeIcon'
                    onClick={event => {
                        this.removeSelectedItem(event, item.id);
                    }}
                >
                    <span className='rioglyph rioglyph-remove'></span>
                </span>
            </span>
        );
    }

    renderSelection() {
        const { options, counterMessage, renderCounterMessage } = this.props;
        const { selectedItems } = this.state;

        return counterMessage || renderCounterMessage
            ? this.renderCounterMessage(selectedItems, counterMessage, renderCounterMessage)
            : this.buildSelectionRepresentation(options);
    }

    renderPlaceholder(placeholder) {
        return placeholder ? <span className='placeholder'>{placeholder}</span> : <span />;
    }

    renderToggle() {
        const { isOpen, selectedItems } = this.state;
        const {
            name,
            id = name,
            tabIndex,
            disabled,
            useFilter,
            bsSize,
            placeholder,
            counterMessage,
            renderCounterMessage,
        } = this.props;

        const classnames = classname(
            'dropdown-toggle',
            'form-control',
            'text-left',
            bsSize === 'large' && 'input-lg', // TODO: deprecte since it's not consistent
            bsSize === 'small' && 'input-sm', // TODO: deprecte since it's not consistent
            bsSize === 'sm' && 'input-sm',
            bsSize === 'lg' && 'input-lg',
            disabled && 'disabled'
        );

        const showPlaceholder = !selectedItems.length && !this.props.showUnselectedItemIcons;

        // Note: due to issues with nested events in Firefox (for toggle and inside the remove)
        // the toggle element must not be a <button> but a div.
        return (
            <div
                type='button'
                id={id}
                name={name}
                className={classnames}
                data-toggle='dropdown'
                tabIndex={tabIndex}
                aria-haspopup='true'
                aria-expanded={isOpen}
                onClick={this.onToggle}
                onKeyDown={this.handleToggleKeyDown}
                ref={node => (this.refToggle = node)}
            >
                {showPlaceholder ? this.renderPlaceholder(placeholder) : this.renderSelection()}
                {useFilter && isOpen && !counterMessage && !renderCounterMessage && this.renderFilterInput()}
                <span className='caret' />
            </div>
        );
    }

    renderDropdownMenu() {
        const { pullRight, autoDropDirection, noItemMessage } = this.props;

        const options = this.state.filteredOptions.map(option => {
            option.selected = this.state.selectedItems.indexOf(option.id) !== -1;
            return option;
        });

        return (
            <BaseDropdownMenu
                isOpen={this.state.isOpen}
                options={options}
                keyboardUsed={this.state.keyboardUsed}
                updateDOMValues={this.updateDOMValues}
                onOpen={this.onOpenMenu}
                onSelect={this.onOptionChange}
                onClose={this.closeMenu}
                noItemMessage={noItemMessage}
                autoDropDirection={autoDropDirection}
                pullRight={pullRight}
                useActiveClass
            />
        );
    }

    renderFilterInput() {
        const inputClasses = classname(
            'multiselect-filter-input',
            !this.state.selectedItems.length && 'multiselect-filter-input-placeholder',
            (this.state.isFilterActive || this.state.filterValue) && 'multiselect-filter-input-active'
        );
        return (
            <input
                type='text'
                className={inputClasses}
                autoFocus
                onChange={this.handleFilterChange}
                defaultValue={this.state.filterValue}
            />
        );
    }

    filterOptions(itemDOMValues, filterValue, options) {
        const filteredDOMValues = itemDOMValues.filter(item =>
            item.text.toLowerCase().includes(filterValue.toLowerCase())
        );

        // Filter the options accodring to the filtered DOM values and map the IDs since the filter cannot be done
        // on the options itself as they might contain arbitrary components
        return options.filter(option => {
            return filteredDOMValues.find(value => value.id === option.id);
        });
    }

    handleFilterChange(event) {
        event.preventDefault();

        const filterValue = event.currentTarget.value;
        const filteredOptions = this.filterOptions(this.state.itemDOMValues, filterValue, this.props.options);

        // highlight the first item of the search result if at least one item was found
        const newFocusedItemIndex = filteredOptions.length > 0 ? 0 : -1;

        this.setState(() => ({
            isFilterActive: true,
            filterValue,
            filteredOptions,
            keyboardUsed: true,
            focusedItemIndex: newFocusedItemIndex,
        }));
    }

    onOptionChange(event, selectedItem) {
        event.preventDefault();

        // prevent selecting disabled Items
        if (selectedItem && selectedItem.disabled) {
            return;
        }

        const newSelectedItems = this.updateSelection(selectedItem.id);

        this.setState(() => ({
            selectedItems: newSelectedItems,
            isFilterActive: false,
            filterValue: '',
            filteredOptions: this.props.options,
        }));

        this.props.onChange(this.state.selectedItems);
    }

    updateSelection(selectedItemId) {
        const newSelectedItems = this.state.selectedItems;
        const itemIndex = this.state.selectedItems.indexOf(selectedItemId);

        if (itemIndex !== -1) {
            newSelectedItems.splice(itemIndex, 1);
        } else {
            newSelectedItems.push(selectedItemId);
        }

        return newSelectedItems;
    }

    removeSelectedItem(event, itemId) {
        event.stopPropagation();

        const newSelectedItems = this.updateSelection(itemId);
        this.setState(() => ({
            selectedItems: newSelectedItems,
        }));

        if (this.props.onChange) {
            this.props.onChange(this.state.selectedItems);
        }
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    handleToggleKeyDown(event) {
        switch (event.keyCode) {
            case 32:
                if (!this.state.isOpen) {
                    // open on space
                    event.preventDefault();
                    this.onToggle(event);
                }
                break;
            case 13:
                if (!this.state.isOpen) {
                    // open on enter
                    event.preventDefault();
                    this.onToggle(event);
                }
                break;
            default:
                break;
        }
    }

    onToggle(event) {
        // Dont toggle when component is disabled or an item in the toggle was clicked in order to remove from selection
        // neither close when filter is active, means entering some filter value
        // in order to avoid closing menu on space but allow to use it for filtering
        if (this.props.disabled || this.state.isFilterActive) {
            return;
        }

        // using the enter key on the toggle button will trigger a synthetic click event as all buttons are of
        // type submit by default in HTML. In order to differentiate between real click and a synthetic event
        // caused by they keyboard, use the event details. A synthetic event is always 0.
        const keyboardUsed = event.detail === 0;

        this.setState({
            isOpen: !this.state.isOpen,
            keyboardUsed,
        });
    }

    onOpenMenu(dropup) {
        const node = ReactDOM.findDOMNode(this);
        if (dropup) {
            node.classList.add('dropup');
        } else {
            node.classList.remove('dropup');
        }
    }

    closeMenu() {
        if (this.state.isOpen) {
            this.setState(() => ({
                isOpen: false,
                isFilterActive: false,
                keyboardUsed: false,
            }));
            this.refToggle.focus();
        }
    }

    handleClickOutside() {
        this.closeMenu();
    }
}

Multiselect.defaultProps = {
    onChange: () => {},
    options: [],
    selectedItems: [],
    disabled: false,
    autoDropDirection: true,
    pullRight: false,
    hasError: false,
    useFilter: false,
    tabIndex: 0,
    showSelectedItemIcon: false,
    showUnselectedItemIcons: false,
};

Multiselect.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            // Identify an option
            id: PropTypes.string.isRequired,
            // A label to show in body
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            // An icon to show in body
            icon: PropTypes.node,
            // Mark option as selected
            selected: PropTypes.bool,
            // Mark option as disabled
            disabled: PropTypes.bool,
            // Mark option as groupHeader
            header: PropTypes.bool,
        })
    ),
    // Callback params: option to change
    onChange: PropTypes.func,
    // Text to display when no menu item is selected
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    value: PropTypes.array, // FIXME: should be removed - use options.selected instead
    bsSize: PropTypes.oneOf(['sm', 'lg', 'small', 'large']),
    disabled: PropTypes.bool,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    hasError: PropTypes.bool,
    useFilter: PropTypes.bool,
    // Text to display when no menu item is selected
    noItemMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    // Text to display a counter for selected items
    counterMessage: PropTypes.shape({
        one: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        many: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
    // External function to render a counter message for selected items
    renderCounterMessage: PropTypes.func,
    // shows icons as selected values
    showSelectedItemIcon: PropTypes.bool,
    // shows icons always with active or inactive state
    showUnselectedItemIcons: PropTypes.bool,
};

export default onClickOutside(Multiselect);
