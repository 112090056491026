import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import Collapse from '../collapse/Collapse';
import classname from 'classnames';

export default class ExpanderList extends Component {
    /* istanbul ignore next */
    constructor(...args) {
        super(...args);

        this.state = {
            items: this.parseItems(this.props.items),
        };
    }

    parseItems(items) {
        return items.map((item, index) => {
            // Generate a unique id (if not present) instead of using the index for the key as it will create
            // side effects when removing items from the list and re-render the ExpanderList.
            if (!item.id) {
                item.id = `${index}-${Math.random()}`;
            }
            return item;
        });
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        // Map the internal open state to the new props in order to preserve opened items
        const mappedNextProps = nextProps.items.map(item => {
            if (item.open === undefined || item.open === null) {
                const matchingItem = this.state.items.find(oldItem => oldItem.id === item.id);
                if (matchingItem) {
                    item.open = matchingItem.open;
                }
            }
            return item;
        });

        // Only update the list when the amount of items is different or items inside have changed.
        // Note: When using the generated internal "id" the check is always true, as the id is regenerated for new
        // items, means it will always update the state and hence re-render.
        if (!isEqual(mappedNextProps, this.state.items)) {
            this.setState({
                items: this.parseItems(mappedNextProps),
            });
        }
    }

    handleExpand(itemToExpand) {
        // Toggle the open state for the selected item based on the provided or generated id
        const result = this.state.items.find(item => item.id === itemToExpand.id);
        result.open = !result.open;

        this.setState({
            items: this.state.items,
        });
    }

    getHeaderElement(item) {
        const headerClassNames = classname('expander-list-header', item.headerClassName && item.headerClassName);
        const iconClassNames = item.body && classname('expander-icon', 'rioglyph', 'rioglyph-chevron-down');
        return (
            <div
                className={headerClassNames}
                onClick={() => {
                    if (item.body) {
                        this.handleExpand(item);
                    }
                }}
            >
                <span className='expander-list-header-content'>{item.header}</span>
                <span className={iconClassNames}></span>
            </div>
        );
    }

    getBodyElement(item, isOpen, mountOnEnter, unmountOnExit) {
        if (!item.body) {
            return;
        }

        const bodyClassNames = classname('expander-list-body', item.bodyClassName && item.bodyClassName);

        return (
            <Collapse in={isOpen} mountOnEnter={mountOnEnter} unmountOnExit={unmountOnExit}>
                <div className='expander-list-body-wrapper'>
                    <div className={bodyClassNames}>{item.body}</div>
                </div>
            </Collapse>
        );
    }

    getListItem(item) {
        const isOpen = item.open;
        const itemClassNames = classname(
            'list-group-item',
            item.className && `${item.className}`,
            item.body && 'expandable',
            isOpen && 'open'
        );

        return (
            <li className={itemClassNames} key={item.id}>
                {this.getHeaderElement(item)}
                {this.getBodyElement(item, isOpen, this.props.mountOnEnter, this.props.unmountOnExit)}
            </li>
        );
    }

    render() {
        const listClassNames = classname('expander-list list-group', this.props.className);

        return (
            <ul className={listClassNames}>
                {this.state.items.map(item => {
                    return this.getListItem(item);
                })}
            </ul>
        );
    }
}

ExpanderList.defaultProps = {
    items: [],
    mountOnEnter: true,
    unmountOnExit: true,
};

ExpanderList.propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            open: PropTypes.bool,
            className: PropTypes.string,
            headerClassName: PropTypes.string,
            bodyClassName: PropTypes.string,
        })
    ).isRequired,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
};
