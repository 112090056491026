import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import Tooltip from '../tooltip/Tooltip';
import OverlayTrigger from '../overlay/OverlayTrigger';

const LEFT = OverlayTrigger.LEFT;
const TOP = OverlayTrigger.TOP;
const RIGHT = OverlayTrigger.RIGHT;
const BOTTOM = OverlayTrigger.BOTTOM;
const SMALL = 'small';
const LARGE = 'large';
const LIGHT = 'light';
const BOLD = 'bold';

const renderProgressBar = (
    { percentage = 0, tooltip, tooltipPosition = TOP, tooltipDelay = 50, color, className, ...props },
    index,
    useProgressDivider
) => {
    const classNames = classname(
        'progress-bar',
        color ? color : 'progress-bar-info',
        useProgressDivider && 'progress-divider',
        className && className
    );

    const progressBar = <div key={index} className={classNames} style={{ width: `${percentage}%` }} {...props} />;

    if (tooltip) {
        const tooltipElement = (
            <Tooltip id={`tooltip-${index}`} width={'auto'}>
                {tooltip}
            </Tooltip>
        );
        return (
            <OverlayTrigger key={index} placement={tooltipPosition} overlay={tooltipElement} delay={tooltipDelay}>
                {progressBar}
            </OverlayTrigger>
        );
    }

    return progressBar;
};

const renderAllProgressBars = (progress, useProgressDivider) => {
    if (!progress.length) {
        return null;
    }

    return progress.map((item, index) => {
        return renderProgressBar(item, index, useProgressDivider);
    });
};

const getIconAlignment = icon => icon.alignment || LEFT;
const getIconColorClassName = icon => icon.color || 'text-color-gray';

const getLabelAlignment = label => label.alignment || RIGHT;
const getLabelColorClassName = label => label.color || 'text-color-dark';

const getLabelAlignmentClassName = label => (label.alignment === TOP ? 'align-items-end' : 'align-items-center');

const getProgressBarHightClassName = size => (size === SMALL && 'height-5') || (size === LARGE && 'height-10');

const getIsIconLeftAndLabelRight = (icon, iconAlignment, label, labelAlignment) =>
    icon.name && iconAlignment === LEFT && label.value && labelAlignment === RIGHT;

const getIsIconRightAndLabelLeft = (icon, iconAlignment, label, labelAlignment) =>
    icon.name && iconAlignment === RIGHT && label.value && labelAlignment === LEFT;

const getIsIconAndLabelLeft = (icon, iconAlignment, label, labelAlignment) =>
    icon.name && iconAlignment === LEFT && label.value && labelAlignment === LEFT;

const getIsIconAndLabelRight = (icon, iconAlignment, label, labelAlignment) =>
    icon.name && iconAlignment === RIGHT && label.value && labelAlignment === RIGHT;

const getIsIconOnly = (label, icon) => !label.value && icon.name;
const getIsIconOnlyLeft = (isIconOnly, iconAlignment) => isIconOnly && iconAlignment === LEFT;
const getIsIconOnlyRight = (isIconOnly, iconAlignment) => isIconOnly && iconAlignment === RIGHT;

const getIsLabelOnly = (label, icon) => label.value && !icon.name;
const getIsLabelOnlyLeft = (isLabelOnly, labelAlignment) => isLabelOnly && labelAlignment === LEFT;
const getIsLabelOnlyRight = (isLabelOnly, labelAlignment) => isLabelOnly && labelAlignment === RIGHT;

const renderIcon = (icon, size) => {
    if (!icon.name) {
        return null;
    }

    const iconColor = getIconColorClassName(icon);
    const iconAlignment = getIconAlignment(icon);

    const iconWrapperClasses = classname(
        iconAlignment === LEFT && 'flex-order-1 margin-right-5',
        iconAlignment === RIGHT && 'flex-order-2 margin-left-5'
    );

    const iconClasses = classname(
        'rioglyph',
        icon.name && icon.name,
        iconColor,
        size === SMALL && 'text-size-h3',
        size === LARGE && 'text-size-h2',
        icon.className && icon.className
    );

    return (
        <div className={iconWrapperClasses}>
            <span className={iconClasses}></span>
        </div>
    );
};

const renderLabel = (label, size, labelWrapperClasses) => {
    if (!label.value) {
        return null;
    }

    const labelColor = getLabelColorClassName(label);
    const labelAlignment = getLabelAlignment(label);

    const labelClasses = classname(
        'display-flex',
        'ellipsis-1',
        'flex-1-0',
        'justify-content-center',
        labelAlignment === TOP && 'width-0',
        labelColor,
        size === SMALL && 'text-size-16',
        size === LARGE && 'text-size-h3',
        label.weight !== LIGHT && 'text-bold',
        label.className && label.className
    );

    return (
        <div className={labelWrapperClasses}>
            <span className={labelClasses}>{label.value}</span>
        </div>
    );
};

// eslint-disable-next-line complexity
const StatusBar = props => {
    const { progress = [], icon, label, size, className, useProgressDivider } = props;

    // Default values for icon and label alignment
    const iconAlignment = getIconAlignment(icon);
    const labelAlignment = getLabelAlignment(label);

    const isIconLeftAndLabelRight = getIsIconLeftAndLabelRight(icon, iconAlignment, label, labelAlignment);
    const isIconRightAndLabelLeft = getIsIconRightAndLabelLeft(icon, iconAlignment, label, labelAlignment);

    const isIconAndLabelLeft = getIsIconAndLabelLeft(icon, iconAlignment, label, labelAlignment);
    const isIconAndLabelRight = getIsIconAndLabelRight(icon, iconAlignment, label, labelAlignment);

    const isIconOnly = getIsIconOnly(label, icon);
    const isIconOnlyLeft = getIsIconOnlyLeft(isIconOnly, iconAlignment);
    const isIconOnlyRight = getIsIconOnlyRight(isIconOnly, iconAlignment);

    const isLabelOnly = getIsLabelOnly(label, icon);
    const isLabelOnlyLeft = getIsLabelOnlyLeft(isLabelOnly, labelAlignment);
    const isLabelOnlyRight = getIsLabelOnlyRight(isLabelOnly, labelAlignment);

    const wrapperClasses = classname(
        'StatusBar',
        'width-100pct',
        'display-flex',
        getLabelAlignmentClassName(label),
        className
    );

    const progressLabelWrapperClasses = classname(
        'display-flex',
        'flex-1-0',
        labelAlignment === TOP && 'flex-order-1 flex-column margin-bottom-10',
        isIconAndLabelRight && 'flex-order-1 align-items-center',
        isIconAndLabelLeft && 'flex-order-2 align-items-center',
        isIconLeftAndLabelRight && 'flex-order-2 align-items-center',
        isIconRightAndLabelLeft && 'flex-order-1 align-items-center',
        isLabelOnlyLeft && 'align-items-center',
        isLabelOnlyRight && 'align-items-center',
        isIconOnlyLeft && 'flex-order-2 align-items-center',
        isIconOnlyRight && 'flex-order-1 align-items-center'
    );

    const labelWrapperClasses = classname(
        labelAlignment === TOP && 'display-flex justify-content-center',
        isIconAndLabelLeft && 'flex-order-1 margin-right-10',
        isIconAndLabelRight && 'flex-order-2 margin-left-10',
        isIconLeftAndLabelRight && 'flex-order-2 margin-left-10',
        isIconRightAndLabelLeft && 'flex-order-1 margin-right-10',
        isLabelOnlyLeft && 'flex-order-1 margin-right-10',
        isLabelOnlyRight && 'flex-order-2 margin-left-10'
    );

    const progressOuterWrapperClasses = classname(
        'display-flex',
        'flex-1-0',
        isIconAndLabelLeft && 'flex-order-2',
        isIconAndLabelRight && 'flex-order-1',
        isIconLeftAndLabelRight && 'flex-order-1',
        isIconRightAndLabelLeft && 'flex-order-2',
        isLabelOnlyLeft && 'flex-order-2',
        isLabelOnlyRight && 'flex-order-1'
    );

    const progressWrapperClasses = classname('progress', 'margin-0', 'flex-1-0', getProgressBarHightClassName(size));

    return (
        <div className={wrapperClasses}>
            {renderIcon(icon, size)}
            <div className={progressLabelWrapperClasses}>
                {renderLabel(label, size, labelWrapperClasses)}
                <div className={progressOuterWrapperClasses}>
                    <div className={progressWrapperClasses}>{renderAllProgressBars(progress, useProgressDivider)}</div>
                </div>
            </div>
        </div>
    );
};

StatusBar.defaultProps = {
    size: SMALL,
    useProgressDivider: true,
    icon: {
        name: '',
        alignment: LEFT,
    },
    label: {
        value: '',
        alignment: RIGHT,
        weight: BOLD,
    },
    progress: [],
    className: '',
};

StatusBar.propTypes = {
    icon: PropTypes.shape({
        name: PropTypes.string.isRequired,
        alignment: PropTypes.oneOf([LEFT, RIGHT]),
        color: PropTypes.string,
        className: PropTypes.string,
    }),
    label: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        alignment: PropTypes.oneOf([LEFT, TOP, RIGHT]),
        weight: PropTypes.oneOf([LIGHT, BOLD]),
        color: PropTypes.string,
        className: PropTypes.string,
    }),
    progress: PropTypes.arrayOf(
        PropTypes.shape({
            percentage: PropTypes.number.isRequired,
            tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            tooltipPosition: PropTypes.oneOf([TOP, BOTTOM, LEFT, RIGHT]),
            tooltipDelay: PropTypes.number,
            color: PropTypes.string,
            className: PropTypes.string,
        })
    ),
    useProgressDivider: PropTypes.bool,
    size: PropTypes.oneOf([SMALL, LARGE]),
    className: PropTypes.string,
};

export default StatusBar;
