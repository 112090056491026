import { useEffect, useRef } from 'react';

export default function useAfterMount(callback, deps) {
    const componentJustMountedRef = useRef(true);

    useEffect(() => {
        if (!componentJustMountedRef.current) {
            return callback();
        }
        componentJustMountedRef.current = false;
    }, deps);
}
