import React from 'react';
import {
    BarChart as RechartsBarChart,
    XAxis,
    YAxis,
    Legend as RechartsLegend,
    Bar as RechartsBar,
    Brush,
    Cell,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

import { getFromDefaultColors, getColor, mapGridOptions, CURSOR_BACKGROUND_COLOR } from './chartHelper';

const BarChart = props => {
    const {
        width,
        height,
        data,
        dataUnit,
        dataKey,
        layout,
        legend,
        bars,
        xAxisOptions,
        yAxisOptions,
        gridOptions,
        showGrid,
        showXAxis,
        showYAxis,
        useDataColors,
        brush,
        tooltip,
        ...remainingProps
    } = props;

    return (
        <ResponsiveContainer>
            <RechartsBarChart layout={layout} width={width} height={height} data={data} {...remainingProps}>
                {showGrid && <CartesianGrid strokeDasharray='3 3' {...mapGridOptions(gridOptions)} />}

                <XAxis hide={!showXAxis} dataKey={dataKey} {...xAxisOptions} />
                <YAxis hide={!showYAxis} unit={dataUnit} {...yAxisOptions} />

                {legend && (
                    <RechartsLegend
                        payload={
                            useDataColors &&
                            data.map((entry, index) => {
                                const legendColor = getColor(entry.color) || getFromDefaultColors(index);
                                return {
                                    ...entry,
                                    type: 'square',
                                    color: legendColor,
                                };
                            })
                        }
                        {...legend.props}
                    />
                )}

                {tooltip && <Tooltip cursor={{ fill: CURSOR_BACKGROUND_COLOR }} />}

                {bars.map(bar => {
                    const { dataUnit, color, ...remainingBarProps } = bar.props;
                    const cellColor = color && getColor(color);

                    return (
                        <RechartsBar key={bar.key} unit={dataUnit} {...remainingBarProps} fill={cellColor}>
                            {useDataColors &&
                                data.map((entry, index) => {
                                    const cellColor = getColor(entry.color) || getFromDefaultColors(index);
                                    return <Cell key={`cell-${index}`} fill={cellColor} />;
                                })}
                        </RechartsBar>
                    );
                })}

                {brush && <Brush stroke={getColor('gray')} />}
            </RechartsBarChart>
        </ResponsiveContainer>
    );
};

BarChart.defaultProps = {
    width: 480,
    height: 200,
    data: [],
    // dataUnit,
    // dataKey,
    layout: 'horizontal',
    // legend,
    bars: [],
    // xAxisOptions,
    // yAxisOptions,
    // gridOptions,
    showGrid: false,
    showXAxis: true,
    showYAxis: true,
    useDataColors: false,
    brush: false,
    tooltip: true,
    legend: false,
};

export default BarChart;
