import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getSizeClassName = (bsSize) => {
    return (bsSize === 'xs' && 'btn-xs') || (bsSize === 'sm' && 'btn-sm') || (bsSize === 'lg' && 'btn-lg');
};

const DropdownToggleButton = React.forwardRef((props, ref) => {
    const {
        id,
        bsSize,
        bsStyle,
        disabled,
        iconOnly,
        splitButton,
        className,
        children,
        ...remainingProps
    } = props;

    const labelButtonClasses = classNames(
        !splitButton && 'dropdown-toggle',
        'btn',
        `btn-${bsStyle}`,
        getSizeClassName(bsSize),
        iconOnly && 'btn-icon-only',
        disabled && 'disabled',
        className && !splitButton && className
    );

    return (
        <button
            {...remainingProps}
            id={splitButton ? `button-${id}` : id}
            role={'button'}
            type={'button'}
            className={labelButtonClasses}
            ref={ref}
        >
            {children}
        </button>
    );
});

export default DropdownToggleButton;

DropdownToggleButton.propTypes = {
    disabled: PropTypes.bool,
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success', 'link', 'muted']),
    className: PropTypes.string,
};
