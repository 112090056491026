const Legend = () => null;

Legend.defaultProps = {
    iconType: 'square',
    iconSize: '12',
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
};

export default Legend;
