import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ApplicationLayoutHeader from './ApplicationLayoutHeader';
import ApplicationLayoutSidebar from './ApplicationLayoutSidebar';
import ApplicationLayoutBody from './ApplicationLayoutBody';

// initializes document bootstrapping - keep the import to avoid being tree-shaked
import { initDocumentBootstrapping } from '../../utils/init';
initDocumentBootstrapping;

export const ApplicationLayout = ({ className, children }) => {
    const classes = classNames('ApplicationLayout', className);

    return <div className={classes}>{children}</div>;
};

ApplicationLayout.Header = ApplicationLayoutHeader;
ApplicationLayout.Sidebar = ApplicationLayoutSidebar;
ApplicationLayout.Body = ApplicationLayoutBody;

ApplicationLayout.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayout;
