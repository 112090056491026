import React from 'react';
import Button from './Button';

const ToggleButton = props => (
    <Button {...props}  asToggle={true} />
)

ToggleButton.defaultProps = Button.defaultProps;

ToggleButton.propTypes = Button.propTypes;

export default ToggleButton;