import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TreeRoot = ({ maxHeight, children }) => {
    const treeRootClasses = classNames('TreeRoot', 'user-select-none');

    return (
        <div className={treeRootClasses} style={{ maxHeight: maxHeight }}>
            {children}
        </div>
    );
};

TreeRoot.displayName = 'TreeRoot';

TreeRoot.propTypes = {
    maxHeight: PropTypes.number,
};

export default TreeRoot;
