/* eslint-disable new-cap */
import React from 'react';
import classNames from 'classnames';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';

import Checkbox from '../checkbox/Checkbox';
import Collapse from '../collapse/Collapse';
import { TableSettingsColumnDetails } from './TableSettingsColumnDetails';
import { TableSettingsColumnButtons } from './TableSettingsColumnButtons';

const MAX_COLUMN_WIDTH = 1000;

export const filterColumns = (searchValue, column = '', columnLabelStrings) => {
    if (!searchValue) {
        return false;
    }
    const label = columnLabelStrings[column] || '';
    return !label.toLowerCase().includes(searchValue.toLowerCase());
};

const SettingsItemLabel = SortableHandle(({ column, columnLabels, className = '' }) => (
    <div className={`table-settings-item-label ${className}`} data-key={column}>
        {columnLabels[column]}
    </div>
));

const TableSettingsListItem = SortableElement(props => {
    const {
        value: column,
        orderIndex,
        columnLabels,
        autoLabel,
        disabledColumns,
        columnOrder,
        hiddenColumns = [],
        columnSearchValue,
        columnsDetails,
        columnLabelStrings,
        openColumnsDetails,
        updateColumnLabelStrings,
        onColumnWidthChange,
        onResetColumnWidth,
        onMoveColumn,
        onOpenDetails,
        onToggleHideColumn,
    } = props;

    // Filter out items which don't match the search value.
    // Note that we need to render all items at least once at the beginning in order to get their DOM lables
    // otherwise the search won't work when initial search value is provided via props.
    const isFiltered = filterColumns(columnSearchValue, column, columnLabelStrings) && !updateColumnLabelStrings;

    const itemClassNames = classNames(
        'table-settings-item',
        updateColumnLabelStrings && 'opacity-0',
        isFiltered && 'position-offscreen'
    );

    const columnDetails = columnsDetails[column];

    return (
        <div className={itemClassNames} key={`table-settings-item-${column}`}>
            <div className={'table-settings-item-header'}>
                <div className={'CheckboxWrapper display-flex align-items-center padding-left-2'}>
                    <Checkbox
                        checked={!hiddenColumns.includes(column)}
                        onClick={event => {
                            onToggleHideColumn(column);
                            event.stopPropagation();
                        }}
                        disabled={disabledColumns.includes(column)}
                    />
                </div>
                <SettingsItemLabel
                    column={column}
                    columnLabels={columnLabels}
                    className={columnSearchValue && 'no-drag'}
                />
                {columnDetails && (
                    <div className={'column-width-label'}>
                        {columnDetails.width ? `${columnDetails.width}px` : autoLabel}
                    </div>
                )}
                <TableSettingsColumnButtons
                    column={column}
                    index={orderIndex}
                    columnDetails={columnDetails}
                    columnOrder={columnOrder}
                    openColumnsDetails={openColumnsDetails}
                    columnSearchValue={columnSearchValue}
                    onMoveColumn={onMoveColumn}
                    onOpenDetails={onOpenDetails}
                />
            </div>
            {columnDetails && (
                <Collapse in={!!openColumnsDetails[column]}>
                    <div>
                        <TableSettingsColumnDetails
                            {...columnDetails}
                            column={column}
                            maxColumnWidth={MAX_COLUMN_WIDTH}
                            onColumnWidthChange={onColumnWidthChange}
                            onResetColumnWidth={onResetColumnWidth}
                        />
                    </div>
                </Collapse>
            )}
        </div>
    );
});

export default TableSettingsListItem;
