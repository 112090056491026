import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DialogFooter = ({ className, children }) => {
    const footerClassNames = classNames('modal-footer', className);
    return <div className={footerClassNames}>{children}</div>;
};

DialogFooter.defaultProps = {
    className: '',
};

DialogFooter.propTypes = {
    className: PropTypes.string,
};

export default DialogFooter;
