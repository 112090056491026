/* eslint-disable complexity */
import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import { baseStatePropTypes, ALIGN_LEFT, ALIGN_CENTER, ALIGN_RIGHT } from './baseStatePropTypes';

const StateButton = ({ text, className, onClick = () => {}, href }) => {
    const buttonClassNames = classNames('btn btn-primary', 'margin-top-10', className && className);

    // In case a href is given, render a link button instead
    if (href) {
        return (
            <a className={buttonClassNames} href={href} onClick={onClick}>
                {text}
            </a>
        );
    }

    return (
        <button className={buttonClassNames} type='button' onClick={onClick}>
            {text}
        </button>
    );
};

const Icon = ({ name, color, className }) => {
    const iconClassNames = classNames(
        'rioglyph',
        color || 'text-color-light',
        name && name,
        !className && 'text-size-300pct',
        className && className
    );
    return <span className={iconClassNames}></span>;
};

const getAlignmentClassName = alignment => {
    switch (alignment) {
        case ALIGN_LEFT:
            return 'justify-content-start';
        case ALIGN_CENTER:
            return 'justify-content-center';
        case ALIGN_RIGHT:
            return 'justify-content-end';
        default:
            return 'justify-content-center';
    }
};

const CustomState = props => {
    const {
        icon,
        icons,
        image,
        headline,
        message,
        buttons,
        alignment,
        fullWidth,
        condensed,
        outerClassName,
        innerClassName,
        children,
        ...remainingProps
    } = props;

    const wrapperClasses = classNames(
        'margin-0',
        'width-100pct',
        !fullWidth && 'max-width-600',
        'panel-default',
        'panel',
        condensed ? 'padding-10' : 'padding-15',
        outerClassName && outerClassName
    );

    const innerClassNames = classNames(
        'display-flex',
        'flex-column',
        'align-items-center',
        'text-center',
        condensed ? 'padding-15' : 'padding-25',
        innerClassName && innerClassName
    );

    const iconClasses = classNames(condensed ? 'text-size-16 margin-bottom-10' : 'text-size-h2 margin-bottom-20');

    const iconsClasses = classNames(
        condensed ? 'text-size-16 margin-bottom-10' : 'text-size-h2 margin-bottom-20',
        'display-flex',
        'align-items-center',
        'max-width-100pct'
    );

    const headlineClassNames = classNames(
        condensed ? 'text-size-h4' : 'text-size-h3 text-size-h2-sm',
        'line-height-125rel',
        'text-color-dark',
        'text-medium',
        'width-500',
        'max-width-100pct'
    );

    const renderButtons = (
        <div className='btn-toolbar margin-top-20'>
            {buttons.map((buttonProps, index) => (
                <StateButton key={index} {...buttonProps} />
            ))}
        </div>
    );

    const renderIcon = (
        <div className={iconClasses}>
            <Icon name={icon} />
        </div>
    );

    const renderIcons = (
        <div className={iconsClasses}>
            {icons.map((iconProps, index) => (
                <Icon key={index} {...iconProps} />
            ))}
        </div>
    );

    const renderChildren = <div className={'width-500 max-width-100pct margin-top-25'}>{children}</div>;

    const renderHeadline = <div className={headlineClassNames}>{headline}</div>;

    const renderMessage = <div className={'text-color-dark margin-top-15 width-500 max-width-100pct'}>{message}</div>;

    const singleOrMultiIcon = isEmpty(icons) ? renderIcon : renderIcons;
    const imageOrIcon = image ? image : singleOrMultiIcon;

    return (
        <div className={`display-flex ${getAlignmentClassName(alignment)} max-width-100pct`} {...remainingProps}>
            <div className={wrapperClasses}>
                <div className={innerClassNames}>
                    {imageOrIcon}
                    {headline && renderHeadline}
                    {message && renderMessage}
                    {!isEmpty(buttons) && renderButtons}
                    {children && renderChildren}
                </div>
            </div>
        </div>
    );
};

CustomState.defaultProps = {
    icon: 'rioglyph-rio',
    icons: [],
    buttons: [],
    alignment: ALIGN_CENTER,
    fullWidth: false,
    condensed: false,
};

CustomState.propTypes = baseStatePropTypes;

export default CustomState;
