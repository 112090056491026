import React from 'react';
import {
    PieChart as RechartsPieChart,
    Pie,
    Legend as RechartsLegend,
    Cell,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import isFunction from 'lodash/fp/isFunction';

import { getFromDefaultColors, getColor } from './chartHelper';
import Legend from './Legend';

const RADIAN = Math.PI / 180;

const PieChart = props => {
    const {
        width,
        height,
        innerRadius,
        outerRadius,
        data,
        dataKey,
        dataUnit,
        nameKey,
        color,
        filled,
        labels,
        innerLabels,
        paddingAngle,
        legend,
        tooltip,
        pieOptions,
        ...remainingProps
    } = props;

    const renderInnerLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
                {`${value} ${dataUnit}`}
            </text>
        );
    };

    const renderLabels = entry => {
        return `${isFunction(dataKey) ? dataKey(entry) : entry[dataKey]} ${dataUnit}`;
    };

    const pieLabel = labels && (innerLabels ? renderInnerLabel : renderLabels);

    return (
        <ResponsiveContainer width={width} height={height}>
            <RechartsPieChart {...remainingProps}>
                {legend && (
                    <RechartsLegend
                        formatter={(value, entry) => <span className='text-color-darker'>{value}</span>}
                        {...legend.props}
                    />
                )}

                <Pie
                    data={data}
                    isAnimationActive={true}
                    nameKey={nameKey}
                    dataKey={dataKey}
                    label={pieLabel}
                    // cx={Math.max(outerRadius, width / 3)}
                    // cy={height / 2}
                    innerRadius={filled ? 0 : innerRadius}
                    outerRadius={outerRadius}
                    paddingAngle={filled ? 0 : paddingAngle}
                    labelLine={!innerLabels}
                    {...pieOptions}
                >
                    {data.map((entry, index) => {
                        const cellColor = getColor(entry.color) || getFromDefaultColors(index);
                        return (
                            <Cell
                                key={`cell-${index}`}
                                fill={isFunction(color) ? getColor(color(entry, index)) : cellColor}
                            />
                        );
                    })}
                </Pie>

                {tooltip && <Tooltip formatter={value => `${value} ${dataUnit}`} />}
            </RechartsPieChart>
        </ResponsiveContainer>
    );
};

PieChart.defaultProps = {
    width: '100%',
    height: 250,
    innerRadius: 60,
    outerRadius: 80,
    data: [],
    dataKey: 'value',
    dataUnit: '',
    nameKey: 'name',
    // color,
    filled: false,
    labels: true,
    innerLabels: false,
    paddingAngle: 3,
    // pieOptions,
    legend: <Legend />,
    tooltip: true,
};

export default PieChart;
