import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import moment from 'moment';

const MINUTES_OFFSET = 15;

const mask = [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/];
const fullRegexp = /^(?:\d|[01]\d|2[0-3]):[0-5]\d$/;

const getPadded = value => (value >= 10 ? value : `0${value}`);
const formatTimeString = (hours, minutes) => `${getPadded(hours)}:${getPadded(minutes)}`;

const roundedUp = (time, offset) => Math.floor(time.minutes() / offset) * offset;

const getInitialTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return formatTimeString(hours, minutes);
};

const isTimeValid = value => fullRegexp.test(value);

const isControlledAndValid = value => {
    const hasExternalValue = !isNil(value);
    const isValid = isEmpty(value) || isTimeValid(value);
    if (hasExternalValue && !isValid) {
        throw Error('Wrong time format. Please pass in value with the following format: "hh:mm".');
    }
    return hasExternalValue && isValid;
};

const TimePicker = props => {
    const { onChange, value, className, alwaysShowMask, showIcon, inputProps, ...remainingProps } = props;

    const initialTime = isControlledAndValid(value) ? value : getInitialTime();

    const [timeValue, setTimeValue] = useState(initialTime);

    // Update internal time value from outside when used as a controlled component
    useEffect(() => {
        if (isControlledAndValid(value)) {
            setTimeValue(value);
            onChange(value);
        }
    }, [value]);

    const handleChangeTime = event => {
        const currentValue = event.target.value;

        setTimeValue(currentValue);

        if (fullRegexp.test(currentValue)) {
            onChange(currentValue);
        }
    };

    const isValid = isTimeValid(timeValue);

    const handleIncrease = () => {
        const time = moment(`2020-01-01 ${timeValue}`);
        const rounded = roundedUp(time, MINUTES_OFFSET);
        time.minute(rounded).second(0);
        time.add(MINUTES_OFFSET, 'minutes');
        const updatedTimeValue = formatTimeString(time.hours(), time.minutes());

        setTimeValue(updatedTimeValue);
        onChange(updatedTimeValue);
    };

    const handleDecrease = () => {
        const time = moment(`2020-01-01 ${timeValue}`);
        const rounded = roundedUp(time, MINUTES_OFFSET);
        time.minute(rounded).second(0);
        time.subtract(MINUTES_OFFSET, 'minutes');
        const updatedTimeValue = formatTimeString(time.hours(), time.minutes());

        setTimeValue(updatedTimeValue);
        onChange(updatedTimeValue);
    };

    const { className: inputClassName } = inputProps;

    return (
        <div {...remainingProps} className={classNames('TimePicker', 'input-group', className)}>
            {showIcon && 
                <span className='input-group-addon'>
                    <span className='rioglyph rioglyph-time-alt' />
                </span>
            }
            <InputMask
                {...inputProps}
                className={classNames('TimePickerInput', 'form-control', inputClassName)}
                mask={mask}
                maskPlaceholder={'-'}
                value={timeValue}
                onChange={handleChangeTime}
                alwaysShowMask={alwaysShowMask}
            />
            {isValid && (
                <div className={'TimePickerIncreaseButton input-group-addon'}>
                    <div className={'text-color-gray hover-text-color-dark'} onClick={handleDecrease}>
                        <div className={'rioglyph rioglyph-chevron-left scale-90'} />
                    </div>
                    <div className={'text-color-gray hover-text-color-dark margin-left-5'} onClick={handleIncrease}>
                        <div className={'rioglyph rioglyph-chevron-right scale-90'} />
                    </div>
                </div>
            )}
        </div>
    );
};

TimePicker.defaultProps = {
    onChange: () => {},
    alwaysShowMask: true,
    showIcon: false,
    className: '',
    inputProps: {},
};

TimePicker.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    showIcon: PropTypes.bool,
    className: PropTypes.string,
    inputProps: PropTypes.shape({
        className: PropTypes.string,
    }),
};

export default TimePicker;
