import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../checkbox/Checkbox';

const TreeNode = React.memo(props => {
    const { node, hasMultiselect, isSelected, isIndeterminate, onToggleNode, onSelect } = props;

    const treeNodeClassNames = classNames(
        'TreeNode',
        'from-group',
        isSelected && 'checked',
        node.className && node.className
    );

    const hasChildren = !!node.items.length;

    return (
        <div className={treeNodeClassNames} data-key={node.id} onClick={() => hasChildren && onToggleNode(node.id)}>
            {hasMultiselect && (
                <Checkbox
                    className={'TreeCheckbox'}
                    checked={isSelected}
                    disabled={node.disabled}
                    indeterminate={isIndeterminate}
                    onClick={() => onSelect(node, isIndeterminate)}
                />
            )}
            <span className={'TreeLabel'}>
                <span className={'TreeLabelName'}>
                    {node.icon && <span className={`rioglyph ${node.icon}`}></span>}
                    <span className={'TreeLabelNameText'}>
                        <span className={'TreeLabelNameTextHeadline'}>
                            {node.name}
                        </span>
                    </span>
                    <span className={'TreeLabelCount label label-muted label-filled label-condensed'}>
                        {node.items.length}
                    </span>
                </span>
                <span
                    className={`TreeLabelExpander rioglyph rioglyph-chevron-down ${
                        hasChildren ? '' : 'text-color-light'
                    }`}
                ></span>
            </span>
        </div>
    );
});

TreeNode.displayName = 'TreeNode';

TreeNode.defaultProps = {
    isSelected: false,
    isIndeterminate: false,
    hasMultiselect: true,
};

TreeNode.propTypes = {
    node: PropTypes.object,
    hasMultiselect: PropTypes.bool,
    isSelected: PropTypes.bool,
    isIndeterminate: PropTypes.bool,
    onToggleNode: PropTypes.func,
    onSelect: PropTypes.func,
    className: PropTypes.string,
};

export default TreeNode;
