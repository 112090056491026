import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';

export const ActionBarItemPopoverContent = ({ className, children, ...remainingProps }) => {
    const classes = classNames('ActionBarItemPopoverContent', className);
    const filterProps = omit(['title', 'useOffscreen']);

    return (
        <div {...filterProps(remainingProps)} className={classes}>
            {children}
        </div>
    );
};

ActionBarItemPopoverContent.displayName = 'ActionBarItemPopoverContent';

ActionBarItemPopoverContent.propTypes = {
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    className: PropTypes.string,
    useOffscreen: PropTypes.bool,
};

export default ActionBarItemPopoverContent;
