import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Dialog from '../dialog/Dialog';
import BrowserWarningMessageDE from './BrowserWarningMessageDE';
import BrowserWarningMessageEN from './BrowserWarningMessageEN';

const UNSUPPORTED_BROWSER_TAG = 'ua-ie';

const getLanguage = () => {
    if (window) {
        return window.navigator.language.toLowerCase();
    }
    return '';
};

const BrowserWarning = props => {
    const { show, onClose, onBrowserSelect } = props;
    const [showWarning, setShowWarning] = useState(show);

    const handleClose = () => {
        setShowWarning(false);
        onClose(false);
    };

    // Force to show the dialog from outside
    useEffect(() => setShowWarning(show), [show]);

    // check HTML tag for browsers only on mount
    useEffect(() => {
        if (
            document &&
            document.documentElement &&
            document.documentElement.classList.contains(UNSUPPORTED_BROWSER_TAG)
        ) {
            setShowWarning(true);
        }
    }, []);

    const lang = getLanguage();
    const isDE = lang.startsWith('de');

    const body = isDE ? (
        <BrowserWarningMessageDE onBrowserSelect={onBrowserSelect} />
    ) : (
        <BrowserWarningMessageEN onBrowserSelect={onBrowserSelect} />
    );

    const footerText = isDE ? 'Weiter im IE11' : 'Continue with IE11';

    const footer = (
        <div className='display-flex justify-content-end'>
            <button type='button' className='btn btn-link' onClick={handleClose}>
                {footerText}
            </button>
        </div>
    );

    return <Dialog show={showWarning} className='backdrop-blured' body={body} footer={footer} onHide={handleClose} />;
};

BrowserWarning.defaultProps = {
    onClose: () => {},
    onBrowserSelect: () => {},
};

BrowserWarning.propTypes = {
    onClose: PropTypes.func,
    onBrowserSelect: PropTypes.func,
};

export default BrowserWarning;
