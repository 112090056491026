import React from 'react';
import PropTypes from 'prop-types';
import { default as _Popover } from 'react-bootstrap/Popover';
import Tooltip from '../tooltip/Tooltip';

const Popover = React.forwardRef((props, ref) => {
    const { title, titleClassName = '', contentClassName = '', children, ...remainingProps } = props;
    return (
        <_Popover ref={ref} {...remainingProps}>
            {title && <_Popover.Title className={`popover-title ${titleClassName}`}>{title}</_Popover.Title>}
            {children && (
                <_Popover.Content className={`popover-content ${contentClassName}`}>{children}</_Popover.Content>
            )}
        </_Popover>
    );
});

Popover.AUTO = Tooltip.AUTO;
Popover.TOP = Tooltip.TOP;
Popover.BOTTOM = Tooltip.BOTTOM;
Popover.RIGHT = Tooltip.RIGHT;
Popover.LEFT = Tooltip.LEFT;

Popover.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.node.isRequired,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
};

export default Popover;
