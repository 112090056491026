import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ClearableInput from '../clearableInput/ClearableInput';

const TreeSearch = React.memo(props => {
    const { value, className, placeholder, onChange } = props;

    const classes = classNames('TreeSearch', className);

    return (
        <div className={classes}>
            <div className={'input-group flex-1-0'}>
                <span className={'input-group-addon'}>
                    <span className={'rioglyph rioglyph-search'} aria-hidden={'true'}></span>
                </span>
                <ClearableInput value={value} onChange={onChange} placeholder={placeholder} />
            </div>
        </div>
    );
});

TreeSearch.displayName = 'TreeSearch';

TreeSearch.defaultProps = {
    onChange: () => {},
};

TreeSearch.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

export default TreeSearch;
