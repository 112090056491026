import { useEffect, useRef } from 'react';

export default function useOnMount(callback, deps) {
    const componentJustMountedRef = useRef(true);

    useEffect(() => {
        if (componentJustMountedRef.current) {
            return callback();
        }
        componentJustMountedRef.current = true;
    }, deps);
}
