import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

import AppMenuDropdown from './AppMenuDropdown';
import modulePropTypes from './modulePropTypes';

const renderMenuContent = props => {
    const { appMenuItems, appNavigator } = props;

    // In case there is a navigation component injected into the header,
    // render this component
    if (appNavigator) {
        return [
            <li role='presentation' key='app-navigator'>
                {appNavigator}
            </li>,
        ];
    }

    // If there is a list of appMenuItems given, render them as a list
    return appMenuItems.map(module => {
        return (
            <li role='presentation' key={`link-icon-${module.key}`}>
                {module.route}
            </li>
        );
    });
};

export const AppMenu = props => {
    const { label = 'Unknown', appMenuItems, appNavigatorClassName, appNavigator } = props;
    const hasItems = !!((appMenuItems && appMenuItems.length) || appNavigator);

    const classNames = classname(
        'ModuleNavigation AppMenu',
        'user-select-none',
        { hasModules: hasItems },
        appNavigatorClassName && appNavigatorClassName,
    );

    return (
        <ul className={classNames}>
            <AppMenuDropdown title={label} caret={hasItems}>
                {renderMenuContent(props)}
            </AppMenuDropdown>
        </ul>
    );
};

AppMenu.defaultProps = {
    appMenuItems: [],
};

AppMenu.propTypes = {
    appMenuItems: PropTypes.arrayOf(modulePropTypes),
    appNavigator: PropTypes.element,
    appNavigatorClassName: PropTypes.string,
};
