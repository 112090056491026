import { useEffect } from 'react';

export default function useKey(callback, eventTypes = ['keydown'], node = document) {
    useEffect(() => {
        eventTypes.forEach(eventType => node.addEventListener(eventType, callback));
        return () => {
            eventTypes.forEach(eventType => node.removeEventListener(eventType, callback));
        };
    }, [callback]);
}
