/**
 * @fileOverview: Wrapper component for React-Datetime.
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import DatePicker from './DatePicker';
import onClickOutside from 'react-onclickoutside';
import moment from 'moment';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';
import isNull from 'lodash/fp/isNull';
import isEqual from 'lodash/fp/isEqual';
import getDropDirection from '../../utils/getDropDirection';

const Placeholder = ({ text }) => <span className={'placeholder'}>{text}</span>;

/**
 * The DateRangePicker
 */

const propTypes = {
    startValue: PropTypes.object,
    endValue: PropTypes.object,
    defaultStartValue: PropTypes.object,
    defaultEndValue: PropTypes.object,
    minValue: PropTypes.object,
    maxValue: PropTypes.object,
    onRangeChange: PropTypes.func,
    locale: PropTypes.string,
    textDefault: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textToday: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textLastWeek: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textLastMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textLastYear: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textCustom: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textApply: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textCancel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    textTo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    dropdownClass: PropTypes.string,
    dropdownMenuClass: PropTypes.string,
    hasTimePicker: PropTypes.bool,
    customRangeOnly: PropTypes.bool,
    customPresets: PropTypes.arrayOf(
        PropTypes.shape({
            startValue: PropTypes.object.isRequired,
            endValue: PropTypes.object.isRequired,
            text: PropTypes.string.isRequired,
            onSelect: PropTypes.func,
        })
    ),
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    clearable: PropTypes.bool,
    onClear: PropTypes.func,
};

const defaultProps = {
    defaultStartValue: moment().startOf('day'),
    defaultEndValue: moment().endOf('day'),
    minValue: null,
    maxValue: null,
    locale: 'en-GB',
    textDefault: 'Select date range ...',
    textToday: 'Today',
    textLastWeek: 'Last week',
    textLastMonth: 'Last month',
    textCustom: 'Custom',
    textFrom: 'From',
    textTo: 'To',
    textApply: 'Apply',
    textCancel: 'Cancel',
    dropdownClass: '',
    dropdownMenuClass: '',
    hasTimePicker: false,
    customRangeOnly: false,
    customPresets: [
        {
            text: 'Today',
            startValue: moment().startOf('day'),
            endValue: moment().endOf('day'),
        },
        {
            text: 'Last week',
            startValue: moment().subtract(1, 'weeks').startOf('isoWeek'),
            endValue: moment().subtract(1, 'weeks').endOf('isoWeek'),
        },
        {
            text: 'Last Month',
            startValue: moment().subtract(1, 'months').startOf('month'),
            endValue: moment().subtract(1, 'months').endOf('month'),
        },
    ],
    onRangeChange: () => {
        // function when range changes
    },
    autoDropDirection: true,
    dropup: false,
    pullRight: false,
    clearable: false,
    onClear: () => undefined,
};

const LOCAL_DATE_FORMAT = 'dd L LT';
const LOCAL_DATE_FORMAT_NO_TIME = 'dd L';

const DROPDOWN_WIDTH = 600;

class DateRangePicker extends Component {
    constructor(props) {
        super(props);

        const customRangeStartValue = props.startValue ? props.startValue : props.defaultStartValue;
        const customRangeEndValue = props.endValue ? props.endValue : props.defaultEndValue;

        this.state = {
            internalStartValue: props.startValue,
            internalEndValue: props.endValue,
            isMounted: false,
            isCustomVisible: props.customRangeOnly,
            isDropdownOpen: false,
            customRangeStartValue: moment(customRangeStartValue), // default value for individual datepickers
            customRangeEndValue: moment(customRangeEndValue), // default value for individual datepickers
            isStartValid: true,
            isEndValid: true,
            dropdownToggleText: <Placeholder text={props.textDefault} />,
            dropup: props.dropup,
            pullRight: props.pullRight,
        };

        this.toggleDropdownOpen = this.toggleDropdownOpen.bind(this);
        this.handleCustomToggle = this.handleCustomToggle.bind(this);
        this.handleCustomApply = this.handleCustomApply.bind(this);
        this.onStartChange = this.onStartChange.bind(this);
        this.getValidStartDates = this.getValidStartDates.bind(this);
        this.onEndChange = this.onEndChange.bind(this);
        this.getValidEndDates = this.getValidEndDates.bind(this);
        this.handleResetInternalState = this.handleResetInternalState.bind(this);
    }

    componentDidMount() {
        const { startValue, endValue, locale } = this.props;
        if (!this.state.isMounted) {
            this.setState({
                isMounted: true,
            });
        }

        // initial mount value
        if (startValue && endValue) {
            this.update(startValue, endValue, locale);
        }
    }

    /**
     * Responsible for handling start/end values given from outside (controlled component context).
     * @param {object} nextProps - The next props.
     * @returns {undefined}
     */
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const {
            startValue,
            endValue,
            textDefault,
            customRangeOnly,
            locale,
            defaultStartValue,
            defaultEndValue,
        } = nextProps;

        const { internalStartValue, internalEndValue } = this.state;

        // handle reset:
        // reset condition 1 is for uncontrolled components,
        // making sure that when a time is selected, and then the locale changed, that the selection remains
        const resetCondition1 =
            isNil(startValue) && isNil(endValue) && isNil(internalStartValue) && isNil(internalEndValue);

        // reset condition 2 is for controlled components,
        // when a time is set / defined, and the controlling component wants a hard reset
        const resetCondition2 = isNull(startValue) && isNull(endValue);

        // reset to original state
        if (resetCondition1 || resetCondition2) {
            this.setState({
                internalStartValue: undefined,
                internalEndValue: undefined,
                customRangeStartValue: defaultStartValue,
                customRangeEndValue: defaultEndValue,
                dropdownToggleText: <Placeholder text={textDefault} />,
                isCustomVisible: customRangeOnly,
                isDropdownOpen: false,
                isStartValid: true,
                isEndValid: true,
                pullRight: nextProps.pullRight,
                dropup: nextProps.dropup,
            });
        } else if (startValue !== this.props.startValue || endValue !== this.props.endValue) {
            this.update(startValue, endValue, locale);
        } else {
            this.update(internalStartValue, internalEndValue, locale);
        }
    }

    /**
     * Converts start/end values to moments, makes them readable,
     * crafts a dropdownToggleText and updates state.
     * @param {object} startValue - the start date (date or moment).
     * @param {object} endValue - the start date (date or moment).
     * @param {object} locale - the locale to use.
     * @param {object} extras - additional state, which needs to update.
     * @param {object} callback - callback to be called, after state has changed.
     * @returns {undefined}
     */
    update(startValue, endValue, locale, extras, callback) {
        const { hasTimePicker } = this.props;
        const format = hasTimePicker ? LOCAL_DATE_FORMAT : LOCAL_DATE_FORMAT_NO_TIME;

        // necessary to set start to startOf / end day to endOf, when selecting "without time"
        const finalStartValue = hasTimePicker ? moment(startValue) : moment(startValue).startOf('day');
        const finalEndValue = hasTimePicker ? moment(endValue) : moment(endValue).endOf('day');

        // composition of dropdownToggleText
        const startValueText = `${finalStartValue.locale(locale).format(format)}`;
        const endValueText = `${finalEndValue.locale(locale).format(format)}`;
        const dropdownToggleText =
            startValueText === endValueText ? startValueText : `${startValueText} → ${endValueText}`;

        // set state, and call callback
        this.setState(
            {
                internalStartValue: finalStartValue,
                internalEndValue: finalEndValue,
                customRangeStartValue: finalStartValue,
                customRangeEndValue: finalEndValue,
                dropdownToggleText,
                startValueText,
                endValueText,
                isStartValid: true,
                isEndValid: !finalEndValue.isBefore(finalStartValue),
                ...extras,
            },
            () => {
                callback && callback(finalStartValue, finalEndValue);
            }
        );
    }

    handleResetInternalState(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        const {
            textDefault,
            customRangeOnly,
            defaultStartValue,
            defaultEndValue,
            pullRight,
            dropup,
            onClear,
        } = this.props;

        this.setState({
            internalStartValue: undefined,
            internalEndValue: undefined,
            customRangeStartValue: defaultStartValue,
            customRangeEndValue: defaultEndValue,
            dropdownToggleText: <Placeholder text={textDefault} />,
            isCustomVisible: customRangeOnly,
            isDropdownOpen: false,
            isStartValid: true,
            isEndValid: true,
            startValueText: null,
            endValueText: null,
            pullRight: pullRight,
            dropup: dropup,
        });

        onClear();
    }

    /**
     * Gets called by wrapper component "onClickOutside".
     * Responsible for closing the dropdown when a click occured outside.
     * @returns {undefined}
     */
    handleClickOutside() {
        if (this.state.isMounted) {
            this.setState({
                isDropdownOpen: false,
            });
        }
    }

    /**
     * Toggles dropdown open status and visibility of custom range area.
     * @param {object} event - mouse event on toggle dropdown
     * @returns {undefined}
     */
    toggleDropdownOpen(event) {
        const toggleNode = ReactDOM.findDOMNode(this.refDropdownToggle);
        const menuNode = ReactDOM.findDOMNode(this.refDropdownMenu);

        const dropDirection =
            this.isAutoDropActive() && !this.state.isDropdownOpen ? getDropDirection(toggleNode, menuNode) : {};

        const left = event.currentTarget.getBoundingClientRect().left;

        this.setState(prevState => ({
            isDropdownOpen: !prevState.isDropdownOpen,
            isCustomVisible: this.props.customRangeOnly,
            ...dropDirection,
            pullRight: left + DROPDOWN_WIDTH > document.body.clientWidth,
        }));
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    /**
     * Only gets called on manual change of datePicker (user interaction).
     * Has influence the validity of the end date.
     *
     * Important to note that the start date is not limited,
     * whereas the end date is limited by the start date (see 'getValidEndDates').
     *
     * @param {object} startValue - the start date (date or moment).
     * @param {object} isValidDate - if the date is valid or not.
     * @returns {undefined}
     */
    onStartChange(startValue, isValidDate) {
        const newState = { isStartValid: false };
        const { hasTimePicker, minValue, maxValue } = this.props;
        const { customRangeEndValue } = this.state;

        if (moment.isMoment(startValue)) {
            // necessary to set start to startOf / end day to endOf, when selecting "without time"
            const finalStartValue = hasTimePicker ? startValue : moment(startValue).startOf('day');

            // check validity of input (min/max)
            const passesMin = minValue ? finalStartValue.isSameOrAfter(moment(minValue)) : true;
            const passesMax = maxValue ? finalStartValue.isSameOrBefore(moment(maxValue)) : true;
            const isValidInput = isValidDate && passesMin && passesMax;
            newState.isStartValid = isValidInput;
            if (isValidDate) {
                newState.customRangeStartValue = finalStartValue;
                newState.isEndValid = !customRangeEndValue.isBefore(finalStartValue);
            }
        }

        this.setState(newState);
    }

    /**
     * Only gets called on manual change of datePicker (user interaction)
     * @param {object} endValue - the start date (date or moment).
     * @param {object} isValidDate - if the date is valid or not.
     * @returns {undefined}
     */
    onEndChange(endValue, isValidDate) {
        const newState = { isEndValid: false };
        const { hasTimePicker, minValue, maxValue } = this.props;
        const { customRangeStartValue } = this.state;
        // let isValidInput = false;

        if (moment.isMoment(endValue)) {
            // necessary to set end of seconds and milliseconds for every end value
            const correctedEndValue = isValidDate ? endValue.endOf('minute') : endValue;

            // necessary to set start to startOf / end day to endOf, when selecting "without time"
            const finalEndValue = hasTimePicker ? correctedEndValue : moment(endValue).endOf('day');

            // check validity of input (min/max)
            const passesMin = minValue ? finalEndValue.isSameOrAfter(moment(minValue)) : true;
            const passesMax = maxValue ? finalEndValue.isSameOrBefore(moment(maxValue)) : true;
            const isValidInput = isValidDate && passesMin && passesMax && finalEndValue.isAfter(customRangeStartValue);
            newState.isEndValid = isValidInput;

            if (isValidDate) {
                newState.customRangeEndValue = finalEndValue;
            }
        }

        this.setState(newState);
    }

    /**
     * Callback method for DatePicker to limit selection in DatePicker.
     * @param {object} current - Current date in the loop.
     * @returns {undefined}
     */
    getValidStartDates(current) {
        const { minValue, maxValue } = this.props;
        const customRangeEndValue = moment(this.state.customRangeEndValue);

        if (current.isSame(customRangeEndValue, 'day')) {
            return true;
        }

        const passesMin = minValue ? current.isSameOrAfter(moment(minValue)) : true;
        const passesMax = maxValue ? current.isSameOrBefore(moment(maxValue)) : true;

        return passesMin && passesMax;
    }

    /**
     * Callback method for DatePicker to limit selection in DatePicker.
     * End date has to be after start date.
     * @param {object} current - Current date in the loop.
     * @returns {undefined}
     */
    getValidEndDates(current) {
        const { minValue, maxValue } = this.props;
        const customRangeStartValue = moment(this.state.customRangeStartValue);

        if (current.isSame(customRangeStartValue, 'day')) {
            return true;
        }

        const passesMin = minValue ? current.isSameOrAfter(moment(minValue)) : true;
        const passesMax = maxValue ? current.isSameOrBefore(moment(maxValue)) : true;
        const isAfterStart = current.isAfter(customRangeStartValue);

        return passesMin && passesMax && isAfterStart;
    }

    /**
     * Gets called when "custom ..." has been selected.
     * @returns {undefined}
     */
    handleCustomToggle() {
        this.setState(prevState => {
            return {
                isCustomVisible: !prevState.isCustomVisible,
            };
        });
    }

    handleCustomPresetSelect(preset) {
        if (typeof preset.onSelect === 'function') {
            preset.onSelect();
        }
        this.handleSelect(preset.startValue, preset.endValue);
    }

    /**
     * Gets called when a preset has been selected.
     * @param {object} startValue - the start date (date or moment).
     * @param {object} endValue - the start date (date or moment).
     * @returns {undefined}
     */
    handleSelect(startValue, endValue) {
        const { onRangeChange, locale } = this.props;

        const extras = { isDropdownOpen: false };
        this.update(startValue, endValue, locale, extras, onRangeChange);
    }

    /**
     * Gets called user clicks the apply button.
     * @returns {undefined}
     */
    handleCustomApply() {
        const { customRangeStartValue, customRangeEndValue, isStartValid, isEndValid } = this.state;
        const { onRangeChange, locale } = this.props;

        if (isStartValid && isEndValid) {
            const extras = { isDropdownOpen: false };
            this.update(customRangeStartValue, customRangeEndValue, locale, extras, onRangeChange);
        }
    }

    getDropdownToggle(dropdownToggleText) {
        const { children, clearable } = this.props;
        const { startValueText, endValueText } = this.state;

        const isClearable = clearable && dropdownToggleText.type !== Placeholder;

        const labelClassNames = classNames(isClearable && 'withClearButton');

        const isRenderCallback = children && typeof children === 'function';

        // Note: due to issues with nested events in Firefox (for toggle and inside the clear)
        // the toggle element must not be a <button> but a div.
        return (
            <div
                type={'button'}
                className={'DateRangePickerToggle form-control dropdown-toggle text-left'}
                aria-haspopup={'true'}
                aria-expanded={'true'}
                onClick={this.toggleDropdownOpen}
                ref={node => (this.refDropdownToggle = node)}
            >
                {isRenderCallback ? (
                    this.props.children(startValueText, endValueText)
                ) : (
                    <span className={labelClassNames}>{dropdownToggleText}</span>
                )}
                {isClearable && this.getClearButton()}
                <span className={'caret'} />
            </div>
        );
    }

    getClearButton() {
        return (
            <span className={'clearButton'} onClick={this.handleResetInternalState}>
                <span className={'clearButtonIcon rioglyph rioglyph-remove-sign'}></span>
            </span>
        );
    }

    getCustomPanelToggle(isCustomVisible, textCustom) {
        return (
            <li className={classNames('custom-panel-toggle', isCustomVisible && 'hidden')}>
                <a role={'button'} onClick={this.handleCustomToggle}>
                    {textCustom} ...
                </a>
            </li>
        );
    }

    getCustomPanelButtons(isCustomVisible, isStartValid, isEndValid, textApply, textCancel) {
        return (
            <li className={classNames('custom-panel-buttons', 'margin-20', !isCustomVisible && 'hidden')}>
                <div className={'btn-toolbar'}>
                    <button
                        type={'button'}
                        disabled={!isStartValid || !isEndValid}
                        onClick={this.handleCustomApply}
                        className={'btn btn-primary pull-right'}
                    >
                        {textApply}
                    </button>
                    <button type={'button'} onClick={this.toggleDropdownOpen} className={'btn btn-default pull-right'}>
                        {textCancel}
                    </button>
                </div>
            </li>
        );
    }

    // eslint-disable-next-line complexity
    render() {
        const {
            locale,
            textCustom,
            textApply,
            textCancel,
            textFrom,
            textTo,
            textToday,
            textLastWeek,
            textLastMonth,
            className,
            clearable,
            dropdownClass,
            dropdownMenuClass,
            customPresets,
            hasTimePicker,
        } = this.props;

        const {
            customRangeStartValue,
            customRangeEndValue,
            isStartValid,
            isEndValid,
            isCustomVisible,
            isDropdownOpen,
            dropdownToggleText,
        } = this.state;

        const pullRight = this.isAutoDropActive() ? this.state.pullRight : this.props.pullRight;
        const dropup = this.isAutoDropActive() ? this.state.dropup : this.props.dropup;

        if (customPresets && isEqual(customPresets, defaultProps.customPresets)) {
            // support backward compatibility to override default drop down text values
            customPresets[0].text = textToday;
            customPresets[1].text = textLastWeek;
            customPresets[2].text = textLastMonth;
        }

        const classes = classNames('DateRangePicker', className && className);

        const dropDownClassNames = classNames(
            'select',
            'dropdown',
            dropdownClass,
            isDropdownOpen && 'open',
            dropup && 'dropup'
        );

        return (
            <div className={classes}>
                <div className={dropDownClassNames}>
                    {this.getDropdownToggle(dropdownToggleText, clearable)}
                    <ul
                        className={classNames('dropdown-menu', pullRight && 'pull-right', dropdownMenuClass)}
                        ref={node => (this.refDropdownMenu = node)}
                    >
                        {customPresets.map((preset, i) => {
                            return (
                                <li
                                    key={`preset-${i}`}
                                    title={(preset.disabled && preset.disabledText) || null}
                                    className={classNames(preset.disabled && 'disabled')}
                                >
                                    <a
                                        role={'button'}
                                        className={classNames(isCustomVisible && 'hidden')}
                                        onClick={
                                            !preset.disabled
                                                ? this.handleCustomPresetSelect.bind(this, preset)
                                                : undefined
                                        }
                                    >
                                        {preset.text}
                                    </a>
                                </li>
                            );
                        })}
                        <li role={'separator'} className={classNames('divider', isCustomVisible && 'hidden')} />
                        {this.getCustomPanelToggle(isCustomVisible, textCustom)}
                        <li className={classNames('custom-panel', !isCustomVisible && 'hidden')}>
                            <div className={'row'}>
                                <div className={'col-sm-6'}>
                                    <label>{textFrom}</label>
                                    <DatePicker
                                        locale={locale}
                                        open={true}
                                        autoDropDirection={false}
                                        dateFormat={true}
                                        timeFormat={hasTimePicker}
                                        initialValue={customRangeStartValue}
                                        onChange={this.onStartChange}
                                        closeOnSelect={true}
                                        isValidDate={this.getValidStartDates}
                                        className={classNames('DatePicker', !isStartValid && 'has-error')}
                                    />
                                </div>
                                <div className={'col-sm-6'}>
                                    <label>{textTo}</label>
                                    <DatePicker
                                        locale={locale}
                                        open={true}
                                        autoDropDirection={false}
                                        dateFormat={true}
                                        timeFormat={hasTimePicker}
                                        initialValue={customRangeEndValue}
                                        onChange={this.onEndChange}
                                        closeOnSelect={true}
                                        isValidDate={this.getValidEndDates}
                                        className={classNames('DatePicker', !isEndValid && 'has-error')}
                                    />
                                </div>
                            </div>
                        </li>
                        {this.getCustomPanelButtons(isCustomVisible, isStartValid, isEndValid, textApply, textCancel)}
                    </ul>
                </div>
            </div>
        );
    }
}

DateRangePicker.propTypes = propTypes;
DateRangePicker.defaultProps = defaultProps;

export default onClickOutside(DateRangePicker);
export { DateRangePicker as DateRangePickerPure };
