import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutBodyNavigation = props => {
    const { className, children, ...remainingProps } = props;

    const navigationClasses = classNames(
        'ApplicationLayoutBodyNavigation',
        className
    );

    return (
        <div {...remainingProps} className={navigationClasses}>
            {children}
        </div>
    );
};

ApplicationLayoutBodyNavigation.propTypes = {
    className: PropTypes.string,
};

export default ApplicationLayoutBodyNavigation;
