import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
//import ReactDOM from 'react-dom';
import debounce from 'lodash/fp/debounce';
import classname from 'classnames';
import { AppMenu } from './AppMenu';
import { ApplicationActionBar } from './ApplicationActionBar';
import NavItems from './NavItems';
import modulePropTypes from './modulePropTypes';

const RESIZE_THROTTELING = 10;
const MOBILE_MAX_WIDTH = 580;
//const COLLAPSED_DROPDOWN_WIDTH = 50;

export class ApplicationHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            containerWidth: 0,
            brandWidth: 0,
            renderNavigation: false,
            isMobile: false,
        };

        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    isMobile() {
        //const node = this.navRef;

        // FIXME: collision check need to be done after second rendering of NavItems
        /*let isColliding = false;

        // check for collision of NavItems and ActionBar
        if (this.refNavItems && this.refActionBar) {
            const navItemsNode = ReactDOM.findDOMNode(this.refNavItems);
            const actionBarNode = ReactDOM.findDOMNode(this.refActionBar);

            // first empty <li> and second would be the collapsed dropdown
            const navItemsCount = navItemsNode.childElementCount;
            const collapsedDropdown = navItemsNode.children.item(1);
            if (navItemsCount === 2 && collapsedDropdown) {
                //isColliding = navItemsNode.scrollWidth - actionBarNode.scrollWidth < COLLAPSED_DROPDOWN_WIDTH;
                //console.log("isColliding=" + isColliding + " | clientWidth=" + node.clientWidth);

                const navItemsRight = navItemsNode.offsetLeft + collapsedDropdown.scrollWidth;
                const actionBarItemsLeft = actionBarNode.offsetLeft;

                isColliding = navItemsRight > actionBarItemsLeft;
                console.log({ isColliding, navItemsRight, actionBarItemsLeft });
            }
        }*/

        return /*isColliding || */ this.getContainerWidth() < MOBILE_MAX_WIDTH && this.props.label;
    }

    getContainerWidth() {
        //return this.navRef.clientWidth;
        return window.innerWidth;
    }

    componentDidMount() {
        this.resizer = debounce(RESIZE_THROTTELING)(this.handleWindowResize);
        window.addEventListener('resize', this.resizer);

        this.setState(() => ({
            containerWidth: this.getContainerWidth(),
            renderNavigation: true,
            isMobile: this.isMobile(),
        }));

        this.props.onMount();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizer);
        this.props.onUnmount();
    }

    handleWindowResize() {
        this.setState(() => ({
            renderNavigation: true,
            containerWidth: this.getContainerWidth(),
            isMobile: this.isMobile(),
        }));
    }

    render() {
        const {
            homeRoute,
            showHomeIcon,
            label,
            className,
            appNavigator,
            appNavigatorClassName,
            appMenuItems,
            navItems,
            actionBarItems,
        } = this.props;

        const classNames = classname(
            'ApplicationHeader',
            'navbar navbar-default',
            'user-select-none',
            this.state.isMobile && 'mobile',
            className && className
        );

        const renderNavItems = (
            <NavItems
                key='NavItems'
                navItems={navItems}
                isMobile={this.state.isMobile}
                containerWidth={this.state.containerWidth}
                actionBarItems={this.state.isMobile && actionBarItems}
                ref={node => (this.refNavItems = node)}
            />
        );

        const renderActionItems = !this.state.isMobile && (
            <ApplicationActionBar nodeRef={node => (this.refActionBar = node)} items={actionBarItems} />
        );

        return (
            <nav className={classNames} ref={node => (this.navRef = node)}>
                <div className='navbar-header'>
                    <span className={`navbar-brand ${showHomeIcon ? 'home-icon' : ''}`}>{homeRoute}</span>
                </div>
                {label && (
                    <AppMenu
                        label={label}
                        key='AppMenu'
                        appMenuItems={appMenuItems}
                        appNavigator={appNavigator}
                        appNavigatorClassName={appNavigatorClassName}
                    />
                )}
                {navItems && renderNavItems}
                {renderActionItems}
            </nav>
        );
    }
}

export default ApplicationHeader;

ApplicationHeader.defaultProps = {
    homeRoute: '',
    showHomeIcon: true,
    actionBarItems: [],
    onMount: () => {},
    onUnmount: () => {},
};

ApplicationHeader.propTypes = {
    label: PropTypes.node,
    className: PropTypes.string,
    homeRoute: PropTypes.node.isRequired,
    showHomeIcon: PropTypes.bool,
    appMenuItems: PropTypes.arrayOf(modulePropTypes),
    appNavigator: PropTypes.node,
    appNavigatorClassName: PropTypes.string,
    navItems: PropTypes.arrayOf(modulePropTypes),
    actionBarItems: PropTypes.arrayOf(PropTypes.node),
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
};
