export const createButtonRipple = (event, target) => {
    const button = target || event.currentTarget;
    const buttonRect = button.getBoundingClientRect();

    const circle = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - buttonRect.left - radius}px`;
    circle.style.top = `${event.clientY - buttonRect.top - radius}px`;
    circle.classList.add('btn-ripple');

    const rippleElement = button.getElementsByClassName('btn-ripple')[0];

    // Cleanup previous ripple element from current button in case there
    // are multiple clicks on the button itself
    if (rippleElement) {
        rippleElement.remove();
    }

    button.appendChild(circle);

    // Cleanup ripple element after some time
    setTimeout(() => circle.remove(), 1000);
};

const isTargetEligibleForEffect = target => {
    if (!target || !target.className) {
        return false;
    }

    const { className } = target;

    if (typeof className === 'string' || typeof className === 'array') {
        const isButton = className.includes('btn ');
        const isNotButtonComponent = !className.includes('btn-component');
        const isNotLinkButton = !className.includes('btn-link');
        const isNotActionButton = !className.includes('btn-action');
        const isNotDisabled = !className.includes('disabled');

        return isButton && isNotButtonComponent && isNotDisabled && isNotLinkButton && isNotActionButton;
    }
    return false;
};

const enableButtonRipple = event => {
    const target = event.target;
    if (target) {
        const isButton = isTargetEligibleForEffect(target);
        const isChildOfButton = !isButton && isTargetEligibleForEffect(target.parentElement);

        if (isButton || isChildOfButton) {
            createButtonRipple(event, isChildOfButton ? target.parentElement : target);
        }
    }
};

export const registerButtonEffect = (function (document) {
    if (!document) {
        return;
    }
    document.addEventListener('mousedown', enableButtonRipple, false);
    document.addEventListener('touchstart', enableButtonRipple, false);
})(document);
