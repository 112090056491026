import { useEffect } from 'react';
import throttle from 'lodash/fp/throttle';

export default function useWindowResize(onResizeCallback, timeout = 300) {
    useEffect(() => {
        const throttledCallback = throttle(timeout, onResizeCallback);

        window.addEventListener('resize', throttledCallback, false);
        return () => window.removeEventListener('resize', throttledCallback, true);
    }, [onResizeCallback, timeout]);
}
