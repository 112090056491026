import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dialog from './Dialog';
import ResponsiveVideo from '../video/ResponsiveVideo';
import ImagePreloader from '../preloader/ImagePreloader';
import Spinner from '../spinner/Spinner';
import baseDialogPropTypes from './baseDialogPropTypes';

// TODO: add keyboard support left/right

class MediaDialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentMediaIndex: 0,
        };

        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    handlePrevious() {
        const oldIndex = this.state.currentMediaIndex;
        const newIndex = (oldIndex === 0) ? this.props.media.length - 1 : oldIndex - 1;

        this.setState(() => ({
            currentMediaIndex: newIndex,
        }));

        this.props.previousButtonCallback(newIndex);
    }

    handleNext() {
        const oldIndex = this.state.currentMediaIndex;
        const newIndex = (oldIndex === this.props.media.length - 1) ? 0 : oldIndex + 1;

        this.setState(() => ({
            currentMediaIndex: newIndex,
        }));

        this.props.nextButtonCallback(newIndex);
    }

    renderFallback() {
        return (
            <div className={'text-center text-size-h3'}>
                <span className='rioglyph rioglyph-missing text-size-200pct text-color-gray' />
            </div>
        );
    }

    renderImage(mediaData) {
        const imageClassNames = classNames(
            'img-responsive',
            mediaData.className && mediaData.className
        );

        return (
            <ImagePreloader src={mediaData.src} className={imageClassNames}>
                {({ status, image }) => {
                    if (status === ImagePreloader.STATUS_LOADED) {
                        // When image has been loaded, render the image tag
                        return (
                            <img
                                className={'ani-fade-in'}
                                key={image.src}
                                src={image.src}
                            />
                        );
                    }

                    if (status === ImagePreloader.STATUS_FAILED) {
                        return this.renderFallback();
                    }

                    return <Spinner isInverse={false} />;
                }}
            </ImagePreloader>
        );
    }

    renderVideo(mediaData) {
        const videoClassNames = classNames(mediaData.className && mediaData.className);
        return (
            <div className='flex-1-0'>
                <ResponsiveVideo {...mediaData} className={videoClassNames} />
            </div>
        );
    }

    renderMediaContent(mediaData = {}) {
        if (mediaData.type === MediaDialog.MEDIA_TYPE_VIDEO) {
            return this.renderVideo(mediaData);
        }

        // image media is default
        return mediaData.src && this.renderImage(mediaData);
    }

    renderBody(media = [], currentMediaIndex) {
        const mediaData = media[currentMediaIndex];

        const mediaContentClassNames = classNames(
            'media-content',
            'content-center'
        );

        return (
            <div className={mediaContentClassNames}>
                {this.renderMediaContent(mediaData)}
            </div>
        );
    }

    renderFooter(props) {
        return (
            <div className='position-relative clearfix'>
                <button type='button' className='btn btn-primary btn-link pull-left'
                    onClick={this.handlePrevious}>
                    <span className='rioglyph rioglyph-chevron-left'></span>
                    {props.previousButtonText}
                </button>
                <div className='position-absolute top-50pct left-50pct transform translate-centered'>
                    {`${this.state.currentMediaIndex + 1} / ${props.media.length}`}
                </div>
                <button type='button' className='btn btn-primary btn-link btn-icon-right pull-right'
                    onClick={this.handleNext}>
                    <span className='rioglyph rioglyph-chevron-right'></span>
                    {props.nextButtonText}
                </button>
            </div>
        );
    }

    render() {
        const {
            show,
            onClose,
            className,
            media = [],
            ...remainingProps
        } = this.props;

        const { currentMediaIndex } = this.state;

        if (!show) {
            return null;
        }

        const dialogClassName = classNames(
            'media-dialog',
            className && className
        );

        const bodyClassNames = classNames(
            'padding-0'
        );

        const mediaTitle = media[currentMediaIndex].title;
        const mediaSubtitle = media[currentMediaIndex].subtitle || null;

        return (
            <Dialog
                {...remainingProps}
                show={show}
                title={mediaTitle}
                subtitle={mediaSubtitle}
                body={this.renderBody(media, currentMediaIndex)}
                footer={(media && media.length > 1) ? this.renderFooter(this.props) : ''}
                onHide={onClose}
                className={dialogClassName}
                bodyClassName={bodyClassNames}
            />
        );
    }
}

MediaDialog.MEDIA_TYPE_IMAGE = 'image';
MediaDialog.MEDIA_TYPE_VIDEO = 'video';

MediaDialog.defaultProps = {
    show: false,
    onClose: () => {},
    disableEsc: false,
    useOverflow: false,
    bsSize: Dialog.SIZE_LG,
    media: [],
    previousButtonText: 'Prev',
    previousButtonCallback: () => {},
    nextButtonText: 'Next',
    nextButtonCallback: () => {},
};

MediaDialog.propTypes = {
    ...baseDialogPropTypes,
    media: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf([MediaDialog.MEDIA_TYPE_IMAGE, MediaDialog.MEDIA_TYPE_VIDEO]).isRequired,
            src: PropTypes.string.isRequired,
            title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            aspectRatio: PropTypes.string,
            className: PropTypes.string,
        })
    ).isRequired,
    previousButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    previousButtonCallback: PropTypes.func,
    nextButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    nextButtonCallback: PropTypes.func,
};

export default MediaDialog;
