import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/fp/debounce';
import classNames from 'classnames';
import { ApplicationLayoutBodyBottomBar } from './ApplicationLayoutBodyBottomBar';

const RESIZE_THROTTELING = 200;

export const ApplicationLayoutBody = props => {
    const { className, innerClassName, forceScrollbar, enableScrollToTop, banner, navigation, bottomBar, children } =
        props;

    const [offset, setOffset] = useState(0);
    const moduleContentRef = useRef();

    const handleScroll = debounce(RESIZE_THROTTELING)(() => {
        if (moduleContentRef.current) {
            setOffset(moduleContentRef.current.scrollTop);
        }
    });

    const handleToTop = () => {
        if (!moduleContentRef.current) {
            return;
        }
        const currentScroll = moduleContentRef.current.scrollTop;
        if (currentScroll > 0) {
            window.requestAnimationFrame(handleToTop);
            moduleContentRef.current.scrollTo(0, currentScroll - currentScroll / 5);
            setOffset(currentScroll);
        }
    };

    const classes = classNames('ApplicationLayoutBody', bottomBar && 'has-footer', className);

    const innerClasses = classNames(
        'module-content',
        forceScrollbar && 'overflow-y-scroll',
        innerClassName && innerClassName
    );

    const offsetThreshold = window.innerHeight * 0.1;

    const scrollToTopClasses = classNames('scroll-to-top', offset > offsetThreshold && 'in');

    return (
        <React.Fragment>
            <div className={classes}>
                <div className='module-content-wrapper'>
                    {banner && banner}
                    {navigation && navigation}

                    <div className={innerClasses} onScroll={handleScroll} ref={moduleContentRef}>
                        {children}
                    </div>
                </div>

                {enableScrollToTop && (
                    <span className={scrollToTopClasses}>
                        <button className='btn btn-primary btn-icon-only' onClick={handleToTop}>
                            <span className='rioglyph rioglyph-arrow-up' />
                        </button>
                    </span>
                )}
            </div>

            {bottomBar && bottomBar}
        </React.Fragment>
    );
};

ApplicationLayoutBody.defaultProps = {
    className: '',
    enableScrollToTop: true,
    forceScrollbar: false,
};

ApplicationLayoutBody.propTypes = {
    className: PropTypes.string,
    enableScrollToTop: PropTypes.bool,
    forceScrollbar: PropTypes.bool,
    innerClassName: PropTypes.string,
    banner: PropTypes.node,
    navigation: PropTypes.node,
    bottomBar: PropTypes.node,
};

export default ApplicationLayoutBody;
