import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import isNil from 'lodash/fp/isNil';
import mapValues from 'lodash/fp/mapValues';
import some from 'lodash/fp/some';
import arrayMove from '../../utils/arrayMove';

import Dialog from '../dialog/Dialog';
import ClearableInput from '../clearableInput/ClearableInput';
import { TableSettingsDialogFooter } from './TableSettingsDialogFooter';
import { TableSettingsListContainerSortable } from './TableSettingsListContainer';
import { filterColumns } from './TableSettingsListItem';

const DEFAULT_COLUMN_WIDTH = 0;
const MAX_COLUMN_WIDTH = 1000;

class TableSettingsDialog extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            columnSearchValue: props.columnSearchValue,
            columnOrder: props.columnOrder || props.defaultColumnOrder,
            hiddenColumns: props.hiddenColumns || props.defaultHiddenColumns,
            hasChanged: false,
            movedColumn: false,
            columnsDetails: props.columnsDetails,
            columnLabelStrings: [],
            updateColumnLabelStrings: true,
            openColumnsDetails: {},
        };

        this.moveColumnToIndex = this.moveColumnToIndex.bind(this);
        this.handleResetColumnChanges = this.handleResetColumnChanges.bind(this);
        this.handleCancelResetColumnChanges = this.handleCancelResetColumnChanges.bind(this);
        this.resetAllColumnChanges = this.resetAllColumnChanges.bind(this);
        this.discardColumnChanges = this.discardColumnChanges.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.toggleHideColumn = this.toggleHideColumn.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.deleteMovedColumn = this.deleteMovedColumn.bind(this);
        this.handleColumnWidthChange = this.handleColumnWidthChange.bind(this);
        this.handleResetColumnWidth = this.handleResetColumnWidth.bind(this);
        this.handleOpenColumnsDetails = this.handleOpenColumnsDetails.bind(this);
        this.handleSortEnd = this.handleSortEnd.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { columnSearchValue, columnOrder, hiddenColumns, columnsDetails } = this.state;

        const hasColumnSearchValueChanged = !isEqual(columnSearchValue, nextProps.columnSearchValue);
        const hasColumnOrderChanged = !isEqual(columnOrder, nextProps.columnOrder);
        const hasHiddenColumnsChanged = !isEqual(hiddenColumns, nextProps.hiddenColumns);
        const hasColumnsDetailsChanged = this.hasColumnsDetailsChanged(columnsDetails);

        if (
            hasColumnSearchValueChanged ||
            hasColumnOrderChanged ||
            hasHiddenColumnsChanged ||
            hasColumnsDetailsChanged
        ) {
            this.setState({
                columnSearchValue: hasColumnSearchValueChanged ? nextProps.columnSearchValue : columnSearchValue,
                columnOrder: hasColumnOrderChanged ? nextProps.columnOrder : columnOrder,
                hiddenColumns: hasHiddenColumnsChanged ? nextProps.hiddenColumns : hiddenColumns,
                columnsDetails: hasColumnsDetailsChanged ? nextProps.columnsDetails : columnsDetails,
                updateColumnLabelStrings: true,
            });
        }
    }

    componentDidMount() {
        const { defaultColumnOrder, defaultHiddenColumns, columnsDetails, columnOrder, hiddenColumns } = this.props;

        this.setColumnLabelStrings();

        const hasColumnOrderChanged = !isEqual(columnOrder, defaultColumnOrder);
        const hasHiddenColumnsChanged = !isEqual(hiddenColumns, defaultHiddenColumns);
        const hasColumnsDetailsChanged = this.hasColumnsDetailsChanged(columnsDetails);

        if (hasColumnOrderChanged || hasHiddenColumnsChanged || hasColumnsDetailsChanged) {
            this.setState({ hasChanged: true });
        }
    }

    componentDidUpdate() {
        if (this.props.show && this.state.updateColumnLabelStrings) {
            this.setColumnLabelStrings();
        }
    }

    hasColumnsDetailsChanged(columnsDetails) {
        if (isEmpty(columnsDetails)) {
            return false;
        }

        const hasChanged = some(details => {
            const defaultWidth = isFinite(details.defaultWidth) ? details.defaultWidth : DEFAULT_COLUMN_WIDTH;
            return details.width !== defaultWidth;
        })(columnsDetails);

        return hasChanged;
    }

    setColumnLabelStrings() {
        if (!this.contentRef) {
            return;
        }

        // For searching by name we need to get the label from the DOM as it may contain a FormattedMessage
        const labels = this.contentRef.getElementsByClassName('table-settings-item-label');

        const columnStrings = {};
        [...labels].map(label => {
            const key = label.getAttribute('data-key');
            columnStrings[key] = label.textContent.replace(/\r?\n|\r/g, '').toLowerCase();
        });

        this.setState({
            columnLabelStrings: columnStrings,
            updateColumnLabelStrings: false,
        });
    }

    deleteMovedColumn() {
        this.setState({
            movedColumn: false,
        });
    }

    moveColumnToIndex(columnName, newIndex, changeMovedColumn) {
        const { immediateChange, onColumnChange } = this.props;
        const { columnOrder, hiddenColumns } = this.state;

        const newColumnOrder = columnOrder.filter(name => name !== columnName);
        newColumnOrder.splice(newIndex, 0, columnName);

        this.setState({
            columnOrder: newColumnOrder,
            movedColumn: changeMovedColumn ? columnName : false,
            hasChanged: true,
        });

        if (immediateChange) {
            onColumnChange(newColumnOrder, hiddenColumns);
        }

        window.setTimeout(this.deleteMovedColumn, 500);
    }

    handleResetColumnChanges() {
        this.setState({ isResetAll: true });
    }

    handleCancelResetColumnChanges() {
        this.setState({ isResetAll: false });
    }

    resetColumnsDetails(columnsDetails) {
        return mapValues(columnDetails => {
            return {
                ...columnDetails,
                width: columnDetails.defaultWidth || DEFAULT_COLUMN_WIDTH,
            };
        })(columnsDetails);
    }

    resetAllColumnChanges() {
        const {
            defaultColumnOrder,
            defaultHiddenColumns,
            immediateChange,
            onSearchChange,
            onColumnChange,
        } = this.props;
        const { columnsDetails } = this.state;

        const defaultColumnsDetails = this.resetColumnsDetails(columnsDetails);

        const newState = {
            columnOrder: defaultColumnOrder,
            hiddenColumns: defaultHiddenColumns,
            columnSearchValue: '',
            hasChanged: false,
            isResetAll: false,
        };

        if (!isEmpty(columnsDetails)) {
            newState.columnsDetails = defaultColumnsDetails;
        }

        this.setState(() => newState);

        if (immediateChange) {
            onSearchChange('');
            onColumnChange(defaultColumnOrder, defaultHiddenColumns, defaultColumnsDetails);
        }
    }

    discardColumnChanges() {
        this.props.onSearchChange('');
        this.props.onCancel();
        this.props.onHide();
    }

    applyChanges() {
        const { columnOrder, hiddenColumns, columnsDetails } = this.state;

        this.setState({
            columnSearchValue: '',
        });

        this.props.onSearchChange('');
        this.props.onColumnChange(columnOrder, hiddenColumns, columnsDetails);
        this.props.onApply(columnOrder, hiddenColumns, columnsDetails);
        this.props.onHide();
    }

    toggleHideColumn(column) {
        const { hiddenColumns } = this.state;
        const { immediateChange, onColumnChange } = this.props;

        const isHidden = hiddenColumns.includes(column);
        const newHiddenColumns = isHidden ? hiddenColumns.filter(name => name !== column) : [...hiddenColumns, column];

        this.setState(() => ({
            hiddenColumns: newHiddenColumns,
            hasChanged: true,
        }));

        if (immediateChange) {
            onColumnChange(this.state.columnOrder, newHiddenColumns);
        }
    }

    handleSearchChange(searchValue) {
        const newSearch = searchValue.toLowerCase();

        this.setState(
            () => ({
                columnSearchValue: newSearch,
            }),
            this.props.onSearchChange(newSearch)
        );
    }

    handleColumnWidthChange(column, value) {
        const { immediateChange, onColumnDetailsChange } = this.props;

        const columnsDetails = { ...this.state.columnsDetails };

        if (columnsDetails[column]) {
            columnsDetails[column].width = value;
        } else {
            columnsDetails[column] = {
                width: value,
                defaultWidth: 0,
                maxWidth: MAX_COLUMN_WIDTH,
            };
        }

        this.setState(() => ({
            columnsDetails,
            hasChanged: true,
        }));

        if (immediateChange) {
            onColumnDetailsChange(column, columnsDetails[column]);
        }
    }

    handleResetColumnWidth(column) {
        const { immediateChange, onColumnDetailsChange } = this.props;

        const columnsDetails = { ...this.state.columnsDetails };
        const updatedColumnDetails = columnsDetails[column];
        updatedColumnDetails.width = updatedColumnDetails.defaultWidth;

        this.setState(() => ({ columnsDetails }));

        if (immediateChange) {
            onColumnDetailsChange(column, columnsDetails[column]);
        }
    }

    handleOpenColumnsDetails(columnName) {
        const updatedOpenColumnDetails = { ...this.state.openColumnsDetails };

        if (updatedOpenColumnDetails[columnName]) {
            delete updatedOpenColumnDetails[columnName];
        } else {
            updatedOpenColumnDetails[columnName] = columnName;
        }

        this.setState(() => ({ openColumnsDetails: updatedOpenColumnDetails }));
    }

    handleSortEnd({ oldIndex, newIndex }) {
        const { immediateChange, onColumnChange } = this.props;
        const { columnOrder, hiddenColumns } = this.state;

        const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);

        this.setState(() => ({
            columnOrder: newColumnOrder,
            movedColumn: true,
            hasChanged: true,
        }));

        if (immediateChange) {
            onColumnChange(newColumnOrder, hiddenColumns);
        }
    }

    renderNotFoundMessage(message) {
        return <div className={'text-center text-color-gray'}>{message}</div>;
    }

    renderTableSettingsDialogContent() {
        const { searchPlaceholder, notFoundMessage, columnLabels, autoLabel, disabledColumns } = this.props;

        const {
            columnOrder,
            hiddenColumns = [],
            movedColumn,
            columnSearchValue,
            columnsDetails,
            openColumnsDetails,
            updateColumnLabelStrings,
            columnLabelStrings,
        } = this.state;

        const itemProps = {
            columnLabels,
            autoLabel,
            disabledColumns,
            columnOrder,
            hiddenColumns,
            movedColumn,
            columnSearchValue,
            columnsDetails,
            columnLabelStrings,
            openColumnsDetails,
            updateColumnLabelStrings,
            notFoundMessage,
            onMoveColumn: this.moveColumnToIndex,
            onOpenDetails: this.handleOpenColumnsDetails,
            onColumnWidthChange: this.handleColumnWidthChange,
            onResetColumnWidth: this.handleResetColumnWidth,
            onToggleHideColumn: this.toggleHideColumn,
        };

        const filteredColumns = columnOrder.filter(column =>
            filterColumns(columnSearchValue, column, columnLabelStrings)
        );
        const hasItems = !isEqual(filteredColumns, columnOrder);

        return (
            <div ref={node => (this.contentRef = node)}>
                <div className={'table-settings-search'}>
                    <div className={'input-group width-100pct'}>
                        <span className={'input-group-addon'}>
                            <span className={'rioglyph rioglyph-search'} />
                        </span>
                        <ClearableInput
                            value={columnSearchValue}
                            onChange={this.handleSearchChange}
                            placeholder={searchPlaceholder}
                        />
                    </div>
                </div>
                <div className={'table-settings-body'}>
                    {hasItems ? (
                        <TableSettingsListContainerSortable
                            items={columnOrder}
                            onSortEnd={this.handleSortEnd}
                            lockAxis={'y'}
                            useDragHandle={true}
                            helperClass={'table-settings-item shadow-smooth-to-bottom z-index-max'}
                            isSortable={!isNil(columnSearchValue) && !isEmpty(columnSearchValue)}
                            {...itemProps}
                        />
                    ) : (
                        this.renderNotFoundMessage(notFoundMessage)
                    )}
                </div>
            </div>
        );
    }

    renderTableSettingsDialogFooter() {
        const {
            immediateChange,
            resetButtonText,
            onHide,
            closeButtonText,
            cancelButtonText,
            applyButtonText,
        } = this.props;
        const { hasChanged, isResetAll } = this.state;

        return (
            <TableSettingsDialogFooter
                hasChanged={hasChanged}
                isResetAll={isResetAll}
                immediateChange={immediateChange}
                resetButtonText={resetButtonText}
                onHide={onHide}
                onApplyChanges={this.applyChanges}
                onDiscardChanges={this.discardColumnChanges}
                onResetColumnChanges={this.handleResetColumnChanges}
                onConfirmResetColumnChanges={this.resetAllColumnChanges}
                onCancelResetColumnChanges={this.handleCancelResetColumnChanges}
                closeButtonText={closeButtonText}
                cancelButtonText={cancelButtonText}
                applyButtonText={applyButtonText}
            />
        );
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        const dialogClassNames = classNames('TableSettingsDialog', this.props.className && this.props.className);

        return (
            <Dialog
                show={this.props.show}
                title={this.props.title}
                onHide={this.props.onHide}
                body={this.renderTableSettingsDialogContent()}
                footer={this.renderTableSettingsDialogFooter()}
                className={dialogClassNames}
            />
        );
    }
}

TableSettingsDialog.defaultProps = {
    show: false,
    immediateChange: false,
    columnSearchValue: '',
    onColumnChange: () => {},
    onColumnDetailsChange: () => {},
    onSearchChange: () => {},
    onCancel: () => {},
    onApply: () => {},
    columnLabels: {},
    defaultColumnOrder: [],
    defaultHiddenColumns: [],
    columnOrder: [],
    hiddenColumns: [],
    disabledColumns: [],
    columnsDetails: {},
    notFoundMessage: '',
    autoLabel: '',
};

const columnsDetailsPropTypes = PropTypes.objectOf(
    PropTypes.shape({
        width: PropTypes.number,
        defaultWidth: PropTypes.number,
        maxWidth: PropTypes.number,
    })
);

TableSettingsDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    className: PropTypes.string,
    defaultColumnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
    columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
    hiddenColumns: PropTypes.arrayOf(PropTypes.string),
    // listed with { columnName: string/node }
    columnLabels: PropTypes.object.isRequired,
    // hide a sorted column will result in an error, so disable at least one important fallback column
    // or the sorted column (fallback column recommended)
    disabledColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnsDetails: columnsDetailsPropTypes,
    defaultColumnDetails: columnsDetailsPropTypes,
    autoLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // TODO Document this prop
    applyButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    closeButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    resetButtonText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onColumnChange: PropTypes.func.isRequired,
    onColumnDetailsChange: PropTypes.func,
    onDiscard: PropTypes.func,
    onApply: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    columnSearchValue: PropTypes.string,
    onSearchChange: PropTypes.func,
    searchPlaceholder: PropTypes.node.isRequired,
    notFoundMessage: PropTypes.string,
    // set if you want to change the table after each change
    immediateChange: PropTypes.bool,
};

export default TableSettingsDialog;
