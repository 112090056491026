import React from 'react';
import PropTypes from 'prop-types';

export const LoadMoreProgress = ({ loaded, total }) => {
    if (!loaded || !total) {
        return null;
    }

    const width = `${(loaded / total) * 100}%`;

    return (
        <React.Fragment>
            <div className={'text-center margin-bottom-3'}>
                <span className={'text-medium'}>{loaded}</span>
                <span className={'margin-left-5 margin-right-5'}>{'/'}</span>
                <span className={'text-medium'}>{total}</span>
            </div>
            <div className={'progress bg-white height-2 margin-bottom-10'}>
                <div className={'progress-bar'} style={{ width }}></div>
            </div>
        </React.Fragment>
    );
};

LoadMoreProgress.defaultProps = {
    loaded: 0,
    total: 0,
};

LoadMoreProgress.propTypes = {
    loaded: PropTypes.number,
    total: PropTypes.number,
};

export default LoadMoreProgress;
