import React from 'react';
import {
    LineChart as RechartsLineChart,
    Line as RechartsLine,
    Legend as RechartsLegend,
    XAxis,
    YAxis,
    Brush,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

import { getColor, mapGridOptions } from './chartHelper';

const LineChart = props => {
    const {
        width,
        height,
        data,
        dataUnit,
        dataKey,
        lines,
        xAxisOptions,
        yAxisOptions,
        gridOptions,
        showXAxis,
        showYAxis,
        showGrid,
        legend,
        tooltip,
        brush,
        ...remainingProps
    } = props;

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <RechartsLineChart
                width={width}
                height={height}
                data={data}
                margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
                {...remainingProps}
            >
                {showGrid && <CartesianGrid strokeDasharray='3 3' {...mapGridOptions(gridOptions)} />}

                <XAxis hide={!showXAxis} dataKey={dataKey} {...xAxisOptions} />
                <YAxis hide={!showYAxis} unit={dataUnit} {...yAxisOptions} />

                {tooltip && <Tooltip />}

                {legend && (
                    <RechartsLegend
                        wrapperStyle={
                            legend.props.verticalAlign === 'bottom' ? { bottom: 0 } : legend.props.wrapperStyle
                        }
                        {...legend.props}
                    />
                )}

                {lines.map(line => {
                    const { strokeColor, ...remainingLineProps } = line.props;
                    return <RechartsLine key={line.key} stroke={getColor(strokeColor)} {...remainingLineProps} />;
                })}

                {brush && <Brush stroke={getColor('gray')} />}
            </RechartsLineChart>
        </ResponsiveContainer>
    );
};

LineChart.defaultProps = {
    width: 480,
    height: 200,
    data: [],
    dataUnit: '',
    // dataKey,
    lines: [],
    // xAxisOptions,
    // yAxisOptions,
    // gridOptions,
    showXAxis: true,
    showYAxis: false,
    showGrid: false,
    brush: false,
    tooltip: true,
    // legend,
};

export default LineChart;
