import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Switch from '../switch/Switch';

const TreeOption = props => {
    const { isChecked, className, label, onChange, ...remainingProps } = props;

    const optionClasses = classNames(
        'TreeOption',
        'display-flex',
        'justify-content-start',
        'cursor-pointer',
        className
    );

    return (
        <div {...remainingProps} className={optionClasses} onClick={onChange}>
            <span className={'pointer-events-none scale-80'}>
                <Switch keyName={'TreeOption-switch'} checked={isChecked}/>
            </span>
            <span className={'margin-left-5 padding-top-2'}>{label}</span>
        </div>
    );
};

TreeOption.displayName = 'TreeOption';

TreeOption.defaultProps = {
    isChecked: false,
    onChange: () => {},
};

TreeOption.propTypes = {
    isChecked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    className: PropTypes.string,
};

export default TreeOption;
