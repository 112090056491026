import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ActionBarItemList = ({ className, children, ...remainingProps }) => {
    const classes = classNames('ActionBarItemList', className);
    return (
        <ul {...remainingProps} className={classes}>
            {children}
        </ul>
    );
};

ActionBarItemList.displayName = 'ActionBarItemList';

ActionBarItemList.propTypes = {
    className: PropTypes.string,
};

export default ActionBarItemList;
