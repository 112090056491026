import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DialogBody = ({ className, children }) => {
    const classes = classNames('modal-body', className);
    return <div className={classes}>{children}</div>;
};

DialogBody.defaultProps = {
    className: '',
};

DialogBody.propTypes = {
    className: PropTypes.string,
};

export default DialogBody;
