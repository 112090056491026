import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';
import head from 'lodash/fp/head';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import size from 'lodash/fp/size';
import onClickOutside from 'react-onclickoutside';

import { ApplicationActionBar } from './ApplicationActionBar';
import modulePropTypes from './modulePropTypes';

const collapsedDropdownWidth = 50;

export class NavItems extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasComputed: false,
            isCollapsedNavItemOpen: false,
            showCollapsedNavItems: false,
        };

        this.visibleNavItems = [];
        this.collapsedNavItems = [];

        this.resetRenderedNavItems = this.resetRenderedNavItems.bind(this);
        this.handleCollapsedDropdown = this.handleCollapsedDropdown.bind(this);
        this.handleCollapsedNavItemSelected = this.handleCollapsedNavItemSelected.bind(this);
    }

    resetRenderedNavItems() {
        this.collapsedNavItems = [];
        this.visibleNavItems = [];
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState(() => ({
                hasComputed: false,
            }));
        }
    }

    componentDidUpdate() {
        // After the component received new props, every nav item has to be rendered again without
        // collapsing them in the dropdown. Since there is no new render cycle triggered,
        // we need to recalculate the width of the items again and render trhe result.
        if (!this.state.hasComputed) {
            this.computeNavItems();
        }
    }

    getAvailableWidth(element) {
        // Get relevant elements
        const parent = element.parentNode;

        const actionBar = head(parent.getElementsByClassName('ApplicationActionBar'));
        const actionBarWidth = actionBar && actionBar.childElementCount > 0 ? actionBar.scrollWidth : 0;

        // Since the collapsed dropdown has not been rendered yet,
        // use the fixed with for it to compute available width
        const availableWidth = element.scrollWidth - actionBarWidth - collapsedDropdownWidth;

        return availableWidth;
    }

    computeNavItems() {
        if (this.props.isMobile) {
            return;
        }

        const element = this.navigationRef;
        const navItems = [...element.children];

        const availableWidth = this.getAvailableWidth(element);

        this.resetRenderedNavItems();

        // Iterate over all rendered navItems to figure out their widths in order to figure out
        // which navItems need to be rendered in the collapsed dropdown
        let requiredNavItemsSize = 0;
        navItems.forEach(item => {
            if (!item.className) {
                return;
            }

            // Calculate the remaining width
            requiredNavItemsSize = requiredNavItemsSize + item.scrollWidth;

            // Use the navItems.key to find corresponding navItems for each DOM node
            const matchedNavItem = this.props.navItems.find(navItem => {
                const dataAttr = item.attributes['data-nav-item-key'];
                return dataAttr && navItem.key === dataAttr.value;
            });

            // Add navItems to the visible group until the available with exceeds.
            // All other navItems will be added to the collapsed group
            if (availableWidth > requiredNavItemsSize) {
                this.visibleNavItems.push(matchedNavItem);
            } else {
                this.collapsedNavItems.push(matchedNavItem);
            }
        });

        // Set the state to render the navItems again
        this.setState(() => ({
            hasComputed: true,
        }));
    }

    handleClickOutside(event) {
        if (!event || !event.path) {
            return;
        }

        const isTargetActionBarItem = event.path.find(
            item => !!item.className && includes('ActionBarItemIcon', item.className)
        );

        const isPopoverTarget = event.path.find(item => !!item.className && includes('popover', item.className));

        // Do not close when any ActionBarItemIcon was clicked but close when a popover is is clicked
        if ((!isTargetActionBarItem && this.state.isCollapsedNavItemOpen) || isPopoverTarget) {
            this.setState(() => ({
                isCollapsedNavItemOpen: false,
            }));
        }
    }

    handleCollapsedDropdown() {
        // When the dropdown is open, set showCollapsedNavItems to false so all sumodule items
        // are rendered and their size can be computed
        if (this.state.isCollapsedNavItemOpen) {
            this.setState(() => ({
                isCollapsedNavItemOpen: false,
            }));
        } else {
            // When the dropdown is closed, just set the flag to open is enough
            this.setState(() => ({
                isCollapsedNavItemOpen: true,
            }));
        }
    }

    handleCollapsedNavItemSelected() {
        // Close collapsed dropdown on item select
        if (this.state.isCollapsedNavItemOpen) {
            this.setState(() => ({
                isCollapsedNavItemOpen: false,
            }));
        }
    }

    renderCollapsedNavItem(collapsedNavItems) {
        const { isMobile, containerWidth, actionBarItems } = this.props;

        const collapsedDropdownClass = this.state.isCollapsedNavItemOpen ? 'open' : '';

        const inlineStyle = isMobile ? { width: `${containerWidth}px` } : {};

        if (!collapsedNavItems.length && !actionBarItems.length) {
            return null;
        }

        return (
            <li className={`CollapsedDropdown dropdown ${collapsedDropdownClass}`} key={'collapsed-dropdown'}>
                <a
                    id='basic-nav-dropdown'
                    role='button'
                    className='dropdown-toggle'
                    aria-haspopup='true'
                    aria-expanded='true'
                    onClick={this.handleCollapsedDropdown}
                >
                    <span className='rioglyph rioglyph-option-horizontal' aria-hidden='true'></span>
                </a>

                <ul className={'dropdown-menu'} role='menu' aria-labelledby='basic-nav-dropdown' style={inlineStyle}>
                    <ApplicationActionBar items={this.props.actionBarItems} />
                    {collapsedNavItems.map(navItem => {
                        return (
                            <li
                                key={navItem.key}
                                className={'submodule nav-link-item'}
                                onClick={this.handleCollapsedNavItemSelected}
                                data-nav-item-key={navItem.key}
                            >
                                {navItem.route}
                            </li>
                        );
                    })}
                </ul>
            </li>
        );
    }

    renderNavItem(navItem, isOffscreen) {
        return (
            <li
                key={navItem.key}
                className={`submodule nav-link-item ${isOffscreen ? 'offscreen' : ''}`}
                data-nav-item-key={navItem.key}
            >
                {navItem.route}
            </li>
        );
    }

    renderNavItems() {
        const { isMobile, navItems, actionBarItems } = this.props;

        const isOffscreen = !this.state.hasComputed;

        const hasNavItems = !isEmpty(navItems);
        const hasSingleActionItem = size(actionBarItems) === 1;

        if (isMobile) {
            return !hasNavItems && hasSingleActionItem ? (
                <ApplicationActionBar items={actionBarItems} className={'position-relative'} />
            ) : (
                this.renderCollapsedNavItem(navItems)
            );
        }

        if (!isOffscreen && this.collapsedNavItems.length) {
            const items = this.visibleNavItems.map(navItem => this.renderNavItem(navItem, isOffscreen));
            items.push(this.renderCollapsedNavItem(this.collapsedNavItems));
            return items;
        }

        return navItems.map(navItem => this.renderNavItem(navItem, isOffscreen));
    }

    render() {
        // As all navItems need to be added to the DOM first in order to get their real size
        // the computation wheather a navItem need to be shown under the collapsed dropdown or not
        // needs to be done after the component did mount to the DOM.

        const classes = classname('SubmoduleNavigation', 'NavItems', 'nav', 'navbar-nav');

        return (
            <ul className={classes} ref={node => (this.navigationRef = node)}>
                {this.renderNavItems()}
            </ul>
        );
    }
}

export default onClickOutside(NavItems);

NavItems.defaultProps = {
    navItems: [],
    actionBarItems: [],
};

NavItems.propTypes = {
    navItems: PropTypes.arrayOf(modulePropTypes),
    containerWidth: PropTypes.number.isRequired,
};
