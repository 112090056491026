import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OverlayTrigger from '../overlay/OverlayTrigger';
import Tooltip from '../tooltip/Tooltip';

// TODO: add responsive behaviour and trigger a change from table to list for small screen -> see DriverAdministration

const wrapWithTooltip = (tooltipContent, element) => (
    <OverlayTrigger
        placement='bottom'
        overlay={
            <Tooltip id='tooltip' allowOnTouch>
                {tooltipContent}
            </Tooltip>
        }
    >
        {element}
    </OverlayTrigger>
);

const TableViewToggles = props => {
    const {
        viewType,
        initialViewType,
        onViewTypeChange,
        className,
        disabledViewTypes,
        disabled,
        tableViewTooltipContent,
        singleCardViewTooltipContent,
        multiCardsViewTooltipContent,
        ...remainingProps
    } = props;

    const [internaViewType, setInternalViewType] = useState(initialViewType);

    // update internal state when external state changes - controlled component case
    useEffect(() => {
        if (viewType) {
            setInternalViewType(viewType);
        }
    }, [viewType]);

    const handleSetTableView = () => {
        setInternalViewType(TableViewToggles.VIEW_TYPE_TABLE);
        onViewTypeChange(TableViewToggles.VIEW_TYPE_TABLE);
    };

    const handleSetSingleCardsView = () => {
        setInternalViewType(TableViewToggles.VIEW_TYPE_SINGLE_CARD);
        onViewTypeChange(TableViewToggles.VIEW_TYPE_SINGLE_CARD);
    };

    const handleSetMultiCardsView = () => {
        setInternalViewType(TableViewToggles.VIEW_TYPE_MULTI_CARDS);
        onViewTypeChange(TableViewToggles.VIEW_TYPE_MULTI_CARDS);
    };

    const wrapperClassNames = classNames(
        'TableViewToggles',
        'btn-group',
        'display-flex',
        'flex-row',
        className && className
    );

    const buttonClassNames = classNames('btn btn-default btn-icon-only', disabled && 'disabled pointer-events-none');

    const tableViewActiveClasses = classNames(
        buttonClassNames,
        internaViewType === TableViewToggles.VIEW_TYPE_TABLE && 'active'
    );

    const cardViewActiveClasses = classNames(
        buttonClassNames,
        internaViewType === TableViewToggles.VIEW_TYPE_SINGLE_CARD && 'active'
    );

    const multiCardsViewActiveClasses = classNames(
        buttonClassNames,
        internaViewType === TableViewToggles.VIEW_TYPE_MULTI_CARDS && 'active'
    );

    const tableViewButton = (
        <button className={tableViewActiveClasses} type='button' onClick={handleSetTableView}>
            <span className='rioglyph rioglyph-table-view' />
        </button>
    );

    const singleCardViewButton = (
        <button className={cardViewActiveClasses} type='button' onClick={handleSetSingleCardsView}>
            <span className='rioglyph rioglyph-th-list' />
        </button>
    );

    const multiCardViewButton = (
        <button className={multiCardsViewActiveClasses} type='button' onClick={handleSetMultiCardsView}>
            <span className='rioglyph rioglyph-split-view' />
        </button>
    );

    const showTableViewButton = !disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_TABLE);
    const showSingleCardViewButton = !disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_SINGLE_CARD);
    const showMultiCardViewButton = !disabledViewTypes.includes(TableViewToggles.VIEW_TYPE_MULTI_CARDS);

    return (
        <div {...remainingProps} className={wrapperClassNames}>
            {showTableViewButton &&
                (tableViewTooltipContent ? wrapWithTooltip(tableViewTooltipContent, tableViewButton) : tableViewButton)}
            {showSingleCardViewButton &&
                (singleCardViewTooltipContent
                    ? wrapWithTooltip(singleCardViewTooltipContent, singleCardViewButton)
                    : singleCardViewButton)}

            {showMultiCardViewButton &&
                (multiCardsViewTooltipContent
                    ? wrapWithTooltip(multiCardsViewTooltipContent, multiCardViewButton)
                    : multiCardViewButton)}
        </div>
    );
};

TableViewToggles.VIEW_TYPE_TABLE = 'TABLE';
TableViewToggles.VIEW_TYPE_SINGLE_CARD = 'SINGLE_CARD';
TableViewToggles.VIEW_TYPE_MULTI_CARDS = 'MULTI_CARDS';

TableViewToggles.defaultProps = {
    initialViewType: TableViewToggles.VIEW_TYPE_TABLE,
    onViewTypeChange: () => {},
    disabledViewTypes: [],
    disabled: false,
};

TableViewToggles.propTypes = {
    viewType: PropTypes.oneOf([
        TableViewToggles.VIEW_TYPE_TABLE,
        TableViewToggles.VIEW_TYPE_SINGLE_CARD,
        TableViewToggles.VIEW_TYPE_MULTI_CARDS,
    ]),
    initialViewType: PropTypes.oneOf([
        TableViewToggles.VIEW_TYPE_TABLE,
        TableViewToggles.VIEW_TYPE_SINGLE_CARD,
        TableViewToggles.VIEW_TYPE_MULTI_CARDS,
    ]),
    disabledViewTypes: PropTypes.arrayOf(
        PropTypes.oneOf([
            TableViewToggles.VIEW_TYPE_TABLE,
            TableViewToggles.VIEW_TYPE_SINGLE_CARD,
            TableViewToggles.VIEW_TYPE_MULTI_CARDS,
        ])
    ),
    onViewTypeChange: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    tableViewTooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    singleCardViewTooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    multiCardsViewTooltipContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default TableViewToggles;
