import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StatsWidgetBody = props => {
    const { children, fullWidth, className, ...remainingProps } = props;

    const classes = classNames(
        'StatsWidget-body',
        fullWidth && 'full-width',
        className,
    );

    return (
        <div {...remainingProps} className={classes}>
            {fullWidth && (
                <div className={'StatsWidget-body-column-spacer invisible'} />
            )}
            {children}
            {fullWidth && (
                <div className={'StatsWidget-body-column-spacer invisible'} />
            )}
        </div>
    );
};

StatsWidgetBody.defaultProps = {
    fullWidth: false,
};

StatsWidgetBody.propTypes = {
    fullWidth: PropTypes.bool,
    className: PropTypes.string,
};

export default StatsWidgetBody;
