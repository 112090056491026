import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import OverlayTrigger from '../overlay/OverlayTrigger';
import Tooltip from '../tooltip/Tooltip';

const DELAY_SHOW = 500;
const DELAY_HIDE = 300;

export default function OnboardingTip(props) {
    const {
        id,
        show,
        placement,
        title,
        content,
        onHide,
        children,
        textAlignment,
        useInDialog,
        clickflow,
        // clickflowBackdrop,
        showCloseIcon,
        className,
        width,
        preventOverflow,
        popperConfig,
    } = props;

    const tooltipWrapperClasses = classNames(
        useInDialog && 'z-index-max',
        className && className,
        clickflow && 'onboarding-clickflow'
        // clickflowBackdrop && 'onboarding-clickflow-backdrop'
    );
    const overlay = (
        <Tooltip
            className={tooltipWrapperClasses}
            tooltipStyle={Tooltip.STYLE_ONBOARDING}
            id={id}
            onClick={onHide}
            width={width}
            textAlignment={textAlignment}
            allowOnTouch
        >
            <div className='display-flex'>
                <div className='display-flex flex-column flex-1-1'>
                    {title && <div className='tooltip-title'>{title}</div>}
                    {content && <div className='tooltip-content'>{content}</div>}
                </div>
                {showCloseIcon && <span className='tooltip-close rioglyph rioglyph-remove' />}
            </div>
        </Tooltip>
    );

    const trigger = (
        <OverlayTrigger
            placement={placement}
            trigger='click'
            overlay={overlay}
            delayShow={DELAY_SHOW}
            delayHide={DELAY_HIDE}
            onToggle={onHide}
            show={show}
            rootClose={false}
            popperConfig={
                popperConfig || {
                    modifiers: [
                        {
                            name: 'preventOverflow',
                            options: {
                                // Popper.js default is: false but we enable it by default
                                altAxis: preventOverflow,
                            },
                        },
                    ],
                }
            }
        >
            {children}
        </OverlayTrigger>
    );

    return show ? trigger : children;
}

OnboardingTip.AUTO_START = Tooltip.AUTO_START;
OnboardingTip.AUTO = Tooltip.AUTO;
OnboardingTip.AUTO_END = Tooltip.AUTO_END;

OnboardingTip.TOP_START = Tooltip.TOP_START;
OnboardingTip.TOP = Tooltip.TOP;
OnboardingTip.TOP_END = Tooltip.TOP_END;

OnboardingTip.BOTTOM_START = Tooltip.BOTTOM_START;
OnboardingTip.BOTTOM = Tooltip.BOTTOM;
OnboardingTip.BOTTOM_END = Tooltip.BOTTOM_END;

OnboardingTip.RIGHT_START = Tooltip.RIGHT_START;
OnboardingTip.RIGHT = Tooltip.RIGHT;
OnboardingTip.RIGHT_END = Tooltip.RIGHT_END;

OnboardingTip.LEFT_START = Tooltip.LEFT_START;
OnboardingTip.LEFT = Tooltip.LEFT;
OnboardingTip.LEFT_END = Tooltip.LEFT_END;

export const OnboardingTipPlacementType = PropTypes.oneOf([
    OnboardingTip.AUTO_START,
    OnboardingTip.AUTO,
    OnboardingTip.AUTO_END,
    OnboardingTip.TOP_START,
    OnboardingTip.TOP,
    OnboardingTip.TOP_END,
    OnboardingTip.BOTTOM_START,
    OnboardingTip.BOTTOM,
    OnboardingTip.BOTTOM_END,
    OnboardingTip.RIGHT_START,
    OnboardingTip.RIGHT,
    OnboardingTip.RIGHT_END,
    OnboardingTip.LEFT_START,
    OnboardingTip.LEFT,
    OnboardingTip.LEFT_END,
]);

OnboardingTip.TEXT_ALIGNMENT_LEFT = 'left';
OnboardingTip.TEXT_ALIGNMENT_CENTER = 'center';
OnboardingTip.TEXT_ALIGNMENT_RIGHT = 'right';

OnboardingTip.defaultProps = {
    show: false,
    onHide: () => {},
    showCloseIcon: true,
    textAlignment: OnboardingTip.TEXT_ALIGNMENT_LEFT,
    useInDialog: false,
    className: '',
    clickflow: false,
    // clickflowBackdrop: false,
    placement: OnboardingTip.BOTTOM,
    width: null,
    preventOverflow: true,
};

OnboardingTip.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    show: PropTypes.bool.isRequired,
    showCloseIcon: PropTypes.bool,
    textAlignment: PropTypes.oneOf([
        OnboardingTip.TEXT_ALIGNMENT_LEFT,
        OnboardingTip.TEXT_ALIGNMENT_CENTER,
        OnboardingTip.TEXT_ALIGNMENT_RIGHT,
    ]),
    onHide: PropTypes.func.isRequired,
    useInDialog: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    clickflow: PropTypes.bool,
    // clickflowBackdrop: PropTypes.bool,
    placement: OnboardingTipPlacementType,
    width: PropTypes.oneOf(['auto', 100, 150, 200, 250, 300, 350, 400, 450, 500]),
    preventOverflow: PropTypes.bool,
    popperConfig: PropTypes.object,
};
