import PropTypes from 'prop-types';

export default {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    iconNavigation: PropTypes.node,
    showCloseButton: PropTypes.bool,
    onClose: PropTypes.func,
    bsSize: PropTypes.oneOf(['sm', 'lg', 'xl', 'full', 'fullscreen']),
    disableEsc: PropTypes.bool,
    useOverflow: PropTypes.bool,
    className: PropTypes.string,
};
