import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonDropdown from '../dropdown/ButtonDropdown';
import { isEmpty } from 'lodash';

const TreeOptions = props => {
    const { treeOptions, className } = props;

    if (isEmpty(treeOptions)) {
        return null;
    }

    const dropdownClasses = classNames('width-250', 'padding-10', className);

    const customDropdown = <div className={dropdownClasses}>{treeOptions}</div>;

    return (
        <ButtonDropdown
            title={<span className='rioglyph rioglyph-option-vertical' aria-hidden='true' />}
            bsStyle={'muted'}
            customDropdown={customDropdown}
            className={'TreeHeaderOptions'}
            pullRight
            iconOnly
        />
    );
};

TreeOptions.displayName = 'TreeOptions';

TreeOptions.defaultProps = {
    treeOptions: [],
};

TreeOptions.propTypes = {
    treeOptions: PropTypes.arrayOf(PropTypes.node),
    className: PropTypes.string,
};

export default TreeOptions;
