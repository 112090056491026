import React from 'react';

import { ChromeIcon, FirefoxIcon } from './BrowserIcons';

const BrowserWarningMessageDE = ({ onBrowserSelect }) => (
    <React.Fragment>
        <div className='text-center padding-right-1pct padding-left-1pct'>
            <h1 className='margin-bottom-25 padding-top-25'>Sie nutzen einen veralteten Browser</h1>
            <p className='text-size-18 margin-bottom-25'>
                {'Der '}
                <b>Internet Explorer</b>
                {' ist mit seinen knapp 7 Jahren nun langsam ins hohe Alter gekommen und kann '}
                {'in Sachen '}
                <b>Schnelligkeit</b>
                {', '}
                <b>Features</b>
                {' aber vor allem in Punkto '}
                <b>Sicherheit</b>, nicht mehr mit modernen Browsern mithalten.
            </p>
            <p className='text-size-18 margin-bottom-25'>
                {'Wir bei RIO haben uns das Ziel gesetzt, Ihnen die '}
                <b>bestmögliche Software</b>
                {' bereitzustellen um Ihnen ein '}
                <b>optimales Nutzungserlebnis</b>
                {' zu bieten. Daher haben wir uns dazu entschlossen den Support für '}
                den IE11 einzustellen.
            </p>
            <p className='text-size-18'>
                <b>Erleben Sie RIO von einer ganz neuen Seite, z.B. mit einem der folgenden Browser:</b>
            </p>
        </div>
        <div
            className={`display-flex justify-content-center justify-content-around flex-wrap
                padding-right-2pct-ls padding-left-2pct-ls`}
        >
            <a
                href='https://www.google.com/chrome/'
                target='_blank'
                rel='noopener noreferrer'
                className='display-block hover-scale-110'
                onClick={() => onBrowserSelect('chrome')}
            >
                <ChromeIcon />
            </a>
            <a
                href='https://www.mozilla.org/firefox/new/'
                target='_blank'
                rel='noopener noreferrer'
                className='display-block hover-scale-110'
                onClick={() => onBrowserSelect('firefox')}
            >
                <FirefoxIcon />
            </a>
        </div>
        {/* <div className={'text-center padding-right-2pct padding-left-2pct'}>
            <hr />
            <p>
                {'Sollten Sie nicht wissen wie man einen anderen Browser installiert, oder andere Fragen zur '}
                {'Umstellung haben, sind wir natürlich immer unter der '}
                <b>{'Hotline +(49) 08 00 / 22 55 07 46'}</b>
                {' für Sie erreichbar und helfen Ihnen gerne.'}
            </p>
        </div> */}
    </React.Fragment>
);

export default BrowserWarningMessageDE;
