/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import { default as _ContentLoader } from 'react-content-loader';
import cond from 'lodash/fp/cond';
import isNil from 'lodash/fp/isNil';

const hasChildren = ({ children }) => !isNil(children);
const renderChildren = ({ children }) => children;

const isRect = ({ type }) => type === ContentLoader.RECT;
const isCircle = ({ type }) => type === ContentLoader.CIRCLE;

const renderRect = ({ rx = 3, ry = 3 }) => <rect width={'100%'} height={'100%'} rx={rx} ry={ry} />;
const renderCircle = ({ radius }) => <circle cx={radius} cy={radius} r={radius} />;

const ContentLoader = props => {
    const { width, height, radius, className, ...remainingProps } = props;

    const content = cond([
        [hasChildren, renderChildren],
        [isRect, renderRect],
        [isCircle, renderCircle],
    ])(props);

    const wrapperHeight = isCircle(props) ? radius * 2 : height;
    const wrapperWidth = isCircle(props) ? radius * 2 : width;

    return (
        <_ContentLoader
            className={`ContentLoader ${className}`}
            height={wrapperHeight}
            width={wrapperWidth}
            {...remainingProps}
        >
            {content}
        </_ContentLoader>
    );
};

ContentLoader.RECT = 'rect';
ContentLoader.CIRCLE = 'circle';

ContentLoader.defaultProps = {
    speed: 2,
    height: 14,
    width: '100%',
    radius: 16,
    type: 'rect',
    className: '',
    title: '',
};

ContentLoader.propTypes = {
    speed: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(['rect', 'circle', ContentLoader.RECT, ContentLoader.CIRCLE]),
    radius: PropTypes.number,
    title: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
};

export default ContentLoader;
