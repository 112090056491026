import React from 'react';
import PropTypes from 'prop-types';

function formatDegrees(degrees, positivePrefix, negativePrefix) {
    const prefix = degrees >= 0 ? positivePrefix : negativePrefix;
    const absDegrees = Math.abs(degrees);
    const flooredDegrees = Math.floor(absDegrees);
    const minutes = (absDegrees - flooredDegrees) * 60;
    return `${prefix}${flooredDegrees}\u00b0 ${minutes.toFixed(3)}\u2032`;
}

const Position = props => {
    const { latitude, longitude, address } = props;

    let formattedAddress;
    if (!address) {
        formattedAddress = `${formatDegrees(latitude, 'N', 'S')} ${formatDegrees(longitude, 'E', 'W')}`;
    } else {
        formattedAddress = address;
    }

    return <span className={'vehiclePositionInfo'}>{formattedAddress}</span>;
};

Position.propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    address: PropTypes.string,
};

export default Position;
