import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const ApplicationLayoutBodyBottomBar = ({ className, buttonAlignment, useBodyPaddings, children }) => {
    const classes = classNames(
        'ApplicationLayoutBodyBottomBar',
        buttonAlignment === 'left' && 'justify-content-start',
        buttonAlignment === 'right' && 'justify-content-end',
        buttonAlignment !== 'left' && buttonAlignment !== 'right' && 'justify-content-between',
        useBodyPaddings && 'use-body-padding',
        className && className
    );

    return <div className={classes}>{children}</div>;
};

ApplicationLayoutBodyBottomBar.LEFT = 'left';
ApplicationLayoutBodyBottomBar.RIGHT = 'right';

ApplicationLayoutBodyBottomBar.defaultProps = {
    useBodyPaddings: false,
};

ApplicationLayoutBodyBottomBar.propTypes = {
    className: PropTypes.string,
    useBodyPaddings: PropTypes.bool,
    buttonAlignment: PropTypes.oneOf([ApplicationLayoutBodyBottomBar.LEFT, ApplicationLayoutBodyBottomBar.RIGHT]),
};

export default ApplicationLayoutBodyBottomBar;
