import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Caret from './Caret';

const SplitCaretButton = React.forwardRef(({ disabled, bsStyle, className, ...remainingProps }, ref) => {
    const splitCaretButtonClasses = classNames(
        'dropdown-toggle',
        'btn',
        `btn-${bsStyle}`,
        disabled && 'disabled',
        className
    );

    return (
        <button {...remainingProps} role={'button'} type={'button'} className={splitCaretButtonClasses} ref={ref}>
            <Caret />
        </button>
    );
});

export default SplitCaretButton;

SplitCaretButton.propTypes = {
    disabled: PropTypes.bool,
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success', 'link', 'muted']),
    className: PropTypes.string,
};
