/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import TableSettingsListItem from './TableSettingsListItem';

export const TableSettingsListContainer = props => {
    const {
        items,
        columnOrder,
        onSortEnd,
        lockAxis,
        helperClass,
        columnSearchValue,
        isSortable,
        ...remainingProps
    } = props;

    return (
        <div className={'table-settings-items-container'}>
            {items.map((column, index) => (
                <TableSettingsListItem
                    key={column}
                    index={index}
                    value={column}
                    orderIndex={index}
                    columnOrder={columnOrder}
                    columnSearchValue={columnSearchValue}
                    disabled={isSortable}
                    {...remainingProps}
                />
            ))}
        </div>
    );
};

export const TableSettingsListContainerSortable = SortableContainer(TableSettingsListContainer);
