import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const Tag = (props) => {
    const {
        children,
        icon,
        active,
        disabled,
        clickable,
        selectable,
        deletable,
        revertable,
        multiline,
        muted,
        round,
        ...remainingProps
    } = props;

    return (
        <div
            {...remainingProps}
            className={
                classname(
                    'tag',
                    (props.size === 'small') && 'tag-small',
                    icon && `rioglyph ${icon}`,
                    active && 'active clickable rioglyph rioglyph-ok',
                    disabled && 'disabled',
                    clickable && 'clickable',
                    selectable && 'selectable clickable rioglyph rioglyph-checkbox',
                    deletable && 'deletable clickable rioglyph rioglyph-remove',
                    revertable && 'revertable clickable rioglyph rioglyph-revert',
                    multiline && 'multiline',
                    muted && 'tag-muted',
                    !round && 'rounded', // sounds wrong, but is right
                    props.className
                )
            }
        >
            <div className={'tag-inner'}>{children}</div>
        </div>
    );
};

Tag.defaultProps = {
    active: false,
    clickable: false,
    deletable: false,
    revertable: false,
    disabled: false,
    muted: false,
    round: true,
    selectable: false,
};

Tag.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    clickable: PropTypes.bool,
    deletable: PropTypes.bool,
    revertable: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    muted: PropTypes.bool,
    multiline: PropTypes.bool,
    round: PropTypes.bool,
    selectable: PropTypes.bool,
    size: PropTypes.oneOf(['small']),
};

export default Tag;
