// Inspired from https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-clipboard/use-clipboard.ts

import { useState } from 'react';
import isNil from 'lodash/fp/isNil';

export default function useClipboard({ timeout = 2000 } = {}) {
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [copyTimeout, setCopyTimeout] = useState(null);

    const handleCopyResult = value => {
        clearTimeout(copyTimeout);
        setCopyTimeout(setTimeout(() => setCopied(false), timeout));
        setCopied(value);
    };

    const copy = valueToCopy => {
        if (!isNil(navigator.clipboard)) {
            navigator.clipboard
                .writeText(valueToCopy)
                .then(() => handleCopyResult(true))
                .catch(error => setError(error));
        } else {
            setError(new Error('useClipboard: navigator.clipboard is not supported'));
        }
    };

    const reset = () => {
        setCopied(false);
        setError(null);
        clearTimeout(copyTimeout);
    };

    return { copy, reset, error, copied };
}
