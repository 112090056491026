import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dialog from './Dialog';
import baseDialogPropTypes from './baseDialogPropTypes';

const SplitDialog = props => {
    const { leftContent, rightContent, onClose, className } = props;

    const dialogClassName = `split-dialog ${className}`;

    const dividerClasses = classNames('split-divider', 'bg-lighter');

    const body = (
        <div className={'split-wrapper'}>
            <div className={'split-left'}>
                {leftContent}
                <div className={dividerClasses}></div>
            </div>
            <div className={'split-right'}>{rightContent}</div>
        </div>
    );

    return <Dialog {...props} body={body} onHide={onClose} className={dialogClassName} />;
};

SplitDialog.defaultProps = {
    className: '',
    footer: '',
    useOverflow: true,
    showCloseButton: true,
};

SplitDialog.propTypes = {
    ...baseDialogPropTypes,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    footer: PropTypes.node,
};

export default SplitDialog;
