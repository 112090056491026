import React from 'react';

export const ApplicationActionBar = ({ items, nodeRef, className = '' }) => (
    <ul className={`ApplicationActionBar nav navbar-nav navbar-right ${className}`} ref={nodeRef}>
        {items &&
            items.map((item, index) => {
                return (
                    <li key={index} role='presentation' className='navigationItem'>
                        {item}
                    </li>
                );
            })}
    </ul>
);
