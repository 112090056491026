import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { createButtonRipple } from '../../utils/buttonEffect';

const Button = props => {
    const {
        active,
        disabled,
        asToggle,
        onClick,
        bsStyle,
        bsSize,
        variant,
        iconOnly,
        multiline,
        block,
        className,
        children,
        ...remainingProps
    } = props;

    const [isToggled, setIsToggled] = useState(active);

    const btnRef = useRef();

    // Update internal toggle state when used as controlled component
    // and outside toggle state changes
    useEffect(() => setIsToggled(active), [active]);

    // Register a dedicated click event on the button to interrupt
    // the actual 'onClick' callback passed into the component from outside
    useEffect(() => {
        if (!btnRef.current) {
            return;
        }
        const mouseListener = btnRef.current.addEventListener('mousedown', createButtonRipple, false);
        const touchListener = btnRef.current.addEventListener('touchstart', createButtonRipple, false);
        return () => {
            mouseListener && btnRef.current.removeEventListener('mousedown', createButtonRipple, false);
            touchListener && btnRef.current.removeEventListener('touchstart', createButtonRipple, false);
        };
    }, [btnRef.current]);

    // Intercept click handler only for toggle button to update
    // internal state and blur after click
    const handleClick = () => {
        const newIsToggled = !isToggled;
        setIsToggled(newIsToggled);
        btnRef.current && newIsToggled && btnRef.current.blur();
        onClick(isToggled);
    };

    const buttonClassNames = classNames(
        'btn',
        `btn-${bsStyle}`,
        variant === Button.VARIANT_LINK && 'btn-link',
        variant === Button.VARIANT_LINK_INLINE && 'btn-link btn-link-inline',
        variant === Button.VARIANT_OUTLINE && 'btn-outline',
        variant === Button.VARIANT_ACTION && 'btn-action',
        bsSize && `btn-${bsSize}`,
        asToggle && 'btn-toggle',
        isToggled && 'active',
        iconOnly && 'btn-icon-only',
        disabled && 'disabled',
        multiline && 'btn-multiline',
        block && 'btn-block',
        'btn-component',
        className
    );

    return (
        <button
            ref={btnRef}
            type={'button'}
            {...remainingProps}
            className={buttonClassNames}
            onClick={asToggle ? handleClick : onClick}
        >
            {children}
        </button>
    );
};

// Button styles
Button.DEFAULT = 'default';
Button.PRIMARY = 'primary';
Button.SECONDARY = 'secondary';
Button.INFO = 'info';
Button.WARNING = 'warning';
Button.DANGER = 'danger';
Button.SUCCESS = 'success';
Button.MUTED = 'muted';

// Button Type
Button.VARIANT_LINK = 'link';
Button.VARIANT_LINK_INLINE = 'link-inline';
Button.VARIANT_OUTLINE = 'outline';
Button.VARIANT_ACTION = 'action';

// Button Size
Button.XS = 'xs';
Button.SM = 'sm';
Button.MD = 'md';
Button.LG = 'lg';

Button.defaultProps = {
    active: false,
    disabled: false,
    asToggle: false,
    iconOnly: false,
    multiline: false,
    block: false,
    onClick: () => {},
    className: '',
    bsStyle: 'default',
};

Button.propTypes = {
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    asToggle: PropTypes.bool,
    iconOnly: PropTypes.bool,
    multiline: PropTypes.bool,
    block: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    bsStyle: PropTypes.oneOf([
        Button.DEFAULT,
        Button.PRIMARY,
        Button.SECONDARY,
        Button.INFO,
        Button.WARNING,
        Button.DANGER,
        Button.SUCCESS,
        Button.MUTED,
    ]),
    bsSize: PropTypes.oneOf([Button.XS, Button.SM, Button.MD, Button.LG]),
    variant: PropTypes.oneOf([
        Button.VARIANT_LINK,
        Button.VARIANT_LINK_INLINE,
        Button.VARIANT_OUTLINE,
        Button.VARIANT_ACTION,
    ]),
    className: PropTypes.string,
};

export default Button;
