import colors from '../../style/css/shared/colors.json';

const brightColors = ['color-warmup-corn', 'color-coldplay-khaki'];

export const defaultChartColors = Object.keys(colors)
    .reverse()
    .filter(key => !brightColors.includes(key))
    .map(key => colors[key]);

export const getFromDefaultColors = index => defaultChartColors[index];

export const getColor = colorKey => colors[colorKey];

export const CURSOR_BACKGROUND_COLOR = '#a7afbb24';

export const mapGridOptions = (gridOptions) => {
    if (!gridOptions) {
        return;
    }

    const mappedGridOptions = { ...gridOptions };
    const { stroke } = mappedGridOptions;

    mappedGridOptions.stroke = stroke && !stroke.startsWith('#') ? getColor(stroke) : stroke;

    return mappedGridOptions;
};
