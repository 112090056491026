import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import isNil from 'lodash/fp/isNil';
import noop from 'lodash/fp/noop';

import logDeprecatedWarnings from '../../utils/logDeprecatedWarnings';
import { getOrCreatePortalRoot } from '../../utils/portalRoot';
import useClickOutside from '../../hooks/useClickOutside';
import getMenuItems from '../menuItems/getMenuItems';
import MenuItems from '../menuItems/MenuItems';
import menuItemsPropTypes from './menuItemsPropTypes';
import DropdownToggleButton from './DropdownToggleButton';
import SplitCaretButton from './SplitCaretButton';
import Caret from './Caret';

const getPlacement = (pullRight, dropup) => {
    if (pullRight && dropup) {
        return 'top-end';
    }
    if (!pullRight && dropup) {
        return 'top-start';
    }
    if (pullRight && !dropup) {
        return 'bottom-end';
    }
    return 'bottom-start';
};

const ButtonDropdown = props => {
    const {
        id,
        items,
        bsSize,
        bsStyle,
        disabled,
        iconOnly,
        title,
        splitButton,
        customDropdown,
        toggleClassName,
        dropdownClassName,
        className,
        open,
        dropup,
        pullRight,
        noCaret,
        onOpen,
        onClose,
        onLabelButtonClick,
        usePortal,
        popperConfig,
    } = props;

    const [internalOpen, setInternalOpen] = useState(open);

    const [refDropdownToggle, setRefDropdownToggle] = React.useState(null);
    const [refDropdownMenu, setRefDropdownMenu] = React.useState(null);

    const defaultPopperConfig = {
        placement: getPlacement(pullRight, dropup),
        modifiers: [],
    };

    const { styles, attributes } = usePopper(refDropdownToggle, refDropdownMenu, popperConfig || defaultPopperConfig);

    const wrapperRef = useClickOutside(() => closeMenu());

    const dropdownRoot = getOrCreatePortalRoot();

    const isUncontrolled = isNil(open);

    const shouldShowCaret = !noCaret && !splitButton && !iconOnly;

    useEffect(() => {
        if (!isUncontrolled) {
            // console.log('update open from outside: ' + open);
            setInternalOpen(open);
        }
    }, [isUncontrolled, open]);

    const toggleOpen = event => {
        const isOpen = isUncontrolled ? internalOpen : open;

        if (isOpen) {
            closeMenu();
        } else {
            openMenu(event);
        }
    };

    const openMenu = event => {
        if (isUncontrolled) {
            setInternalOpen(true);
        }
        onOpen(event);
    };

    const closeMenu = () => {
        if (isUncontrolled) {
            setInternalOpen(false);
        }
        onClose();
    };

    const handleSplitLabelButtonClick = () => {
        closeMenu();
        onLabelButtonClick();
    };

    const handleDropdownButtonClick = splitButton ? handleSplitLabelButtonClick : toggleOpen;

    const isOpen = isUncontrolled ? internalOpen : open;

    const wrapperClasses = classNames('dropdown', 'btn-group', isOpen && 'open', className);

    const dropdownClasses = classNames(usePortal && 'dropdown-portal', dropdownClassName);

    const dropdownMenu = (
        <MenuItems className={dropdownClasses} ref={setRefDropdownMenu} style={styles.popper} {...attributes.popper}>
            {customDropdown ? customDropdown : getMenuItems(items, toggleOpen)}
        </MenuItems>
    );

    return (
        <div className={wrapperClasses} ref={wrapperRef}>
            <DropdownToggleButton
                id={id}
                splitButton={splitButton}
                bsStyle={bsStyle}
                bsSize={bsSize}
                iconOnly={iconOnly}
                disabled={disabled}
                ref={setRefDropdownToggle}
                onClick={handleDropdownButtonClick}
                className={toggleClassName}
            >
                <React.Fragment>
                    {title}
                    {shouldShowCaret && <Caret />}
                </React.Fragment>
            </DropdownToggleButton>
            {splitButton && (
                <SplitCaretButton
                    id={id}
                    bsStyle={bsStyle}
                    disabled={disabled}
                    className={toggleClassName}
                    onClick={toggleOpen}
                />
            )}
            {isOpen && usePortal && ReactDOM.createPortal(dropdownMenu, dropdownRoot)}
            {isOpen && !usePortal && dropdownMenu}
        </div>
    );
};

ButtonDropdown.defaultProps = {
    id: Math.random().toString(36).substr(2, 16),
    items: [],
    iconOnly: false,
    noCaret: false,
    pullRight: false,
    splitButton: false,
    dropup: false,
    bsStyle: 'default',
    disabled: false,
    onOpen: noop,
    onClose: noop,
    onLabelButtonClick: noop,
    toggleClassName: '',
    usePortal: false,
    className: '',
};

ButtonDropdown.propTypes = {
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    open: PropTypes.bool,
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    bsSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success', 'link', 'muted']),
    iconOnly: PropTypes.bool,
    noCaret: PropTypes.bool,
    splitButton: PropTypes.bool,
    usePortal: PropTypes.bool,
    items: menuItemsPropTypes.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    onLabelButtonClick: PropTypes.func,
    customDropdown: PropTypes.node,
    popperConfig: PropTypes.object,
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
};

export default ButtonDropdown;
