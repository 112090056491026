import React from 'react';
import PropTypes from 'prop-types';

import Dialog from './Dialog';
import baseDialogPropTypes from './baseDialogPropTypes';

/*
 * Component to show a modal popup containing a title, a content and a close handler.
 */
const SimpleDialog = (props) => {
    const {
        content,
        onClose,
        className,
    } = props;

    const dialogClassName = `${className ? className : ''} simple-dialog`;

    return (
        <Dialog
            {...props}
            body={content}
            onHide={onClose}
            className={dialogClassName}
        />
    );
};

SimpleDialog.defaultProps = {
    showCloseButton: true,
};

SimpleDialog.propTypes = {
    ...baseDialogPropTypes,
    content: PropTypes.node,
};

export default SimpleDialog;
