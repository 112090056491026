import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import classname from 'classnames';
import logPropError from '../../utils/logPropError';

const errorProps = [
    {
        prop: 'labelsClassName',
        replacement: 'labels.labelClassName',
    },
];

export default class SteppedProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableFollowingPages: props.disableFollowingPages
                ? props.disableFollowingPages
                : this.props.selectedStepNumber,
            isValid: true,
        };
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.disableFollowingPages !== undefined) {
            if (nextProps.disableFollowingPages < nextProps.selectedStepNumber) {
                // eslint-disable-next-line no-console
                console.warn(
                    'Invalid component state detected in SteppedProgressBar: can not disable following ' +
                        `pages ${nextProps.disableFollowingPages} when selectedStep is ${nextProps.selectedStep}!`
                );

                this.setState({
                    isValid: false,
                });
                return;
            }

            this.setState({
                disableFollowingPages: nextProps.disableFollowingPages,
            });
        } else if (nextProps.selectedStepNumber > this.state.disableFollowingPages) {
            this.setState({
                disableFollowingPages: nextProps.selectedStepNumber,
            });
        }
    }

    handleSelect(selectedKey) {
        this.props.onSelectedChanged(Number(selectedKey));
    }

    getItems(labels) {
        return labels.map((labelObject, index) => {
            return (
                <Nav.Link
                    eventKey={index}
                    disabled={index > this.state.disableFollowingPages}
                    as={'a'}
                    key={index}
                    className={classname('step-tab', labelObject.labelWrapperClassName)}
                >
                    <div className={'step-tab-content'}>
                        <div className={'item-icon'}>{labelObject.icon}</div>
                        <div className={classname('item-label', labelObject.labelClassName)}>{labelObject.label}</div>
                    </div>
                </Nav.Link>
            );
        });
    }

    render() {
        if (!this.state.isValid) {
            return <b>{`FIXME: INVALID STATE DETECTED!`}</b>;
        }

        const { className, variant, mobileBreakpoint, selectedStepNumber } = this.props;

        const wrapperClasses = classname(
            'stepped-progress-bar',
            variant && `variant-${variant}`,
            mobileBreakpoint && 'mobile-breakpoint',
            className && className
        );

        logPropError(errorProps, this.props, 'SteppedProgressBar');

        return (
            <Nav
                className={wrapperClasses}
                activeKey={selectedStepNumber}
                onSelect={selectedKey => this.handleSelect(selectedKey)}
            >
                {this.getItems(this.props.labels)}
            </Nav>
        );
    }
}

SteppedProgressBar.defaultProps = {
    selectedStepNumber: 1,
    labels: [],
    onSelectedChanged: () => {},
    variant: 'circle',
    mobileBreakpoint: true,
};

SteppedProgressBar.propTypes = {
    disableFollowingPages: PropTypes.number,
    onSelectedChanged: PropTypes.func.isRequired,
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
            labelClassName: PropTypes.string,
            labelWrapperClassName: PropTypes.string,
        })
    ).isRequired,
    itemClassName: PropTypes.string,
    selectedStepNumber: PropTypes.number.isRequired,
    className: PropTypes.string,
    mobileBreakpoint: PropTypes.bool,
    variant: PropTypes.oneOf(['circle', 'rectangle', 'roadsign']),
};
