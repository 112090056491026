import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const TreeNodeContainer = ({ isOpen, groupId, children }) => {
    const treeNodeContainerClasses = classNames('TreeNodeContainer', 'user-select-none', isOpen && 'open');

    return (
        <div className={treeNodeContainerClasses} data-id={groupId}>
            {children}
        </div>
    );
};

TreeNodeContainer.displayName = 'TreeNodeContainer';

TreeNodeContainer.propTypes = {
    maxHeight: PropTypes.number,
};

export default TreeNodeContainer;
