import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const StatsWidgets = props => {
    const { children, className, ...remainingProps } = props;

    const classes = classNames('StatsWidget-wrapper', className);

    return (
        <div {...remainingProps} className={classes}>
            {children}
        </div>
    );
};

StatsWidgets.propTypes = {
    className: PropTypes.string,
};

export default StatsWidgets;
