import React from 'react';
import PropTypes from 'prop-types';

const DialogHeader = props => {
    const { title, subtitle, headerButtons, showCloseButton, onClose } = props;
    return (
        <div className={'modal-header'}>
            <div className={'modal-header-text'}>
                {subtitle && <div className={'modal-header-subtitle'}>{subtitle}</div>}
                <div className={'modal-header-title'}>{title}</div>
            </div>
            <div className={'modal-header-buttons'}>
                {headerButtons && (
                    <React.Fragment>
                        {headerButtons}
                        <div className={'modal-header-buttons-spacer'}></div>
                    </React.Fragment>
                )}
                {showCloseButton && (
                    <button
                        type={'button'}
                        className={'btn btn-icon-only btn-muted modal-header-close close'}
                        onClick={onClose}
                        data-testid={'close'}
                    >
                        <span className={'rioglyph rioglyph-remove'} aria-hidden={'true'}></span>
                    </button>
                )}
            </div>
        </div>
    );
};

DialogHeader.defaultProps = {
    showCloseButton: true,
    onClose: () => {},
};

DialogHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showCloseButton: PropTypes.bool,
    onClose: PropTypes.func,
    headerButtons: PropTypes.node,
};

export default DialogHeader;
