import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';
import baseDialogPropTypes from './baseDialogPropTypes';

const InfoDialog = (props) => {
    const {
        content,
        onClose,
        className,
    } = props;

    const dialogClassName = `${className ? className : ''} info-dialog`;

    return (
        <Dialog
            {...props}
            body={content}
            onHide={onClose}
            className={dialogClassName}
        />
    );
};

InfoDialog.propTypes = {
    ...baseDialogPropTypes,
    content: PropTypes.node,
};

export default InfoDialog;
