import React from 'react';
import PropTypes from 'prop-types';

const TreeNothingFound = React.memo(() => {
    return (
        <div
            className={`TreeTreeNothingFound display-flex justify-content-center text-size-20
                padding-top-25 margin-top-25 margin-bottom-25`}
        >
            <span className={'rioglyph rioglyph-looking-glass-man text-size-400pct text-color-light'} />
        </div>
    );
});

TreeNothingFound.displayName = 'TreeNothingFound';

TreeNothingFound.defaultProps = {};

TreeNothingFound.propTypes = {
    value: PropTypes.string,
    className: PropTypes.string,
};

export default TreeNothingFound;
