import React from 'react';

import { getColor } from './chartHelper';

const AreaGradient = ({ id, color }) => (
    <linearGradient key={id} id={id} x1='0' y1='0' x2='0' y2='1'>
        <stop offset='5%' stopColor={getColor(color)} stopOpacity={0.8} />
        <stop offset='95%' stopColor={getColor(color)} stopOpacity={0} />
    </linearGradient>
);

AreaGradient.defaultProps = {
    id: 'areaGradient',
    color: 'color-coldplay-fountain',
};

export default AreaGradient;
