import React from 'react';

const ChartNeedle = props => {
    const { width, height, x, y, rotation, className, style } = props;

    const needleStyle = {
        width,
        height,
        transformOrigin: 'bottom',
        transform: `translate(${x}, ${y}) rotate(${rotation}deg)`,
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
        borderTopLeftRadius: '100%',
        borderTopRightRadius: '100%',
        display: 'inline-block',
        ...style,
    };

    return (
        <div className={'position-relative'}>
            <div className={`position-absolute bg-dark ${className}`} style={needleStyle} />
        </div>
    );
};

ChartNeedle.defaultProps = {
    width: '5px',
    height: '65px',
    x: '-50%',
    y: '-100%',
    rotation: 0,
    className: '',
    // style
};

export default ChartNeedle;
