import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import get from 'lodash/fp/get';

import ClearableInput from '../clearableInput/ClearableInput';
import Spinner from '../spinner/Spinner';
import scrollItemIntoView, { UP, DOWN } from '../../utils/scrollItemIntoView';
import getDropDirection from '../../utils/getDropDirection';
import DropdownHeader from '../selects/DropdownHeader';
import MenuItem from '../menuItems/MenuItem';

const NoItemMessage = ({ message }) => (
    <li className={'no-item-message disabled'} disabled>
        <a role={'button'}>
            <i>{message}</i>
        </a>
    </li>
);

export class AutoSuggest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            value: props.inputProps.value || '',
            dropup: props.dropup,
            pullRight: props.pullRight,
            highlightedItemIndex: -1,
        };

        this.openMenu = this.openMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.setInputValue = this.setInputValue.bind(this);
        this.clearInputValue = this.clearInputValue.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.props.onSuggestionsFetchRequested({ value: this.state.value });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onSuggestionHighlighted) {
            const currentHighlightedSuggestion = this.getHighlightedSuggestion();
            const prevHighlightedSuggestion = prevState.highlightedSuggestion;

            if (currentHighlightedSuggestion !== prevHighlightedSuggestion) {
                this.props.onSuggestionHighlighted({
                    suggestion: currentHighlightedSuggestion,
                });
            }
        }

        // Update internal value state from outside
        const updatedValueFromProps = get('inputProps.value')(this.props);
        if (!isEqual(updatedValueFromProps, this.state.value)) {
            this.setState(() => ({
                value: updatedValueFromProps,
            }));
        }
    }

    getHighlightedSuggestion() {
        if (this.state.highlightedItemIndex === -1) {
            return null;
        }
        return this.props.suggestions[this.state.highlightedItemIndex];
    }

    openMenu(/*event*/) {
        let dropDirection = {};
        if (this.refDropdownMenu && this.isAutoDropActive() && !this.state.open) {
            dropDirection = getDropDirection(this.refDropdownMenu.parentNode, this.refDropdownMenu);
        }

        this.setState({
            open: true,
            ...dropDirection,
        });
    }

    closeMenu() {
        this.setState({
            open: false,
        });
    }

    handleClickOutside() {
        this.closeMenu();
    }

    handleFocus(event) {
        const { openOnFocus, inputProps } = this.props;
        if (openOnFocus) {
            this.openMenu();
        }
        if (inputProps.onFocus) {
            inputProps.onFocus(event);
        }
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    getDropdownDOMNode() {
        return ReactDOM.findDOMNode(this.refDropdownMenu);
    }

    handleKeyDown(event) {
        const { keyCode } = event;
        const { highlightedItemIndex } = this.state;
        const { suggestions } = this.props;

        switch (keyCode) {
            case 40:
                // select below on arrow down key
                if (this.state.open && suggestions.length > highlightedItemIndex + 1) {
                    this.setState(
                        {
                            highlightedItemIndex: highlightedItemIndex + 1,
                        },
                        () => {
                            scrollItemIntoView(DOWN, this.getDropdownDOMNode(), this.getFocusedOptionNode());
                        }
                    );
                }
                return;
            case 38:
                // select above on arrow up key
                if (this.state.open && highlightedItemIndex > 0) {
                    this.setState(
                        {
                            highlightedItemIndex: highlightedItemIndex - 1,
                        },
                        () => {
                            scrollItemIntoView(UP, this.getDropdownDOMNode(), this.getFocusedOptionNode());
                        }
                    );
                }
                return;
            case 13:
                // select on enter
                if (this.state.open && suggestions[highlightedItemIndex]) {
                    this.onSuggestionClicked(event, suggestions[highlightedItemIndex]);
                } else {
                    this.openMenu(event);
                }
                break;
            case 27:
                // close on esc key or on tab
                this.closeMenu();
                break;
            case 9:
                // close on tab
                this.closeMenu();
                break;
            default:
                break;
        }
    }

    getOptionNodes() {
        const node = ReactDOM.findDOMNode(this.refDropdownMenu);
        return (node && node.getElementsByTagName('li')) || [];
    }

    getFocusedOptionNode() {
        const optionNodes = this.getOptionNodes();
        return [...optionNodes].find(item => item.className.includes('active'));
    }

    handleInputChange(value, event) {
        if (this.state.value !== value) {
            this.setInputValue(value, event);
        }
    }

    setInputValue(newValue, event = {}, shouldCloseMenu = false) {
        const closeMenuEventually = shouldCloseMenu ? false : this.state.open;

        this.setState({
            open: !this.state.open ? true : closeMenuEventually,
            value: newValue,
            highlightedItemIndex: -1,
        });

        // Fire callback so the parent component can filter the suggestions accordingly
        this.props.onSuggestionsFetchRequested({ value: newValue });

        const { value, onChange = () => {} } = this.props.inputProps;
        if (newValue !== value) {
            onChange(event, { newValue });
        }
    }

    getInputNode() {
        return this.refComponent.getElementsByTagName('input')[0];
    }

    clearInputValue(event) {
        this.setState({
            value: '',
            highlightedItemIndex: -1,
        });

        // Focus the input field after clearing it's value in order to allow
        // entering new values right away
        const node = this.getInputNode();
        if (node) {
            ReactDOM.findDOMNode(node).focus();
        }

        // Reset filter value so all suggestions are shown again
        this.props.onSuggestionsFetchRequested({ value: '' });

        // Additionally, fire the onClear callback as the service may react on it
        if (this.props.inputProps.onClear) {
            this.props.inputProps.onClear(event);
        }
    }

    onSuggestionClicked(event, suggestion) {
        this.setInputValue(this.props.getSuggestionValue(suggestion), event, this.props.closeOnSelect);

        // fire callback with the selected item
        this.props.onSuggestionSelected(event, {
            suggestion: suggestion,
            suggestionValue: this.props.getSuggestionValue(suggestion),
            highlightedItemIndex: -1,
        });
    }

    onMouseEnter(event) {
        const newIndex = event.currentTarget.getAttribute('data-item-index');
        if (newIndex) {
            this.setState(() => ({
                highlightedItemIndex: newIndex,
            }));
        }
    }

    wrapAddons(iconClass, leadingInputAddons, child, trailingInputAddons) {
        return (
            <div className={'input-group width-100pct'}>
                {leadingInputAddons && leadingInputAddons}
                {iconClass && (
                    <span className={'input-group-addon'}>
                        <span className={`rioglyph ${iconClass}`} aria-hidden={'true'}></span>
                    </span>
                )}
                {child}
                {trailingInputAddons && trailingInputAddons}
            </div>
        );
    }

    renderInput() {
        const { leadingInputAddons, trailingInputAddons } = this.props;

        const {
            icon,
            // tabIndex,
            // hasError,
            // placeholder,
            value,
            // className,
            // disabled,
            // onBlur,
            // onFocus,
            // inputRef,
            showSelectedItemsInInput,
            ...remainingProps
        } = this.props.inputProps;

        const input = (
            <ClearableInput
                {...remainingProps}
                autoComplete={this.props.autoComplete}
                onChange={this.handleInputChange}
                onClear={this.clearInputValue}
                onClick={this.openMenu}
                onFocus={this.handleFocus}
                value={!showSelectedItemsInInput && value}
            />
        );

        if (icon || leadingInputAddons || trailingInputAddons) {
            return this.wrapAddons(icon, leadingInputAddons, input, trailingInputAddons);
        }

        return input;
    }

    renderLoadingIndicator(dropdownMenuClasses) {
        return (
            <ul className={dropdownMenuClasses}>
                <div className={'display-flex justify-content-center padding-10'}>
                    <Spinner />
                </div>
            </ul>
        );
    }

    renderDropdownMenu() {
        const { suggestions, pullRight, dropdownClassName, noItemMessage, isLoading, renderSuggestion } = this.props;

        const dropdownMenuClasses = classNames(
            'dropdown-menu',
            pullRight && 'pull-right',
            dropdownClassName && dropdownClassName
        );

        if (isLoading) {
            return this.renderLoadingIndicator(dropdownMenuClasses);
        }

        const hasNoSuggestions = isEmpty(suggestions);

        if (hasNoSuggestions && !noItemMessage) {
            return null;
        }

        return (
            <ul role={'menu'} className={dropdownMenuClasses} ref={node => (this.refDropdownMenu = node)}>
                {hasNoSuggestions && noItemMessage && <NoItemMessage key={'NoItemMessage'} message={noItemMessage} />}
                {suggestions.map((suggestion, index) => {
                    if (suggestion.header) {
                        return <DropdownHeader key={suggestion.label} label={suggestion.label} />;
                    }

                    const item = {
                        value: suggestion.customSuggestion ? suggestion.customSuggestion : renderSuggestion(suggestion),
                        onSelect: (idx, event) => this.onSuggestionClicked(event, suggestion),
                        active: index === this.state.highlightedItemIndex,
                        disabled: suggestion.disabled,
                    };

                    return <MenuItem key={index} {...item} index={index} onMouseEnter={this.onMouseEnter} />;
                })}
            </ul>
        );
    }

    render() {
        const classes = classNames(
            'AutoSuggest',
            'dropdown',
            this.state.open && 'open',
            this.state.dropup && 'dropup',
            this.props.className && this.props.className
        );

        return (
            <div className={classes} onKeyDown={this.handleKeyDown} ref={node => (this.refComponent = node)}>
                {this.renderInput()}
                {this.renderDropdownMenu()}
            </div>
        );
    }
}

AutoSuggest.defaultProps = {
    onSuggestionSelected: () => {},
    onSuggestionsFetchRequested: () => {},
    renderSuggestion: suggestion => suggestion.label,
    getSuggestionValue: suggestion => suggestion.label,
    suggestions: [],
    autoDropDirection: true,
    pullRight: false,
    dropup: false,
    isLoading: false,
    openOnFocus: false,
    closeOnSelect: true,
    showSelectedItemsInInput: true,
    autoComplete: 'off',
    inputProps: {
        disabled: false,
        onChange: () => {},
        onClear: () => {},
        onBlur: () => {},
        onFocus: () => {},
        placeholder: 'Start typing ...',
        hasError: false,
        tabIndex: 0,
    },
};

AutoSuggest.propTypes = {
    onSuggestionSelected: PropTypes.func.isRequired,
    onSuggestionsFetchRequested: PropTypes.func.isRequired,
    onSuggestionHighlighted: PropTypes.func,
    suggestions: PropTypes.array.isRequired,
    renderSuggestion: PropTypes.func,
    getSuggestionValue: PropTypes.func,
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    isLoading: PropTypes.bool,
    openOnFocus: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    showSelectedItemsInInput: PropTypes.bool,
    autoComplete: PropTypes.string,
    noItemMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputProps: PropTypes.shape({
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onClear: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        icon: PropTypes.string,
        value: PropTypes.string,
        hasError: PropTypes.bool,
        tabIndex: PropTypes.number,
        inputRef: PropTypes.func,
    }).isRequired,
    leadingInputAddons: PropTypes.oneOfType([PropTypes.node]),
    trailingInputAddons: PropTypes.oneOfType([PropTypes.node]),
};

export default onClickOutside(AutoSuggest);
