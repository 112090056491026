const Line = () => null;

Line.defaultProps = {
    dataKey: 'value',
    dataUnit: '',
    strokeColor: 'color-coldplay-fountain',
    legendType: 'square',
    type: 'monotone',
    isAnimationActive: true,
};

export default Line;
