/* eslint-disable max-len */

import versionJson from './version.json';
const VERSION = versionJson.version;
export { VERSION };

// polyfill for scrollToTop() // Edge <= 15
// As "smoothscroll-polyfill" is accessing the html dom object directly without checking whether it exists or not,
// we need to use old shool require() to have a conditional import only when html really exists.
// This avoids issues when running the UIKIT in a web-worker
if (document && document.documentElement) {
    // eslint-disable-next-line global-require
    const smoothscroll = require('smoothscroll-polyfill');
    smoothscroll.polyfill();
}

import _colors from './style/css/shared/colors.json';
export { _colors as colors };

import { default as _Button } from './components/button/Button';
export { _Button as Button };

import { default as _AutoSuggest } from './components/autosuggest/AutoSuggest';
export { _AutoSuggest as AutoSuggest };

import { default as _ToggleButton } from './components/button/ToggleButton';
export { _ToggleButton as ToggleButton };

import { default as _Checkbox } from './components/checkbox/Checkbox';
export { _Checkbox as Checkbox };

import { default as _RadioButton } from './components/radiobutton/RadioButton';
export { _RadioButton as RadioButton };

import { default as _Sidebar } from './components/sidebars/Sidebar';
export { _Sidebar as Sidebar };

import { default as _AssetTree } from './components/assetTree/AssetTree';
export { _AssetTree as AssetTree };

import { default as _TreeCategory } from './components/assetTree/TreeCategory';
export { _TreeCategory as TreeCategory };

import { default as _Tree } from './components/assetTree/Tree';
export { _Tree as Tree };

import { default as _TreeSearch } from './components/assetTree/TreeSearch';
export { _TreeSearch as TreeSearch };

import { default as _TreeSummary } from './components/assetTree/TreeSummary';
export { _TreeSummary as TreeSummary };

import { default as _TypeCounter } from './components/assetTree/TypeCounter';
export { _TypeCounter as TypeCounter };

import { default as _TreeOption } from './components/assetTree/TreeOption';
export { _TreeOption as TreeOption };

import { default as _Dialog } from './components/dialog/Dialog';
export { _Dialog as Dialog };

import { default as _ConfirmationDialog } from './components/dialog/ConfirmationDialog';
export { _ConfirmationDialog as ConfirmationDialog };

import { default as _DropdownSubmenu } from './components/dropdown/DropdownSubmenu';
export { _DropdownSubmenu as DropdownSubmenu };

import { default as _MenuItem } from './components/menuItems/MenuItem';
export { _MenuItem as MenuItem };

import { default as _ButtonDropdown } from './components/dropdown/ButtonDropdown';
export { _ButtonDropdown as ButtonDropdown };

import { default as _SimpleButtonDropdown } from './components/dropdown/SimpleButtonDropdown';
export { _SimpleButtonDropdown as SimpleButtonDropdown };

import { default as _SingleButtonDropdown } from './components/dropdown/SingleButtonDropdown';
export { _SingleButtonDropdown as SingleButtonDropdown };

import { default as _SplitButtonDropdown } from './components/dropdown/SplitButtonDropdown';
export { _SplitButtonDropdown as SplitButtonDropdown };

import { default as _SaveDialog } from './components/dialog/SaveDialog';
export { _SaveDialog as SaveDialog };

import { default as _SplitDialog } from './components/dialog/SplitDialog';
export { _SplitDialog as SplitDialog };

import { default as _SimpleDialog } from './components/dialog/SimpleDialog';
export { _SimpleDialog as SimpleDialog };

import { default as _InfoDialog } from './components/dialog/InfoDialog';
export { _InfoDialog as InfoDialog };

import { default as _MediaDialog } from './components/dialog/MediaDialog';
export { _MediaDialog as MediaDialog };

import { default as _TableSettingsDialog } from './components/table/TableSettingsDialog';
export { _TableSettingsDialog as TableSettingsDialog };

import { default as _TableViewToggles } from './components/table/TableViewToggles';
export { _TableViewToggles as TableViewToggles };

import { default as _TableToolbar } from './components/table/TableToolbar';
export { _TableToolbar as TableToolbar };

import { default as _TableSearch } from './components/table/TableSearch';
export { _TableSearch as TableSearch };

import { default as _TableCardsSorting } from './components/table/TableCardsSorting';
export { _TableCardsSorting as TableCardsSorting };

import { default as _Slider } from './components/slider/Slider';
export { _Slider as Slider };

import { default as _RangeSlider } from './components/slider/RangeSlider';
export { _RangeSlider as RangeSlider };

import { default as _Spinner } from './components/spinner/Spinner';
export { _Spinner as Spinner };

import { default as _Switch } from './components/switch/Switch';
export { _Switch as Switch };

import { default as _NumberInput } from './components/numberInput/NumberInput';
export { _NumberInput as NumberInput };

import { default as _NumberControl } from './components/numberControl/NumberControl';
export { _NumberControl as NumberControl };

import { default as _Tag } from './components/tag/Tag';
export { _Tag as Tag };

import { default as _TagList } from './components/tag/TagList';
export { _TagList as TagList };

import { default as _Collapse } from './components/collapse/Collapse';
export { _Collapse as Collapse };

import { default as _Fade } from './components/fade/Fade';
export { _Fade as Fade };

import { default as _ResponsiveVideo } from './components/video/ResponsiveVideo';
export { _ResponsiveVideo as ResponsiveVideo };

import { default as _ImagePreloader } from './components/preloader/ImagePreloader';
export { _ImagePreloader as ImagePreloader };

import { default as _Resizer } from './components/resizer/Resizer';
export { _Resizer as Resizer };

import { default as _ListMenu } from './components/listMenu/ListMenu';
export { _ListMenu as ListMenu };

import { default as _Tooltip } from './components/tooltip/Tooltip';
export { _Tooltip as Tooltip };

import { default as _SimpleTooltip } from './components/tooltip/SimpleTooltip';
export { _SimpleTooltip as SimpleTooltip };

import { default as _Popover } from './components/popover/Popover';
export { _Popover as Popover };

import { default as _Select } from './components/selects/Select';
export { _Select as Select };

import { default as _Multiselect } from './components/selects/Multiselect';
export { _Multiselect as Multiselect };

import { default as _DatePicker } from './components/datepicker/DatePicker';
export { _DatePicker as DatePicker };

import { default as _DateRangePicker } from './components/datepicker/DateRangePicker';
export { _DateRangePicker as DateRangePicker };

import { default as _FilePicker } from './components/filepicker/FilePicker';
export { _FilePicker as FilePicker };

import { default as _SteppedProgressBar } from './components/steppedProgressBar/SteppedProgressBar';
export { _SteppedProgressBar as SteppedProgressBar };

import { default as _Position } from './components/position/Position';
export { _Position as Position };

import { default as _Notification } from './components/notification/Notification';
import { default as _NotificationsContainer } from './components/notification/NotificationsContainer';
export { _Notification as Notification, _NotificationsContainer as NotificationsContainer };

import { default as _ApplicationHeader } from './components/applicationHeader/ApplicationHeader';
export { _ApplicationHeader as ApplicationHeader };

import { default as _NotFoundState } from './components/states/NotFoundState';
export { _NotFoundState as NotFoundState };

import { default as _EmptyState } from './components/states/EmptyState';
export { _EmptyState as EmptyState };

import { default as _ErrorState } from './components/states/ErrorState';
export { _ErrorState as ErrorState };

import { default as _ForbiddenState } from './components/states/ForbiddenState';
export { _ForbiddenState as ForbiddenState };

import { default as _NotBookedState } from './components/states/NotBookedState';
export { _NotBookedState as NotBookedState };

import { default as _CustomState } from './components/states/CustomState';
export { _CustomState as CustomState };

import { default as _TimePicker } from './components/timepicker/TimePicker';
export { _TimePicker as TimePicker };

import {
    SortArrowUp as _SortArrowUp,
    SortArrowDown as _SortArrowDown,
    SortArrows as _SortArrows,
} from './components/table/SortArrows';
export { _SortArrowUp as SortArrowUp, _SortArrowDown as SortArrowDown, _SortArrows as SortArrows };

import {
    SortDirection as _SortDirection,
    sortByProperty as _sortByProperty,
    naturalSortByProperty as _naturalSortByProperty,
} from './utils/SortUtils';
export {
    _SortDirection as SortDirection,
    _sortByProperty as sortByProperty,
    _naturalSortByProperty as naturalSortByProperty,
};

import {
    getSortDirShort as _getSortDirShort,
    parseSorting as _parseSorting,
    mapColumnsSettingsToStrings as _mapColumnsSettingsToStrings,
    parseColumnsSettingsStrings as _parseColumnsSettingsStrings,
} from './utils/routeUtils';
export {
    _getSortDirShort as getSortDirShort,
    _parseSorting as parseSorting,
    _mapColumnsSettingsToStrings as mapColumnsSettingsToStrings,
    _parseColumnsSettingsStrings as parseColumnsSettingsStrings,
};

export { default as useEsc } from './hooks/useEsc';
export { default as useKey } from './hooks/useKey';
export { default as useEvent } from './hooks/useEvent';
export { default as useWindowResize } from './hooks/useWindowResize';
export { default as useAfterMount } from './hooks/useAfterMount';
export { default as useOnMount } from './hooks/useOnMount';
export { default as useEffectOnce } from './hooks/useEffectOnce';
export { default as useTimeout } from './hooks/useTimeout';
export { default as useInterval } from './hooks/useInterval';
export { default as useClickOutside } from './hooks/useClickOutside';
export { default as useClipboard } from './hooks/useClipboard';
export { default as useElementSize } from './hooks/useElementSize';

import { hasTouch as _hasTouch, inIframe as _inIframe, isDesktop as _isDesktop } from './utils/deviceUtils';
export { _hasTouch as hasTouch, _inIframe as inIframe, _isDesktop as isDesktop };

import { getNewGroupedSelected as _getNewGroupedSelected } from './utils/GroupSelectionUtil';
export { _getNewGroupedSelected as getNewGroupedSelected };

import { default as _ExpanderPanel } from './components/expander/ExpanderPanel';
export { _ExpanderPanel as ExpanderPanel };

import { default as _ExpanderList } from './components/expander/ExpanderList';
export { _ExpanderList as ExpanderList };

import { default as _ClearableInput } from './components/clearableInput/ClearableInput';
export { _ClearableInput as ClearableInput };

import { default as _ApplicationLayout } from './components/applicationLayout/ApplicationLayout';
export { _ApplicationLayout as ApplicationLayout };

import { default as _ApplicationLayoutBodyNavigation } from './components/applicationLayout/ApplicationLayoutBodyNavigation';
export { _ApplicationLayoutBodyNavigation as ApplicationLayoutBodyNavigation };

import { default as _ApplicationLayoutBodyBottomBar } from './components/applicationLayout/ApplicationLayoutBodyBottomBar';

export { _ApplicationLayoutBodyBottomBar as ApplicationLayoutBodyBottomBar };

import { default as _ApplicationLayoutBodyBanner } from './components/applicationLayout/ApplicationLayoutBodyBanner';
export { _ApplicationLayoutBodyBanner as ApplicationLayoutBodyBanner };

import { default as _ActionBarItem } from './components/actionBarItem/ActionBarItem';
export { _ActionBarItem as ActionBarItem };

import { default as _ReleaseNotesDialog } from './components/dialog/ReleaseNotesDialog';
export { _ReleaseNotesDialog as ReleaseNotesDialog };

import { default as _ReleaseNotes } from './components/releaseNotes/ReleaseNotes';
export { _ReleaseNotes as ReleaseNotes };

import { default as _Teaser } from './components/teaser/Teaser';
export { _Teaser as Teaser };

import { default as _TeaserContainer } from './components/teaser/TeaserContainer';
export { _TeaserContainer as TeaserContainer };

import { default as _StatusBar } from './components/statusBar/StatusBar';
export { _StatusBar as StatusBar };

import { default as _DataTabs } from './components/dataTabs/DataTabs';
export { _DataTabs as DataTabs };

import { default as _DataTab } from './components/dataTabs/DataTab';
export { _DataTab as DataTab };

import { default as _Activity } from './components/activity/Activity';
export { _Activity as Activity };

import { default as _OnboardingTip } from './components/onboarding/OnboardingTip';
export { _OnboardingTip as OnboardingTip };

import { default as _StatsWidgets } from './components/statsWidget/StatsWidgets';
export { _StatsWidgets as StatsWidgets };

import { default as _StatsWidget } from './components/statsWidget/StatsWidget';
export { _StatsWidget as StatsWidget };

import { default as _StatsWidgetNumber } from './components/statsWidget/StatsWidgetNumber';
export { _StatsWidgetNumber as StatsWidgetNumber };

import { default as _StatsWidgetSpacer } from './components/statsWidget/StatsWidgetSpacer';
export { _StatsWidgetSpacer as StatsWidgetSpacer };

import { default as _TagManager } from './components/tagManager/TagManager';
export { _TagManager as TagManager };

import { default as _BrowserWarning } from './components/browserWarning/BrowserWarning';
export { _BrowserWarning as BrowserWarning };

import {
    toggleSupportMarker as _toggleSupportMarker,
    enableSupportMarker as _enableSupportMarker,
    SUPPORT_MARKER_CLASS as _SUPPORT_MARKER_CLASS,
} from './components/supportMarker/toggleSupportMarker';
export {
    _toggleSupportMarker as toggleSupportMarker,
    _enableSupportMarker as enableSupportMarker,
    _SUPPORT_MARKER_CLASS as SUPPORT_MARKER_CLASS,
};

import { default as _Carousel } from './components/carousel/Carousel';
export { _Carousel as Carousel };

import { default as _LoadMoreButton } from './components/loadMore/LoadMoreButton';
export { _LoadMoreButton as LoadMoreButton };

import { default as _OverlayTrigger } from './components/overlay/OverlayTrigger';
export { _OverlayTrigger as OverlayTrigger };

import { default as _ContentLoader } from './components/contentLoader/ContentLoader';
export { _ContentLoader as ContentLoader };

import { default as _AreaChart } from './components/charts/AreaChart';
export { _AreaChart as AreaChart };

import { default as _Area } from './components/charts/Area';
export { _Area as Area };

import { default as _AreaGradient } from './components/charts/AreaGradient';
export { _AreaGradient as AreaGradient };

import { default as _PieChart } from './components/charts/PieChart';
export { _PieChart as PieChart };

import { default as _Bar } from './components/charts/Bar';
export { _Bar as Bar };

import { default as _BarChart } from './components/charts/BarChart';
export { _BarChart as BarChart };

import { default as _Line } from './components/charts/Line';
export { _Line as Line };

import { default as _LineChart } from './components/charts/LineChart';
export { _LineChart as LineChart };

import { default as _Legend } from './components/charts/Legend';
export { _Legend as Legend };

import { default as _RadialBarChart } from './components/charts/RadialBarChart';
export { _RadialBarChart as RadialBarChart };

import { default as _ChartNeedle } from './components/charts/ChartNeedle';
export { _ChartNeedle as ChartNeedle };
