import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const getCridClasses = amount => {
    switch (amount) {
        case 1:
            return 'col-12';
        case 2:
            return 'col-12 col-sm-6';
        case 3:
            return 'col-12 col-md-4';
        case 4:
            return 'col-12 col-md-6 col-lg-3';
        case 6:
            return 'col-12 col-sm-4 col-lg-2';
        case 12:
            return 'col-12 col-sm-1';
        default:
            return 'col-12';
    }
};

const TeaserContainer = props => {
    const rowClassNames = classname(
        'teaser-container',
        'row',
        'display-flex-sm flex-wrap-sm',
        props.className && props.className
    );

    let columnClassNames;

    if (props.teaserPerRow) {
        columnClassNames = `${getCridClasses(props.teaserPerRow)}`;
    } else {
        const childCount = props.children.length || 1;
        columnClassNames = classname(getCridClasses(childCount));
    }

    const aggregartedColumnClassNames = classname(columnClassNames, props.columnClassName && props.columnClassName);

    return (
        <div className={rowClassNames}>
            {React.Children.map(props.children, child => {
                return <div className={aggregartedColumnClassNames}>{child}</div>;
            })}
        </div>
    );
};

TeaserContainer.defaultValue = {
    children: [],
};

TeaserContainer.propTypes = {
    teaserPerRow: PropTypes.PropTypes.oneOf([1, 2, 3, 4, 6, 12]),
    className: PropTypes.string,
    columnClassName: PropTypes.string,
};

export default TeaserContainer;
