import React, { useCallback, useEffect, useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import head from 'lodash/fp/head';
import negate from 'lodash/fp/negate';
import delay from 'lodash/fp/delay';

import { ActionBarOverlay } from './ActionBarOverlay';
import { ActionBarItemPopoverContent } from './ActionBarItemPopoverContent';
import { ActionBarItemIcon } from './ActionBarItemIcon';
import { ActionBarItemList } from './ActionBarItemList';
import { ActionBarItemListItem } from './ActionBarItemListItem';
import { ActionBarItemListSeparator } from './ActionBarItemListSeparator';
import OverlayTrigger from '../overlay/OverlayTrigger';
import Dialog from '../dialog/Dialog';

const EVENT = 'mousedown';
const CLASSNAME_SHOW = 'show';
const CLASSNAME_OFFSCREEN = 'position-offscreen';

const POPOVER_CLOSE_DELAY_IN_MS = 200;
const SMALL_RESOLUTION_THRESHOLD_IN_PX = 580;

const hasClass = (element, className) => element.classList.contains(className);
const hasNotClass = negate(hasClass);

const isNotChildNodeOf = (childNode, parentNode) => !parentNode.contains(childNode);

const checkIfSmallResolution = () => {
    const [isSmallResolution, setIsSmallResolution] = useState(false);

    // We only execute this once. Before it was re rendered on every change.
    // Users probably don't switch between display sizes that often therefore it probably makes sense to
    // avoid the re rendering. Or applying an event listnener.
    useEffect(() => {
        const header = head(document.getElementsByClassName('ApplicationHeader'));
        setIsSmallResolution(header && header.offsetWidth < SMALL_RESOLUTION_THRESHOLD_IN_PX);
    }, []);

    return isSmallResolution;
};

const isActionBarItemPopover = child => child.type?.displayName === ActionBarItemPopoverContent.displayName;
const isActionBarItemIcon = child => child.type?.displayName === ActionBarItemIcon.displayName;

export const ActionBarItem = React.memo(props => {
    const { className, children, popoverWidth, id = `${Math.random()}`, hidePopoverOnClick } = props;

    const [isShown, setIsShown] = useState(false);

    const clickOutsideRef = useRef();

    useLayoutEffect(() => {
        const listener = event => {
            if (!clickOutsideRef || !clickOutsideRef.current || !isShown) {
                return;
            }

            // Since the popover component is based on React Portal and might be offscreen, we need to use
            // old-school approach and query the DOM for the item ID.
            const popoverEl = document.getElementById(id);

            if (!popoverEl) {
                return;
            }

            // Abort when the ActionBarItemIcon itself has been clicked as there is a toggle function
            // applied to the icon that takes care of opening and closing
            const hasIconClickedToClose = event.target.offsetParent === clickOutsideRef.current;
            if (hasIconClickedToClose) {
                return;
            }

            // Handle click outside the popover to close it
            const isClickOutsidePopover = isNotChildNodeOf(event.target, popoverEl);
            const isPopoverVisible = hasClass(popoverEl, CLASSNAME_SHOW) && hasNotClass(popoverEl, CLASSNAME_OFFSCREEN);

            if (isPopoverVisible && isClickOutsidePopover) {
                setIsShown(false);
                return;
            }

            // Handle click inside the popover.
            // Delay the closing of the popover to execute possible actions from within the popover
            // like clicks on links or buttons
            if (hidePopoverOnClick) {
                delay(POPOVER_CLOSE_DELAY_IN_MS)(() => setIsShown(false));
            }
        };

        document.addEventListener(EVENT, listener);
        return () => {
            document.removeEventListener(EVENT, listener);
        };
    }, [clickOutsideRef, id, isShown]);

    const classes = classNames('ActionBarItem', className);

    const isSmallScreen = checkIfSmallResolution();

    const onToggle = useCallback(() => setIsShown(!isShown), [setIsShown, isShown]);

    // The children depend on each other, that's why it's easier to convert them once to an array
    // and then pick the correct elements. In most cases there are only 2 or 3 elements in the children
    // array so the looping shouldn't be too worrysome.
    const childrenAsList = React.Children.toArray(children);
    const itemPopover = childrenAsList.find(isActionBarItemPopover);
    const itemIcon = childrenAsList.find(isActionBarItemIcon);

    if (!itemPopover) {
        return <div className={classes}>{children}</div>;
    }

    const { useOffscreen = false, title } = itemPopover.props;

    // Filter out the popover and icon component from the list of children as they are handled separetely
    const childrenWithoutPopover = childrenAsList.filter(child => !isActionBarItemPopover(child));
    const childrenWithoutPopoverAndIcon = childrenWithoutPopover.filter(child => !isActionBarItemIcon(child));

    if (isSmallScreen) {
        return (
            <div className={classes} ref={clickOutsideRef}>
                {React.cloneElement(itemIcon, { onClick: onToggle })}
                <div onClick={() => setIsShown(false)}>
                    <Dialog show={isShown} onHide={() => setIsShown(false)} body={itemPopover} title={title} />
                </div>
                {childrenWithoutPopoverAndIcon}
            </div>
        );
    }

    const overlay = (
        <ActionBarOverlay
            id={id}
            key='child'
            title={title}
            width={popoverWidth}
            preRender={useOffscreen}
            show={isShown}
        >
            {itemPopover}
        </ActionBarOverlay>
    );

    return (
        <div className={classes} ref={clickOutsideRef}>
            <OverlayTrigger
                onToggle={onToggle}
                show={isShown || useOffscreen}
                placement={OverlayTrigger.BOTTOM_END}
                overlay={overlay}
                trigger='click'
                rootClose={false}
                popperConfig={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 5],
                            },
                        },
                    ],
                }}
            >
                {itemIcon}
            </OverlayTrigger>
            {childrenWithoutPopoverAndIcon}
        </div>
    );
});

ActionBarItem.Icon = ActionBarItemIcon;
ActionBarItem.Popover = ActionBarItemPopoverContent;
ActionBarItem.List = ActionBarItemList;
ActionBarItem.ListItem = ActionBarItemListItem;
ActionBarItem.ListSeparator = ActionBarItemListSeparator;

ActionBarItem.defaultProps = {
    hidePopoverOnClick: true,
    popoverWidth: 250,
};

ActionBarItem.propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
    hidePopoverOnClick: PropTypes.bool,
    popoverWidth: PropTypes.oneOf([100, 150, 200, 250, 300, 350, 400, 450, 500]),
};

export default ActionBarItem;
