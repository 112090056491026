import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '../collapse/Collapse';
import classname from 'classnames';

const ExpanderPanel = props => {
    const {
        bsStyle,
        className,
        iconLeft,
        title,
        headerClassName,
        titleClassName,
        bodyClassName,
        mountOnEnter,
        unmountOnExit,
        onEnter,
        onEntering,
        onEntered,
        onExit,
        onExiting,
        onExited,
        children,
        onToggle,
        open,
    } = props;

    const [isOpen, setIsOpen] = useState(open);

    // Update internal state from external prop change
    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    const handleToggle = () => {
        const newState = !isOpen;
        setIsOpen(newState);
        onToggle(newState);
    };

    const wrapperClassNames = classname('expander-panel panel', `panel-${bsStyle}`, className);

    const iconClassNames = classname('expander-icon', 'rioglyph', 'rioglyph-chevron-down');

    const headerClassNames = classname(
        'panel-heading',
        isOpen && 'open',
        iconLeft && 'icon-left',
        headerClassName && headerClassName
    );

    const titleClassNames = classname('title', titleClassName && titleClassName);

    const bodyClassNames = classname('panel-body', bodyClassName && bodyClassName);

    const isRenderCallback = children && typeof children === 'function';

    return (
        <div className={wrapperClassNames}>
            <div className={headerClassNames} onClick={handleToggle}>
                <span className={titleClassNames}>{title}</span>
                <span className={iconClassNames} />
                {bsStyle === 'separator' && (
                    <div className={'separator'}>
                        <hr />
                    </div>
                )}
            </div>
            <Collapse
                in={isOpen}
                mountOnEnter={mountOnEnter}
                unmountOnExit={unmountOnExit}
                onEnter={onEnter}
                onEntering={onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={onExited}
            >
                <div>
                    <div className={bodyClassNames}>{isRenderCallback ? children(isOpen) : children}</div>
                </div>
            </Collapse>
        </div>
    );
};

ExpanderPanel.defaultProps = {
    mountOnEnter: true,
    unmountOnExit: true,
    bsStyle: 'blank',
    iconLeft: false,
    open: false,
    onToggle: () => {},
    onEnter: () => {},
    onEntering: () => {},
    onEntered: () => {},
    onExit: () => {},
    onExiting: () => {},
    onExited: () => {},
};

ExpanderPanel.propTypes = {
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    bsStyle: PropTypes.oneOf([
        'blank',
        'default',
        'separator',
        'primary',
        'secondary',
        'info',
        'warning',
        'danger',
        'success',
    ]),
    iconLeft: PropTypes.bool,
    open: PropTypes.bool,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    onToggle: PropTypes.func,
    onEnter: PropTypes.func,
    onEntering: PropTypes.func,
    onEntered: PropTypes.func,
    onExit: PropTypes.func,
    onExiting: PropTypes.func,
    onExited: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

export default ExpanderPanel;
