import React from 'react';
import {
    AreaChart as RechartsAreaChart,
    Area as RechartsArea,
    Legend as RechartsLegend,
    XAxis,
    YAxis,
    Brush,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

import AreaGradient from './AreaGradient';
import { getColor, mapGridOptions } from './chartHelper';

const AreaChart = props => {
    const {
        width,
        height,
        data,
        dataUnit,
        dataKey,
        gradients,
        areas,
        xAxisOptions,
        yAxisOptions,
        gridOptions,
        showXAxis,
        showYAxis,
        showGrid,
        legend,
        brush,
        tooltip,
        ...remainingProps
    } = props;

    const areasWithGradient = areas.map(area => {
        const { strokeColor, ...remainingAreaProps } = area.props;
        const gradientId = area.key;
        return {
            area: (
                <RechartsArea
                    {...remainingAreaProps}
                    key={area.key}
                    stroke={getColor(strokeColor)}
                    fill={`url(#${gradientId})`}
                />
            ),
            gradient: <AreaGradient key={area.key} id={gradientId} color={strokeColor} />,
        };
    });

    return (
        <ResponsiveContainer width='100%' height='100%'>
            <RechartsAreaChart
                width={width}
                height={height}
                data={data}
                margin={{ top: 15, right: 15, left: 15, bottom: 15 }}
                {...remainingProps}
            >
                <defs>
                    {gradients}
                    {areasWithGradient.map(areaWithGradient => areaWithGradient.gradient)}
                </defs>

                {showGrid && <CartesianGrid strokeDasharray='3 3' {...mapGridOptions(gridOptions)} />}

                <XAxis hide={!showXAxis} dataKey={dataKey} {...xAxisOptions} />
                <YAxis hide={!showYAxis} unit={dataUnit} {...yAxisOptions} />

                {tooltip && <Tooltip />}

                {legend && (
                    <RechartsLegend
                        wrapperStyle={
                            legend.props.verticalAlign === 'bottom' ? { bottom: 0 } : legend.props.wrapperStyle
                        }
                        {...legend.props}
                    />
                )}
                {areasWithGradient.map(areaWithGradient => areaWithGradient.area)}

                {brush && <Brush stroke={getColor('gray')} />}
            </RechartsAreaChart>
        </ResponsiveContainer>
    );
};

AreaChart.defaultProps = {
    width: 480,
    height: 200,
    data: [],
    dataUnit: '',
    // dataKey,
    gradients: [],
    areas: [],
    // xAxisOptions,
    // yAxisOptions,
    // gridOptions,
    showXAxis: true,
    showYAxis: false,
    showGrid: false,
    // legend,
    brush: false,
    tooltip: true,
};

export default AreaChart;
