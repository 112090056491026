import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone, { useDropzone } from 'react-dropzone';
import Button from '../button/Button';
import classNames from 'classnames';

const FilePicker = props => {
    const { label, accept, multiple, maxSize, displayMode, onPick, className, children, ...remainigProps } = props;

    const dropzoneRef = useRef();

    const isButton = displayMode === 'button';
    const isDropzone = displayMode === 'dropzone';
    const isFull = displayMode === 'full';

    const showButton = isButton || isFull;
    const showDropzone = isDropzone || isFull;

    const handleDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            const files = accept.includes('image')
                ? acceptedFiles.map(file =>
                      Object.assign(file, {
                          preview: URL.createObjectURL(file),
                      })
                  )
                : acceptedFiles;

            onPick(files, rejectedFiles);
        },
        [onPick, accept]
    );

    const onOpenClick = () => dropzoneRef.current.open();

    return (
        <div className='FilePicker'>
            {showButton && (
                <Button onClick={onOpenClick} className={className}>
                    {label}
                </Button>
            )}
            <Dropzone
                {...remainigProps}
                onDrop={handleDrop}
                accept={accept}
                multiple={multiple}
                maxSize={maxSize}
                ref={dropzoneRef}
            >
                {({ getRootProps, getInputProps, isDragActive }) => {
                    return (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {showDropzone && children && children({ isDragActive })}
                        </div>
                    );
                }}
            </Dropzone>
        </div>
    );
};

FilePicker.DISPLAY_MODE_BUTTON = 'button';
FilePicker.DISPLAY_MODE_DROPZONE = 'dropzone';
FilePicker.DISPLAY_MODE_FULL = 'full';

FilePicker.defaultProps = {
    displayMode: 'button',
    multiple: true,
    label: 'Select Files',
    accept: '',
    onPick: () => {},
    className: '',
};

FilePicker.propTypes = {
    displayMode: PropTypes.string /* Display mode: 'dropzone' / 'button' / 'full' */,
    multiple: PropTypes.bool /* Allow multiple file selections or not */,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]) /* Text to display on Button */,
    accept: PropTypes.string /* List of accepted Mime Types */,
    maxSize: PropTypes.number /* Maximum File Size */,
    onPick: PropTypes.func /* Pick callback */,
    className: PropTypes.string /* Custom className for the displayed component (dropzone/button) */,
    children: PropTypes.any,
};

export default FilePicker;
