import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

class RangeSlider extends Component {
    /* istanbul ignore next */
    constructor(props) {
        super(props);

        this.state = {
            active1: false,
            active2: true,
            value1: props.leftValue ? props.leftValue : props.minValue,
            value2: props.rightValue ? props.rightValue : props.maxValue,
            changing1: false,
            changing2: false,
        };

        this.handleChangeValue1 = this.handleChangeValue1.bind(this);
        this.handleChangeValue2 = this.handleChangeValue2.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseDown1 = this.onMouseDown1.bind(this);
        this.onMouseDown2 = this.onMouseDown2.bind(this);
        this.onMouseMove1 = this.onMouseMove1.bind(this);
        this.onMouseMove2 = this.onMouseMove2.bind(this);
    }

    changeValues(newValues) {
        const newValue1 = newValues.value1 ? newValues.value1 : this.state.value1;
        const newValue2 = newValues.value2 ? newValues.value2 : this.state.value2;

        this.setState({
            value1: newValue1,
            changing1: !!newValues.value1,
            value2: newValue2,
            changing2: !!newValues.value2,
        });

        const rightValue = newValue1 >= newValue2 ? newValue1 : newValue2;
        const leftValue = newValue2 <= newValue1 ? newValue2 : newValue1;

        this.props.onChange(leftValue, rightValue);
    }

    handleChangeValue1(event) {
        const newValue1 = parseInt(event.target.value, 10);
        this.changeValues({ value1: newValue1 });
    }

    handleChangeValue2(event) {
        const newValue2 = parseInt(event.target.value, 10);
        this.changeValues({ value2: newValue2 });
    }

    onMouseDown1() {
        this.setState({
            changing1: true,
            changing2: false,
        });

        this.listenToMouseUp();
    }

    onMouseDown2() {
        this.setState({
            changing1: false,
            changing2: true,
        });

        this.listenToMouseUp();
    }

    getTrackMiddlePosition(targetWidth) {
        const { value1, value2 } = this.state;
        const { minValue, maxValue } = this.props;

        const trackValue1Relative = (value1 - minValue) / (maxValue - minValue);
        const trackValue2Relative = (value2 - minValue) / (maxValue - minValue);
        const trackMiddleRelative = (trackValue1Relative + trackValue2Relative) / 2;

        return trackMiddleRelative * targetWidth;
    }

    onMouseMove1(event) {
        if (!this.state.changing1) {
            const target = event.target.getBoundingClientRect();
            const mouseHover = event.clientX - target.left;
            const targetWidth = event.target.offsetWidth;

            const value1 = this.state.value1;
            const value2 = this.state.value2;

            const trackMiddlePosition = this.getTrackMiddlePosition(targetWidth);

            if (value2 > value1 && mouseHover > trackMiddlePosition) {
                this.setState({
                    active1: false,
                    active2: true,
                });
            }

            if (value2 < value1 && mouseHover < trackMiddlePosition) {
                this.setState({
                    active1: false,
                    active2: true,
                });
            }
        }
    }

    onMouseMove2(event) {
        if (!this.state.changing2) {
            const target = event.target.getBoundingClientRect();
            const mouseHover = event.clientX - target.left;
            const targetWidth = event.target.offsetWidth;

            const value1 = this.state.value1;
            const value2 = this.state.value2;

            const trackMiddlePosition = this.getTrackMiddlePosition(targetWidth);

            if (value2 > value1 && mouseHover < trackMiddlePosition) {
                this.setState({
                    active1: true,
                    active2: false,
                });
            }

            if (value2 < value1 && mouseHover > trackMiddlePosition) {
                this.setState({
                    active1: true,
                    active2: false,
                });
            }
        }
    }

    onMouseUp() {
        document.removeEventListener('mouseup', this.onMouseUp);
        document.getElementsByTagName('body')[0].style.cursor = '';

        this.setState({
            changing1: false,
            changing2: false,
        });

        const { value1, value2 } = this.state;

        const rightValue = value1 >= value2 ? value1 : value2;
        const leftValue = value2 <= value1 ? value2 : value1;

        this.props.onDragEnd(leftValue, rightValue);
    }

    listenToMouseUp() {
        document.addEventListener('mouseup', this.onMouseUp);
        document.getElementsByTagName('body')[0].style.cursor = 'pointer';
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.value1 >= nextProps.minValue &&
            nextProps.value1 <= nextProps.maxValue &&
            nextProps.value2 >= nextProps.minValue &&
            nextProps.value2 <= nextProps.maxValue
        ) {
            this.setState({
                value1: nextProps.value1,
                value2: nextProps.value2,
            });
        }
    }

    render() {
        const { value1, value2, changing1, changing2 } = this.state;
        const { minValue, maxValue, valueLabels, step, className, disabled } = this.props;

        const rightValue = value1 >= value2 ? value1 : value2;
        const leftValue = value2 <= value1 ? value2 : value1;

        const trackWidth = (100 * (rightValue - leftValue)) / (maxValue - minValue);
        const trackLeft = (100 * (leftValue - minValue)) / (maxValue - minValue);

        const classNames = classname(
            'range-slider',
            disabled && 'disabled',
            valueLabels && 'show-value-labels',
            className && className
        );

        const renderValue = () => {
            return (
                <div>
                    <div className='range-value'>{leftValue}</div>
                    <div className='range-value'>{rightValue}</div>
                </div>
            );
        };

        return (
            <div className={classNames}>
                <div className='range-whole-track' />
                <div className='range-track' style={{ width: `${trackWidth}%`, left: `${trackLeft}%` }}>
                    {valueLabels && renderValue()}
                </div>
                <input
                    className={`${changing1 ? 'changing' : ''}`}
                    style={{ zIndex: this.state.active1 ? '6' : '5' }}
                    value={value1}
                    min={minValue}
                    max={maxValue}
                    step={step}
                    type='range'
                    onChange={this.handleChangeValue1}
                    onMouseDown={this.onMouseDown1}
                    onMouseMove={this.onMouseMove1}
                />
                <input
                    className={`${changing2 ? 'changing' : ''}`}
                    style={{ zIndex: this.state.active2 ? '6' : '5' }}
                    value={value2}
                    min={minValue}
                    max={maxValue}
                    step={step}
                    type='range'
                    onChange={this.handleChangeValue2}
                    onMouseDown={this.onMouseDown2}
                    onMouseMove={this.onMouseMove2}
                />
            </div>
        );
    }
}

RangeSlider.defaultProps = {
    step: 1,
    minValue: 0,
    maxValue: Number.MAX_VALUE,
    valueLabels: false,
    disabled: false,
    onChange: () => {},
    onDragEnd: () => {},
};

RangeSlider.propTypes = {
    leftValue: PropTypes.number,
    rightValue: PropTypes.number,
    minValue: PropTypes.number.isRequired,
    maxValue: PropTypes.number.isRequired,
    valueLabels: PropTypes.bool,
    step: PropTypes.number,
    onChange: PropTypes.func,
    onDragEnd: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default RangeSlider;
