const Bar = () => null;

Bar.defaultProps = {
    isAnimationActive: true,
    dataKey: 'value',
    dataUnit: '',
    color: 'color-coldplay-fountain',
};

export default Bar;
