import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DataTabs = props => {
    const {
        activeBgColor,
        activeKey,
        activeTextColor,
        bordered,
        children,
        className: wrapperClassName,
        commonTabContent,
        disableTransition,
        hoverBgColor,
        hoverTextColor,
        onSelectTab,
        tabContentClassName,
        vertical,
    } = props;

    const getFirstTabKey = (children = []) => {
        const [firstItem] = children;
        return firstItem && firstItem.props.tabKey;
    };

    const [internalActiveKey, setInternalActiveKey] = useState(activeKey || getFirstTabKey(props.children));

    useEffect(() => {
        activeKey && setInternalActiveKey(activeKey);
    }, [activeKey]);

    const handleSelectTab = event => {
        onSelectTab(event.currentTarget.getAttribute('data-tabkey'));
    };

    const wrapperClassNames = classNames(
        'DataTabsContainer',
        bordered && 'DataTab-bordered',
        wrapperClassName,
        vertical && 'DataTabs-vertical'
    );

    const enrichChildren = () => {
        return React.Children.map(children, child =>
            React.cloneElement(child, {
                active: child.props.tabKey === internalActiveKey,
                disableTransition,
            })
        );
    };

    return (
        <div className={wrapperClassNames}>
            <div className={'DataTabsWrapper'}>
                <ul className={'DataTabs'} role={'tabList'}>
                    {React.Children.map(children, child => {
                        const { tabKey, className, title } = child.props;
                        const isActive = tabKey === internalActiveKey;

                        const tabClassNames = classNames(
                            'DataTab',
                            'tab',
                            !isActive && `${hoverTextColor} ${hoverBgColor} cursor-pointer`,
                            isActive && `active ${activeTextColor} ${activeBgColor}`,
                            className && className
                        );

                        return (
                            <li key={tabKey} className={tabClassNames} onClick={handleSelectTab} data-tabkey={tabKey}>
                                {title}
                                <div className={`arrow ${isActive ? 'in' : ''}`} />
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className={`tab-content ${tabContentClassName}`} role={'tabpanel'}>
                {commonTabContent ? commonTabContent : enrichChildren()}
            </div>
        </div>
    );
};

DataTabs.defaultProps = {
    activeKey: '',
    onSelectTab: () => {},
    className: '',
    tabContentClassName: '',
    mountOnEnter: true,
    unmountOnExit: true,
    hoverTextColor: 'hover-text-color-darkest',
    hoverBgColor: 'hover-bg-highlight-decent',
    activeTextColor: 'text-color-white',
    activeBgColor: 'bg-highlight-dark',
    bordered: true,
    disableTransition: false,
    children: [],
    vertical: false,
};

DataTabs.propTypes = {
    activeKey: PropTypes.string,
    onSelectTab: PropTypes.func.isRequired,
    mountOnEnter: PropTypes.bool,
    unmountOnExit: PropTypes.bool,
    tabContentClassName: PropTypes.string,
    hoverTextColor: PropTypes.string,
    hoverBgColor: PropTypes.string,
    activeTextColor: PropTypes.string,
    activeBgColor: PropTypes.string,
    bordered: PropTypes.bool,
    disableTransition: PropTypes.bool,
    className: PropTypes.string,
    vertical: PropTypes.bool,
};

export default DataTabs;
