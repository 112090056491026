import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const CheckboxIcon = props => {
    const { icon, iconSize, iconLabelPosition, text } = props;

    const iconStyles = {
        width: `${iconSize}px`,
        height: `${iconSize}px`,
        fontSize: `${iconSize}px`,
        lineHeight: `${iconSize}px`,
    };

    return (
        <span className={`checkbox-icon label-${iconLabelPosition}`}>
            <span className={`rioglyph ${icon}`} style={iconStyles} />
            <span className='checkbox-label'>{text}</span>
        </span>
    );
};

const Checkbox = props => {
    const {
        checked,
        children,
        className,
        custom,
        defaultChecked,
        disabled,
        error,
        icon,
        iconLabelPosition,
        iconSize,
        id = props.name,
        indeterminate,
        inline,
        inputRef,
        label,
        name,
        onChange,
        onClick,
        required,
        right,
        size,
        tabIndex,
        ...remainingProps
    } = props;

    const labelRef = useRef();

    const getInput = () => labelRef.current.firstChild;

    useEffect(() => {
        const input = getInput();
        input.indeterminate = indeterminate;
    }, [indeterminate]);

    const handleToggleKeyDown = event => {
        switch (event.keyCode) {
            case 32:
                // toggle on space
                toggle(event);
                break;
            case 13:
                // open on enter
                toggle(event);
                break;
            default:
                break;
        }
    };

    const toggle = event => {
        event.preventDefault();

        if (disabled) {
            return;
        }

        const input = getInput();

        if (input.indeterminate) {
            input.indeterminate = false;
        }

        input.checked = !input.checked;

        onClick(event);
        onChange(event);
    };

    const text = label || children;

    const labelClassnames = classname(
        'checkbox',
        inline && 'checkbox-inline',
        size === 'large' && 'checkbox-large',
        right && 'checkbox-right',
        className
    );

    const inputClassnames = classname(
        error && 'error',
        size === 'large' && 'large',
        indeterminate && 'indeterminate',
        className
    );

    const renderCustomIcon = !!icon;
    const renderCustomContent = custom && children;
    const renderDefault = !icon && !custom;

    return (
        <label
            {...remainingProps}
            className={labelClassnames}
            tabIndex={tabIndex}
            htmlFor={id}
            onKeyDown={handleToggleKeyDown}
            ref={labelRef}
        >
            <input
                id={id}
                name={name}
                type='checkbox'
                checked={checked}
                required={required}
                defaultChecked={defaultChecked}
                disabled={disabled}
                className={inputClassnames}
                onClick={onClick}
                onChange={onChange}
                ref={inputRef}
            />
            {renderCustomIcon && (
                <CheckboxIcon icon={icon} iconSize={iconSize} iconLabelPosition={iconLabelPosition} text={text} />
            )}
            {renderDefault && (
                <span className='checkbox-text'>
                    <span>{text}</span>
                </span>
            )}
            {renderCustomContent && children}
        </label>
    );
};

Checkbox.ICON_LABEL_VERTICAL = 'vertical';
Checkbox.ICON_LABEL_HORIZONTAL = 'horizontal';

Checkbox.defaultProps = {
    inline: false,
    error: false,
    disabled: false,
    custom: false,
    indeterminate: false,
    required: false,
    tabIndex: 0,
    icon: '',
    iconSize: 16,
    iconLabelPosition: Checkbox.ICON_LABEL_VERTICAL,
    onClick: () => {},
    onChange: () => {},
};

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconLabelPosition: PropTypes.oneOf([Checkbox.ICON_LABEL_VERTICAL, Checkbox.ICON_LABEL_HORIZONTAL]),
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    custom: PropTypes.bool,
    required: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
    inline: PropTypes.bool,
    right: PropTypes.bool,
    error: PropTypes.bool,
    size: PropTypes.string,
    inputRef: PropTypes.func,
    tabIndex: PropTypes.number,
};

export default Checkbox;
