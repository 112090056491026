import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isObject from 'lodash/fp/isObject';

import Checkbox from '../checkbox/Checkbox';
import RadioButton from '../radiobutton/RadioButton';

const TreeLeaf = React.memo(props => {
    const { item, hasMultiselect, showRadioButtons, isSelected, onSelectItem, onActiveItem } = props;

    const treeNodeClassNames = classNames(
        'TreeLeaf',
        'form-group margin-bottom-0',
        isSelected && 'active',
        item.className && item.className
    );

    return (
        <div className={treeNodeClassNames} data-key={item.id}>
            {hasMultiselect && <Checkbox className='TreeCheckbox' checked={isSelected} onClick={onSelectItem} />}
            {!hasMultiselect && showRadioButtons && (
                <RadioButton className='TreeRadioButton' checked={isSelected} onChange={onSelectItem} />
            )}
            <span className='TreeLabel' onClick={onActiveItem}>
                <span className='TreeLabelName'>
                    <span className={`rioglyph rioglyph-${item.type}`} />
                    <span className='TreeLabelNameText'>
                        <span className='TreeLabelNameTextHeadline'>
                            {isObject(item.name) ? (
                                <React.Fragment>
                                    <span className='text-light margin-right-3'>{item.name.firstName}</span>
                                    <span>{item.name.lastName}</span>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>{item.name}</React.Fragment>
                            )}
                        </span>
                        {item.info && <span className='TreeLabelNameTextSubline'>{item.info}</span>}
                    </span>
                </span>
            </span>
        </div>
    );
});

TreeLeaf.displayName = 'TreeLeaf';

TreeLeaf.defaultProps = {};

TreeLeaf.propTypes = {
    item: PropTypes.object,
    hasMultiselect: PropTypes.bool,
    showRadioButtons: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelectItem: PropTypes.func,
    onActiveItem: PropTypes.func,
};

export default TreeLeaf;
