import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import useClickOutside from '../../hooks/useClickOutside';

const ToggleButton = props => {
    const { title, caret } = props;
    return (
        <a
            className={classNames('ModuleNavigation-dropdown dropdown-toggle justify-content-between', {
                inactive: !caret,
            })}
            role='button'
            id='basic-nav-dropdown'
        >
            <span>{title}</span>
            {caret && <span className='caret' />}
        </a>
    );
};

const DropdownMenu = props => {
    const { children } = props;
    if (!children && !children.length) {
        return null;
    }

    return (
        <ul role='menu' className='dropdown-menu'>
            {children}
        </ul>
    );
};

const AppMenuDropdownOpener = React.forwardRef((props, ref) => {
    const { showAsOpen, children, onDropdownClick } = props;
    return (
        <li ref={ref} className={classNames('dropdown', { open: showAsOpen })} onClick={onDropdownClick}>
            {children}
        </li>
    );
});

export const AppMenuDropdown = props => {
    const { title, caret, children } = props;

    const [isOpen, setIsOpen] = useState(false);

    const wrapperRef = useClickOutside(() => setIsOpen(false));

    const handleDropdownClick = () => setIsOpen(!isOpen);

    const openWithChildren = children && children.length && isOpen;

    return (
        <AppMenuDropdownOpener showAsOpen={openWithChildren} onDropdownClick={handleDropdownClick} ref={wrapperRef}>
            <ToggleButton title={title} caret={caret} />
            <DropdownMenu>{children}</DropdownMenu>
        </AppMenuDropdownOpener>
    );
};

export default AppMenuDropdown;

AppMenuDropdown.defaultProps = {
    caret: false,
};

AppMenuDropdown.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    caret: PropTypes.bool,
};
