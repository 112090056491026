// taken from https://github.com/sindresorhus/array-move
// but added here so it will be compiled to es5 as the original library was not nad failed iin IE11

const arrayMoveMutate = (array, from, to) => {
    const startIndex = to < 0 ? array.length + to : to;
    const item = array.splice(from, 1)[0];
    array.splice(startIndex, 0, item);
};

const arrayMove = (array, from, to) => {
    const newArray = [...array];
    arrayMoveMutate(newArray, from, to);
    return newArray;
};

export default arrayMove;
