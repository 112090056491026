import { useEffect, useState } from 'react';

export default function useElementSize(elementRef) {
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (entry.contentRect) {
                    setWidth(entry.contentRect.width);
                    setHeight(entry.contentRect.height);
                }
            }
        });
        resizeObserver.observe(elementRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [elementRef]);

    return [width, height];
}
