import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TypeCounter from './TypeCounter';

const DefaultAssetCounts = ({ assetCounts }) => {
    const { truck, bus, van, trailer, driver } = assetCounts;
    return (
        <div className={'display-flex'}>
            <TypeCounter icon={'truck-baseline'} value={truck} />
            <TypeCounter icon={'trailer-baseline'} value={trailer} />
            <TypeCounter icon={'bus-baseline'} value={bus} />
            <TypeCounter icon={'van-baseline'} value={van} />
            <TypeCounter icon={'driver'} value={driver} />
        </div>
    );
};

const TreeSummary = React.memo(props => {
    const { className, assetCounts, children } = props;
    const classes = classNames('TreeSummary', 'display-flex', 'align-items-center', className);

    return <div className={classes}>{children || <DefaultAssetCounts assetCounts={assetCounts} />}</div>;
});

TreeSummary.displayName = 'TreeSummary';

TreeSummary.defaultProps = {
    assetCounts: {},
    className: '',
};

TreeSummary.propTypes = {
    assetCounts: PropTypes.shape({
        truck: PropTypes.number,
        trailer: PropTypes.number,
        bus: PropTypes.number,
        van: PropTypes.number,
        driver: PropTypes.number,
    }),
    className: PropTypes.string,
};

export default TreeSummary;
